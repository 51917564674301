import React from 'react';
import styled from 'styled-components';
import AirEmission from './AirEmission';
import EmissionEvent from './EmissionEvent';
import EnforcementEvent from './Enforcement';

interface IEmissions {
  result: any;
  entity: any;
  types: any;
  cnStatsResult: any;
}
const Container = styled.div``;

const Emissions: React.FC<IEmissions> = (props) => {
  const statsResult = props.result;
  const entity = props.entity;
  const types = props.types;
  const cnResults = props.cnStatsResult;
  return (
   <Container>
      {
         (types.value === 1) &&
           statsResult &&
           (typeof statsResult.bm_emission_json !== 'undefined') && (
          <EmissionEvent result={statsResult.bm_emission_json} entity={entity} />
        )
      }
      {
         (types.value === 2) &&
           statsResult &&
           (typeof statsResult.bm_annual_facility_emissions_json !== 'undefined') && (
        <AirEmission result={statsResult.bm_annual_facility_emissions_json} entity={entity} />
      )
      }
      { (types.value === 3) && <EnforcementEvent result={statsResult} entity={entity} cnStatsResult={cnResults}/> }
   </Container>
  );
};

export default Emissions;
