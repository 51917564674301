import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { IAuthContext } from '../context/auth/auth-context';
import withAuthContext from '../context/auth/AuthConsumer';
import PaperContainer from '../components/PaperContainer';
import axios from 'axios';
import { CeresLogger } from '../logger';
import { projectId } from '../services/firebase';
import { Row, Col } from 'react-bootstrap';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-ignore
import createFilterOptions from 'react-select-fast-filter-options';
// @ts-ignore
import Select from 'react-virtualized-select';
import './react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import Emission from '../components/benchmarking/Emission';
import queryString from 'query-string';

const BenchmarkWrapper = styled.div`
  overflow: auto;
  height: 100vh;
`;

const BenchmarkContainer = styled.div`
  margin: 4em;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.7rem;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const CorporateSelect = styled(Select)`
  margin-bottom: 10px;
`;

const Benchmarking: React.FC<
  RouteComponentProps<any> & {
    context: IAuthContext;
  }
> = (props) => {
  // const [startDate, setStartDate] = useState(new Date());
  // const [getOptions, setOptions] = useState<any>();
  const [entity, setEntity] = useState<any>();
  const [entityType, setEntityType] = useState<any>();
  const [filter, setFilter] = useState();
  const [statsResult, setStatsResult] = useState<any>();
  const [statsCnResults, setStatsCnResults] = useState<any>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [resultRecords, setResultRecords] = useState<any>();
  const [label, setLabel] = useState<string>('');

  interface ICustomerData {
    'CN Number': string;
    'CN Name': string;
  }
  interface IApiResponse {
    customer_list: ICustomerData[]; // Adjust the property name if necessary
  }
  const fetchRNStats = async (type: any, value: any) => {
    setIsDisabled(true);
    let emissionApiUrl: any = '';
    if (type === 1) {
      emissionApiUrl = 'https://us-central1-' + projectId + '.cloudfunctions.net/api/fetchRNStats';
    } else if (type === 2) {
      emissionApiUrl = 'https://us-central1-' + projectId + '.cloudfunctions.net/api/fetchAirFacilityEmissionsNStats';
    } else if (type === 3) {
      emissionApiUrl = 'https://us-central1-' + projectId + '.cloudfunctions.net/BenchmarkingEnforcement?action=rn_details&rn=' + value;
    }
    try {
      if (type !== 3) {
      await axios
        .post(
          emissionApiUrl,
          { RN: value },
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response) => {
          setStatsResult(response.data[0]);
          setIsDisabled(false);
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally();
      } else if (type === 3) {
        await axios
        .get(
          emissionApiUrl,
        )
        .then((response) => {
          setStatsResult(response.data.data[0].enforcement_list);
          setIsDisabled(false);
        });
      }
    } catch (e) {
      CeresLogger.error(e);
    }
  };
  const getCorporates = () => {
    try {
      axios
        .get(
          'https://us-central1-' + projectId + '.cloudfunctions.net/api/eventEmissions/corporates',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/eventEmissions/corporates',
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response) => {
          setResultRecords(response.data[0].jsonlistb);
          setFilter(() => createFilterOptions({ options: resultRecords }));
          // setOptions(resultRecords);
          const filterData: any = queryString.parse(props.history.location.search);
          if (filterData.rn && filterData.event) {
            const entityFilterRecord = searchParam(resultRecords, filterData.rn);
            const entityFilterType = searchParam(emissionType, parseInt(filterData.event, 10));
            setEntity(entityFilterRecord);
            setEntityType(entityFilterType);
            fetchRNStats(entityFilterType.value, entityFilterRecord.value);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally();
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  useEffect(() => {
    getCorporates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(window.top !== window.self) {
      const filterData: any = queryString.parse(props.history.location.search);
      if (filterData.rn && resultRecords) {
        const entityFilterRecord = searchParam(resultRecords, filterData.rn);
        const entityFilterType = emissionType[0];
        entityFilterRecord ? setEntity(entityFilterRecord) : setEntity(filterData.rn);
        setEntityType(entityFilterType);
        entityFilterRecord && fetchRNStats(entityFilterType.value, entityFilterRecord.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultRecords]);

  const emissionType = [
    {
      label: 'Emissions Events',
      value: 1,
    },
    {
      label: 'Annual Facility Emissions',
      value: 2,
    },
    {
      label: 'Enforcement',
      value: 3,
    },
  ];

  const searchParam = (data: any, value: any): any => {
    for (const aData of data) {
      if (aData.value === value) {
        return aData;
      }
    }
  };

  useEffect(() => {

    const fetchCNNumbers = async () => {
    if (label && entityType) {
      const keyword = label.trim().split(' ')[0]; // Extract the first word
      // tslint:disable-next-line:no-console

      let companyUrl = '';

      if (entityType.value === 3) {
        companyUrl = `https://us-central1-${projectId}.cloudfunctions.net/BenchmarkingEnforcement?action=customer_list&keyword=${keyword}`;

        await axios
          .get<IApiResponse>(companyUrl)
          .then(async (response) => {
            const cnNumbers = response.data.customer_list.map((item) => item['CN Number']);
            const cnNumbersString = cnNumbers.join(',');  // Join CN Numbers as a single string
            setIsDisabled(false); // After setting cnResult, make the second API call
            await axios
              .get(
                'https://us-central1-' + projectId + '.cloudfunctions.net/BenchmarkingEnforcement?action=cn_details&rn=' + entity.value + '&cn=' + cnNumbersString,
              )
              .then((apiResponse) => {
                setStatsCnResults(apiResponse.data.data[0].enforcement_list);
                setIsDisabled(false);
                // tslint:disable-next-line:no-console
                console.log('Response from second API:', apiResponse);
              })
              .catch((error) => {
                // tslint:disable-next-line:no-console
                console.error('Error calling second API:', error);
                setIsDisabled(false);
              });
          })
          .catch((error) => {
            // tslint:disable-next-line:no-console
            console.error('Error fetching CN Numbers:', error);
          });

      }
    }};
    fetchCNNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, entityType]);

  return (
    <BenchmarkWrapper>
      <BenchmarkContainer>
        <HeaderRow>
          {!!isDisabled && (
            <>
              <div>
                {/* Fetching Corporates{' '} */}
                <Spinner animation="border" variant="dark" size="sm" />
              </div>
            </>
          )}
        </HeaderRow>
        <ResultContainer>
          <Row>
            <Col md={8}>
              <CorporateSelect
                value={entity}
                className="basic-single"
                filterOptions={filter}
                disabled={isDisabled}
                name="corporate1"
                options={resultRecords}
                onChange={(e: any) => {
                  setEntity(e);
                  setLabel(e.label);
                  if (e && entityType) {
                    fetchRNStats(entityType.value, e.value);
                  }
                }}
              />
            </Col>
            <Col md={4}>
              <CorporateSelect
                value={entityType}
                className="basic-single"
                filterOptions={filter}
                disabled={isDisabled}
                name="events"
                options={emissionType}
                onChange={(e: any) => {
                  setEntityType(e);
                  if (e) {
                    fetchRNStats(e.value, entity.value);
                  }
                }}
              />
            </Col>
          </Row>
        </ResultContainer>
        <PaperContainer>
          {/* <DatePickerContainer>
            <b>Month : &nbsp;</b>
              <DatePicker
                selected={startDate}
                onChange={val => {
                  if (val) {
                    setStartDate(val);
                  }
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
          </DatePickerContainer> */}
          {statsResult && entity && (
            <ResultContainer>
              <Emission result={statsResult} entity={entity} types={entityType} cnStatsResult ={statsCnResults} />
            </ResultContainer>
          )}
        </PaperContainer>
      </BenchmarkContainer>
    </BenchmarkWrapper>
  );
};

export default withAuthContext(Benchmarking);
