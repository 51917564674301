import React from 'react';
import { alpha, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import { File, PlusCircle, MinusCircle } from 'react-feather';
import { TransitionProps } from '@material-ui/core/transitions';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import Collapse from '@material-ui/core/Collapse';
import styled from 'styled-components';
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';
interface ITreeViewProps {
  details: any;
}
function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });
  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const HeaderContainer = styled.div`
    margin-top: 15px;
    overflow: hidden;
`;
const TreeFont = styled.div`
    font-size:14px;
`;

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }),
)((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const renderTree = (nodes: any) => (
  Object.entries(nodes).map(([keys, value]) => {
    if ((typeof value === 'object') && (value !== null)) {
      return <StyledTreeItem key={keys} nodeId={keys} label={<strong>{keys}</strong>}>
        {renderTree(value)}
      </StyledTreeItem>;
    } else {
      return <StyledTreeItem
        key={keys}
        nodeId={keys}
        label={
          <TreeFont>
            <strong>{keys + ': '}</strong>
            {ReactHtmlParser(value)}
          </TreeFont>
        }
      >
      </StyledTreeItem>;
    }
  })
);

const TreeData: React.FC<
ITreeViewProps>  = (props) => {
  return (
    <HeaderContainer>
      <TreeView
        defaultCollapseIcon={<MinusCircle />}
        defaultExpanded={['root']}
        defaultExpandIcon={<PlusCircle size={32} />}
        defaultEndIcon={<File color="#50575f" />}
      >
        {props.details && renderTree(props.details)}
      </TreeView>
    </HeaderContainer>
  );
};

export default TreeData;
