import { IconButton } from '@material-ui/core';
import {
  StarBorder as EmptyStarIcon,
  Star as FillStarIcon
} from '@material-ui/icons';
import styled from 'styled-components';
import React, { useMemo, useState } from 'react';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import { saveLibrary } from '../../services/firebase';
import showToastMessage from '../../util/showToastMessage';
import { IAuthContext } from '../../context/auth/auth-context';
import { isExxonMobileEmail } from '../Validator';

interface IPropsType {
  context: IAuthContext;
  libraryFavorites: any[];
  showLibraryQuestionsPopup: boolean;
  setShowLibraryQuestionsPopup: (flag: boolean) => void;
  setLibraryFavorites: (favoritePrompt: any) => void;
}

const OptionsList = styled.div`
  display: flex;

  .option-checkbox {
    width: 100%;
    margin-left: 10px;
    margin-bottom: 0;
  }
  input[type="checkbox"] {
    width: 20px;
  }
`;

const LibraryQuestionsListModal = ({ context, showLibraryQuestionsPopup, setShowLibraryQuestionsPopup, libraryFavorites, setLibraryFavorites }: IPropsType) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [libraryType, setLibraryType]: any = useState(2);
  const isSaveEnable = useMemo(() => libraryFavorites.some((o: any) => o.isFavorite || o.id), [libraryFavorites]);
  const saveTypeOptions = isExxonMobileEmail(context)

    ? [{ label: 'Save to Private Library', value: 2 }]
    : [
        { label: 'Save to Library', value: 1 },
        { label: 'Save to Private Library', value: 2 },
        { label: 'Save to Both', value: 3 },
      ];

  /**
   * Select favorite from prompt modal
   * @param index
   */
  function onLibraryFavoriteClick(index: number) {
    const updatedFavorites = libraryFavorites.map((item: any, i: number) =>
      index === i ? { ...item, isFavorite: item.hasOwnProperty('isFavorite') ? !item.isFavorite : true } : item
    );

    setLibraryFavorites(updatedFavorites);
  }

  const saveToLibrary = () => {
    try {
      setShowSpinner(true);

      const libraryData = libraryFavorites.filter(data => data.id || data.isFavorite);
      libraryData.forEach(async (item: any, i: number) => {
        if (item.id && item.isFavorite) {
          await createRemoveLibraryQuestion('update', item, i);
        } else {
          const actionType = item.id ? 'remove' : 'create';
          await createRemoveLibraryQuestion(actionType, item, i);
        }
      });

      const successMessage = 'Library Saved Successfully.';
      showToastMessage({ type: 'success', title: successMessage });

    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(false);
      setShowLibraryQuestionsPopup(false);
    }
  };

  // Handle catch error
  function handleErrorMessage(error: Error) {
    const { name, message } = error;
    showToastMessage({ type: 'error', title: name, description: message });
  }

  const createRemoveLibraryQuestion = async (type: string, prompt: any, i: number) => {
    const email = context.getUserEmail();

    const response = await saveLibrary({
      type,
      id: prompt.id,
      record: { libraryData: [prompt.question] },
      domain: email.split('@')[1],
      libraryType : libraryType || 1
    });

    if(response && response.data) {
      const updatedFavorites = libraryFavorites;
      updatedFavorites[i].id = type === 'create' ? response.data.id : type === 'update' ? updatedFavorites[i].id : '';
      setLibraryFavorites(updatedFavorites);
    }
  };

  return (
    <Modal
      show={showLibraryQuestionsPopup}
      onHide={() => setShowLibraryQuestionsPopup(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 99999 }}
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add To Library
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {libraryFavorites.map((item: any, i: number) => (
          <Card key={i} text="primary" className="mb-2">
            <Card.Body className="d-flex justify-content-between align-items-center">
              <Card.Title className="mb-0">{item.question}</Card.Title>
              <IconButton
                onClick={() => onLibraryFavoriteClick(i)}
                aria-label="fav-icon"
                component="span"
              >
                {item.isFavorite ? (
                  <FillStarIcon style={{ color: '#faaf00' }} />
                ) : (
                  <EmptyStarIcon style={{ color: '#faaf00' }} />
                )}
              </IconButton>
            </Card.Body>
          </Card>
        ))}
      </Modal.Body>
      <Modal.Footer className="d-block">
        <div>
          { saveTypeOptions.map((item: any, i: number) => (
            <OptionsList key={i} className="text-primary mb-1">
              <input type="checkbox" id={`library-${i}`} checked={libraryType === item.value}  onChange={() => setLibraryType(item.value)}/>
              <label htmlFor={`library-${i}`} className="option-checkbox">{item.label}</label>
            </OptionsList>
          ))}
        </div>
        <div className="d-flex justify-content-between">
        <div>
          {isExxonMobileEmail(context) && (
            <span style={{ color: 'red' }}>
              * When saved, the items will be stored in the Private Library.
            </span>
          )}
        </div>
          <div>
          <Button onClick={saveToLibrary} disabled={!isSaveEnable || (!libraryType)}>
            Save
            {showSpinner && (
              <Spinner animation="border" variant="light" size="sm" className="ml-2" />
            )}
          </Button>
          <Button className="ml-1" onClick={() => setShowLibraryQuestionsPopup(false)}>Cancel</Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LibraryQuestionsListModal;
