// Core libraries
import axios, { Method } from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Third-party libraries
import { IconButton } from '@material-ui/core';
import {
  ArrowUpwardOutlined as ArrowUpwardOutlinedIcon,
  AttachFile as AttachFileIcon,
  // Language as SearchIcon,
  ClearAll as ClearAllIcon,
  CloseRounded,
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  Edit as EditIcon,
  StarBorder as EmptyStarIcon,
  FileCopy,
  Star as FillStarIcon,
  Help as HelpIcon,
  LibraryBooks as LibraryBooksIcon,
  Map as MapIcon,
  Person as PersonIcon,
  Print as PrintIcon,
  Save as SaveIcon,
  Update as UpdateIcon
} from '@material-ui/icons';
import { jsPDF } from 'jspdf';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

// Local imports
import Logo from '../../assets/enviro.svg';
import HeaderEnviroAILogo from '../../assets/header-enviroai-logo.svg';
// import ViewTools from '../../assets/view-tools.svg';
// import VoiceChat from '../../assets/voice-chat.svg';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import { saveChats, saveProfile, savePrompts } from '../../services/firebase';
import { removeGlobalFlag, updateGlobalFlags, updateQuickPrompts, updateSavedChatData } from '../../store/aiearth/action';
import showToastMessage from '../../util/showToastMessage';
import Profile from '../home/Profile';
import SavedLibrary from '../home/SavedLibrary';
import SavedPrompts from '../home/SavedPrompts';
import { isExxonMobileEmail } from '../Validator';
import ChatTitleList from './ChatTitleList';
import './index.scss';
import LibraryQuestionsListModal from './LibraryQuestionsListModal';
import MessageLoader from './MessageLoader';

const StyledTooltip = styled(ReactTooltip)`
  max-width: 15% !important
`;

const EnviroAI: React.FC<{ context: IAuthContext }> = (props) => {
  const history = useHistory();
  const context = props.context;
  const [currentChat, setCurrentChat] = useState<number | null>(null);
  const [savedChats, setSavedChats]: any = useState([]);
  const [showSpinner, setShowSpinner] = useState<string | null>(null);
  const [messages, setMessages]: any[] = useState([]);
  const [searchWord, setSearchWord] = useState('');
  const [isNewChatEnable, setIsNewChatEnable] = useState(true);
  const [newChat, setNewChat]: any = useState(null);
  const [savedChatId, setSavedChatId] = useState(null);
  // const [showDraftBtn, setShowDraftBtn] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [showSavePromptBtn, setShowSavePromptBtn] = useState(false);
  const [showSaveLibraryBtn, setShowSaveLibraryBtn] = useState(false);
  const [favorites, setFavorites] = useState<any[]>([]);
  const [libraryFavorites, setLibraryFavorites] = useState<any[]>([]);
  const [showQuestionsPopup, setShowQuestionsPopup] = useState(false);
  const [showLibraryQuestionsPopup, setShowLibraryQuestionsPopup] = useState(false);
  const [prompts, setPrompts] = useState<any[]>([]);
  const [showSavePrompt, setShowSavePrompt] = useState(false);
  const [showSaveLibrary, setShowSaveLibrary] = useState(false);
  const [showSavePrivateLibrary, setShowSavePrivateLibrary] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showDeleteConfitmationPopup, setShowDeleteConfitmationPopup] = useState(false);
  const [activeProfile, setActiveProfile] = useState<string | null>(null);
  const [isChatUpdate, setIsChatUpdate] = useState(false);
  const [editableIndex, setEditableIndex] = useState<number | any>(null);
  const [totalSavedChats, setTotalSavedChats] = useState(0);
  const [mainQuestion, setMainQuestion] = useState('');
  const [animateStartIndex, setAnimateStartIndex] = useState(0);
  // totalDraftLoaded use to keep 3 drafts loading synchronizely
  const [totalDraftLoaded, setTotalDraftLoaded] = useState(3);
  const [files, setFiles]: any[] = useState([]);
  const [filePreviews, setFilePreviews]: any[] = useState([]);
  const store: any = useSelector<any>((state: any): any => state);
  const imageTypes = ['image/jpg','image/jpeg','image/png'];
  const textareaRef: any = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      Promise.all([
        getSavedChats({ type: 'getAll' }),
        getPrompts(),
        getProfileData()
      ]);

      // if(localStorage.getItem('previousPage') || localStorage.getItem('isBackToEnviroPage') || store.savedChat) {
        // localStorage.removeItem('previousPage');
        // localStorage.removeItem('isBackToEnviroPage');
        // dispatch(updateSavedChatData({}));
      // }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStorage.getItem('isBackToEnviroPage') && store.savedChat && store.savedChat.chatData) {
      handlePersistantChat();
      return;
    } else {
      clearPreviousChatData();
    }
    /** when click on new chat button */
    if (isNewChatEnable) {
      resetChatState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewChatEnable]);

  /** Clears previous chat data and updates state */
  const clearPreviousChatData = () => {
    dispatch(removeGlobalFlag('previousPage'));
    localStorage.removeItem('isBackToEnviroPage');
    dispatch(updateSavedChatData({}));
  };

  /** Resets the chat state for a new chat session */
  const resetChatState = () => {
    setCurrentChat(null);
    setNewChat([]);
    setSavedChatId(null);
    setMessages([]);
    setFilePreviews([]);
    setFiles([]);
    setShowSaveBtn(false);
    setShowSavePromptBtn(false);
    setShowSaveLibraryBtn(false);
    setShowSavePrivateLibrary(false);
    setEditableIndex(null);
    setTotalDraftLoaded(3);
    setIsChatUpdate(false);
  };

  useEffect(() => {
    if (currentChat === null) { return; }

    handleNewChatToggle();
    loadCurrentChatData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat, savedChats]);

  /** Handles the toggling and state reset for new chats */
  const handleNewChatToggle = () => {
    setNewChat(null);
    setIsNewChatEnable(false);
  };

  /** Loads the current chat data by ID or payload */
  const loadCurrentChatData = () => {
    if (currentChat === null) { return; }
    const currentChatData = savedChats[currentChat];

    if (currentChatData && currentChatData.id) {
      getChatById({ type: 'get', id: currentChatData.id });
    } else if (currentChatData && currentChatData.payload) {
      setMessages(currentChatData.payload.chatData);
    }
  };

  useEffect(() => {
    if (!currentChat) {
      updateFavorites();
      updateLibraryFavorites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  /** Updates the favorites list based on the messages */
  const updateFavorites = () => {
    const favoritesList = generateQuestionsList(messages, favorites);
    setFavorites(favoritesList);
  };

  /** Updates the library favorites list based on the messages */
  const updateLibraryFavorites = () => {
    const libraryFavoritesList = generateQuestionsList(messages, libraryFavorites);
    setLibraryFavorites(libraryFavoritesList);
  };

  /**
   * Generates a list of questions with their favorite status
   * @param {Array} chats - The list of chats
   * @param {Array} favoritesData - The list of favorites or library favorites
   * @returns {Array} The list of questions with favorite status
   */
  const generateQuestionsList = (chats: any[], favoritesData: any[]) => {
    const lastIndex = chats.length - 1;

    return chats.map((item: any, i: number) => ({
      id: libraryFavorites[i] && libraryFavorites[i].id ? libraryFavorites[i].id : '',
      question: item.q.title,
      isFavorite: lastIndex !== i && favoritesData.length && favoritesData[i] ? favoritesData[i].isFavorite : false,
    }));
  };

  useEffect(() => {
    handleDraftsLoaded();
    handleFilesReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalDraftLoaded]);

  /** Handles logic when drafts are loaded */
  const handleDraftsLoaded = () => {
    if (totalDraftLoaded > 2) {
      handleSingleMessageDraft();
      hideDraftLoader();
    }
  };

  /** Shows save buttons if one or more than one messages is loaded */
  const handleSingleMessageDraft = () => {
    if (messages.length >= 1) {
      setShowSaveBtn(true);
      setShowSavePromptBtn(true);
      setShowSaveLibraryBtn(true);
    }
  };

  /** Hides the draft loader spinner */
  const hideDraftLoader = () => {
    if (showSpinner === 'view_draft_loader') {
      setShowSpinner('');
    }
  };

  /** Resets the files array when all drafts are loaded */
  const handleFilesReset = () => {
    if (totalDraftLoaded >= 3) {
      setFiles([]);
    }
  };

  const handlePersistantChat = async () => {
    const newChatData = store.savedChat;

    // If there is no saved chat data, exit early
    if (!newChatData || !newChatData.chatData) { return; }

    initializeChat(newChatData);
    clearPreviousSession();

    const lastData = newChatData.chatData[newChatData.chatData.length - 1].q;

    // Determine the loading strategy based on the number of responses
    if (Object.keys(lastData).length === 1) {
      loadAdditionalResponse(lastData.title, newChatData.chatData.length);
    } else if (Object.keys(lastData).length === 2) {
      await loadTwoAdditionalResponses(lastData.title);
    } else if (Object.keys(lastData).length === 3) {
      await loadRemainingResponse(lastData.title, newChatData.chatData.length);
    } else {
      showSaveOptions();
    }

    setIsNewChatEnable(false);
  };

  // Initialize chat data and UI state
  const initializeChat = (newChatData: any) => {
    setAnimateStartIndex(newChatData.chatData.length);
    setMainQuestion(newChatData.mainTitle);
    setTimeout(() => {
      setNewChat(newChatData);
      setMessages(newChatData.chatData);
    });
  };

  // Clear any previous session data
  const clearPreviousSession = () => {
    dispatch(removeGlobalFlag('previousPage'));
    dispatch(updateSavedChatData({}));
    localStorage.removeItem('isBackToEnviroPage');
  };

  // Load additional responses if only one response is loaded
  const loadAdditionalResponse = (title: string, chatLength: number) => {
    setAnimateStartIndex(chatLength - 1);
    searchInBot(title, true);
  };

  // Load two more responses if only one response is already loaded
  const loadTwoAdditionalResponses = async (title: string) => {
    setTotalDraftLoaded(1);
    const drafts = store.globalFlags.loadedDrafts || [];
    const loaded = drafts[0];
    const draftsLookup: any = {
      1: [2, 3],
      2: [1, 3],
      default: [1, 2]
    };

    const draftsRemain = draftsLookup[loaded] || draftsLookup.default;

    setTimeout(() => {
      otherDrafts(title, draftsRemain);
    });
  };

  // Load the remaining response when two responses are already loaded
  const loadRemainingResponse = async (title: string, chatLength: number) => {
    setTotalDraftLoaded(2);
    const chatStatus = getChatStatus();
    const predQueryString = buildQueryString(title, chatStatus);

    // const loadedDrafts = JSON.parse(localStorage.getItem('loadedDrafts') || '[]');
    const loadedDrafts = store.globalFlags.loadedDrafts || [];
    const draftRemain = loadedDrafts.includes(2) && loadedDrafts.includes(3) ? 1 : loadedDrafts.includes(1) && loadedDrafts.includes(3) ? 2 : 3;

    const isTestEnvironment = process.env.REACT_APP_PROJECT_ID === 'ceres-platform-test';

    const getApiEndPoint = (draft: number) => {
      const envPrefix = isTestEnvironment ? '_TEST' : '';
      const endpoints: any = {
        1: process.env[`REACT_APP_ENVIROCHAT_GEMINI${envPrefix}`],
        2: process.env[`REACT_APP_ENVIROCHAT_GPT${envPrefix}`],
        3: process.env[`REACT_APP_ENVIROCHAT_CLAUDE${envPrefix}`]
      };
      return endpoints[draft];
    };
    const apiEndPoint = getApiEndPoint(draftRemain);
    await getDraftData(apiEndPoint,draftRemain,chatLength, 'GET', predQueryString);
  };
  // Helper function to determine the chat status
  const getChatStatus = () => {
    if (currentChat != null && currentChat >= 0) {
      return 'ongoing';
    }
    return newChat && newChat.chatData && newChat.chatData.length > 0 ? 'ongoing' : 'new';
  };

  // Helper function to build the query string for API requests
  const buildQueryString = (title: string, chatStatus: string) => {
    let queryString = `?q=${encodeURIComponent(title)}&chatStatus=${chatStatus}`;

    if (activeProfile) {
      queryString += `&profile=${encodeURIComponent(activeProfile)}`;
    }

    const localUserId = localStorage.getItem('uid');
    if (localUserId) {
      queryString += `&userId=${encodeURIComponent(localUserId)}`;
    }

    return queryString;
  };

  // Show save buttons if all responses are loaded
  const showSaveOptions = () => {
    setShowSaveBtn(true);
    setShowSavePromptBtn(true);
    setShowSaveLibraryBtn(true);
  };

  async function getPrompts() {
    try {
      setShowSpinner('load_question_list');

      const storedPromptsData = Object.values(store.quickPrompts);

      if (storedPromptsData.length > 0) {
        setPrompts(storedPromptsData);
        return;
      }

      const response = await savePrompts({ type: 'getAll', pageSize: 4 });

      if (response && response.data) {
        showToastMessage({ type: 'info', title: 'Retrieved prompts successfully.' });

        // Flatten the nested arrays to retrieve prompt data
        const promptsList = response.data.data.flatMap((items: any) => items.payload.promptData).flat(2);
        setPrompts(promptsList);
        dispatch(updateQuickPrompts(promptsList));
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(null);
    }
  }

  async function getChatById(data: any) {
    try {
      setShowSpinner('load_current_chat');
      const response = await saveChats(data);

      if (isValidChatResponse(response)) {
        updateSavedChats(response.data.payload.chatData);
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setTotalDraftLoaded(3);
      setIsChatUpdate(false);
      setShowSpinner('');
    }
  }

  function isValidChatResponse(response: any): boolean {
    return response && response.data && Array.isArray(response.data.payload.chatData);
  }

  function updateSavedChats(chatData: any[]): void {
    if(currentChat === null || !savedChats[currentChat]) {
      return;
    }

    const currentSavedChat = savedChats[currentChat];

    currentSavedChat.payload.chatData = chatData;
    setMessages(chatData);
    setMainQuestion(currentSavedChat.payload.mainTitle);
    setSavedChatId(savedChats[currentChat].id);
  }

  /** Get saved chats */
  async function getSavedChats(data: any) {
    try {
      setShowSpinner('load_question_list');

      const response = await saveChats({...data, pageSize: 15});

      if (response && response.data) {
        showToastMessage({ type: 'info', title: 'Retrieved saved chats successfully.' });
        const savedChatData = response.data.data.map((item: any, i: number) => ({
          ...item,
          index: i
        }));
        setSavedChats(savedChatData);

        setTotalSavedChats(response.data.totalItems);
        setSavedChatId((id) => {
          if(id !== null) {
            setCurrentChat(0);
          }
          return id;
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(null);
    }
  }

  /** Get current active profile */
  const getProfileData = async () => {
    try {
      const response = await saveProfile({ type: 'getAll' });

      if (response && response.data && response.data.length) {
        const activeProfileData = response.data[0].payload.profileData.find((item: any) => item.enable === true);
        const activeProfileTitle = activeProfileData ? activeProfileData.title : null;
        setActiveProfile(activeProfileTitle);
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    }
  };

  const toggleChat = async (id: number) => {
    if (id === currentChat) {
      return;
    }
    setFiles([]);
    setFilePreviews([]);
    setCurrentChat(id);
  };

  /**
   * Hyperlink generate from plain text
   * @param answer
   * @param index
   * @returns
   */
  function generateHyperlink(answer: any, index: number) {
    if (!answer) { return; }
    const answerParts = answer.split('\n\n');
    const tag = answerParts[0];
    const message = answerParts.slice(1).join('\n\n');
    // Regular expression to match URLs in plain text
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    // Check if the message already contains <a> tags
    const containsAnchorTag = /<a\s+(?:[^>]*?\s+)?href=/.test(message);
    // Replace URLs with anchor tags only if <a> tags are not already present
    const linkedText = containsAnchorTag ? message : message.replace(urlRegex, (url: string) => `<a href="${url}" target="_blank">${url}</a><br />`);
    return { __html: `<p class="label">${tag}</p><br /><div class="answer-description">${linkedText}</div>` };
  }
  /**
   * Add new question
   * @param question
   * @returns
   */
  const addQuestion = (question: string) => {
    if (isNewChatEnable) {
      // New chat mode enable
      const payload = { mainTitle: question, chatData: [{ q: { title: question }, attachFiles: filePreviews || [], timestamp: Math.floor(Date.now() / 1000) }] };
      setNewChat(payload);
      setMessages(payload.chatData);
      setIsNewChatEnable(false);
      return;
    }

    if (newChat) {
      // New chat multiple question handler
      setNewChat((chat: any) => {
        const updatedChatData = [...chat.chatData, { q: { title: question }, attachFiles: filePreviews, timestamp: Math.floor(Date.now() / 1000) }];
        setMessages(updatedChatData);
        return { ...chat, chatData: updatedChatData };
      });
      return;
    }

    if (currentChat === null) {
      return;
    }

    // Add question to the existing chat
    const chatData = savedChats[currentChat].payload.chatData;
    savedChats[currentChat].payload.chatData = [...chatData, { q: { title: question }, attachFiles: filePreviews, timestamp: Math.floor(Date.now() / 1000) }];
    setMessages(savedChats[currentChat].payload.chatData);
  };

  /**
   * Add new answer to chatbot
   * @param answer
   * @param draftNumber
   * @param i
   */
  const addAnswer = (answer: string, draftNumber: number, i: number) => {
    let chatData: any = null;

    // New chat mode
    setNewChat((payload: any) => {
      if (payload && payload.chatData) {
        const length = payload.chatData.length ? payload.chatData.length - 1 : 0;
        chatData = payload.chatData[length];
      }
      return payload;
    });

    if(!chatData) {
      if(currentChat === null) { return; }
      chatData = savedChats[currentChat].payload.chatData[i];
    }

    if (draftNumber === 1) {
      chatData = { ...chatData, q: { ...chatData.q, a1: answer } };
    } else if (draftNumber === 2) {
      chatData = { ...chatData, q: { ...chatData.q, a2: answer } };
    } else {
      chatData = { ...chatData, q: { ...chatData.q, a3: answer } };
    }

    // When new chat is enabled
    if (currentChat === null) {
      setNewChat((chat: any) => {
        if(chat) {
          const length = chat && chat.chatData && chat.chatData.length ? chat.chatData.length - 1 : 0;
          chat.chatData[length] = chatData;
        }
        setMessages(chat.chatData);
        return chat;
      });
      return;
    }

    savedChats[currentChat].payload.chatData[i] = chatData;
    setMessages(savedChats[currentChat].payload.chatData);
  };

  const getDraftData = async (url: string = '', enviroNumber: number, index: number, requestType: Method, predQueryString: string, formData?: any) => {
    const reqUrl = requestType === 'GET' ? `${url}${predQueryString}` : url;
    await axios(reqUrl, { data: formData ? formData : undefined, method: requestType }).then(res => {
      if (res && res.data) {
        let total = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let question = '';
        const queryParams = new URLSearchParams(reqUrl.split('?')[1]);
        const qValue = queryParams.get('q');
        setMainQuestion((prevState: string) => {
          question = prevState;
          return prevState;
        });
        setTotalDraftLoaded(count => {
          total = count + 1;
          return count;
        });
        // tslint:disable-next-line:no-console
        console.log(qValue, question);

        // To fixed issue when search question and go to saved chat and search again.
        if(total > 3) {
          return;
        }
        const modelName = enviroNumber === 1 ? 'Gemini Base-Model' : enviroNumber === 2 ? 'OpenAI Base-Model' : 'Anthropic Base-Model';
        if(process.env.REACT_APP_PROJECT_ID === 'ceres-platform-test') {
          const NewMainString = res.data.content;
          const newResponse = `EnviroAI Model Response #${total} - ${modelName}\n\n${NewMainString}`;
          addAnswer(newResponse, total, index);
        } else {
          const mainString = res.data.content;
          const response = `EnviroAI Model Response #${total} - ${modelName}\n\n${mainString}`;
          addAnswer(response, total, index);
        }
        setTotalDraftLoaded(count => {
          // Set loadedDrafts number
          dispatch(updateGlobalFlags({ loadedDrafts: [...(store.globalFlags.loadedDrafts || []), enviroNumber] }));
          return count + 1;
        });

      } else {
        handleDraftDataNotFound(reqUrl, enviroNumber, index);
      }
    }).catch((error: any) => {
      if (error) {
        if(requestType === 'POST') {
          if(error.response && error.response.status === 500) {
            getDraftData(url, enviroNumber, index, 'GET', predQueryString);
          } else {
            const err = error && error.code === 'ECONNABORTED' ? error : { code: 413, name: 'Error', message: 'File is too large.' };
            handleDraftDataNotFound(reqUrl, enviroNumber, index, err);
          }
        } else {
          handleDraftDataNotFound(reqUrl, enviroNumber, index, error);
        }
      }
    });
  };

  const handleDraftDataNotFound = (reqUrl: string, enviroNumber: number, index: number, error?: any) => {
    let total = 0;
    setTotalDraftLoaded(count => {
      total = count + 1;
      return count;
    });
    const queryParams = new URLSearchParams(reqUrl.split('?')[1]);
    const qValue = queryParams.get('q');
    let question = '';
    setMainQuestion((prevState: string) => {
      question = prevState;
      return prevState;
    });

    if(qValue !== question || total > 3) {
      return;
    }

    const modelName = enviroNumber === 1 ? 'Gemini Base-Model' : enviroNumber === 2 ? 'OpenAI Base-Model' : 'Anthropic Base-Model';
    if(error && (error.code === 'ECONNABORTED' || error.code === 413)) {
      handleErrorMessage(error);
    } else {
      showToastMessage({ type: 'error', title: modelName, description: 'Data Not Found' });
    }
    addAnswer(`EnviroAI Model Response #${total} - ${modelName}\n\nData Not Found`, total, index);
    setTotalDraftLoaded(count => {
      // Set loadedDrafts number
      dispatch(updateGlobalFlags({ loadedDrafts: [...(store.globalFlags.loadedDrafts || []), enviroNumber] }));
      return count + 1;
    });
  };

  /** Load other drafts */
  const otherDrafts = async (searchedQuestion: string, remainingDrafts?: number[]) => {
    let total = 0;
    let oldQuestion = '';
    setMainQuestion((prevState: string) => {
      oldQuestion = prevState;
      return prevState;
    });
    setTotalDraftLoaded((prevState: number) => {
      total = prevState;
      return prevState;
    });

    if(searchedQuestion !== oldQuestion || total > 3) {
      return;
    }
    setShowSpinner('view_draft_loader');

    const index = messages.length;
    const question = searchedQuestion || messages[index - 1].q.title;

    let chatStatus = '';
    if(currentChat != null && currentChat >= 0) {
      chatStatus = 'ongoing';
    } else {
      chatStatus = newChat && newChat.chatData && newChat.chatData.length > 0 ? 'ongoing' : 'new';
    }

    let predQueryString = `?q=${encodeURIComponent(question)}&chatStatus=${chatStatus}`;

    const formData = new FormData();
    formData.append('q', question);
    formData.append('chatStatus', chatStatus);

    if(activeProfile) {
      predQueryString += `&profile=${encodeURIComponent(activeProfile)}`;
    }
    const localUserId = localStorage.getItem('uid');
    if(localUserId) {
      predQueryString += `&userId=${encodeURIComponent(localUserId)}`;
      formData.append('userId', encodeURIComponent(localUserId));
    }
    const isTestEnvironment = process.env.REACT_APP_PROJECT_ID === 'ceres-platform-test';
    const getApiEndPoint = (draft: number) => {
      const envPrefix = isTestEnvironment ? '_TEST' : '';
      if (draft === 1) { return process.env[`REACT_APP_ENVIROCHAT_GEMINI${envPrefix}`]; }
      if (draft === 2) { return process.env[`REACT_APP_ENVIROCHAT_GPT${envPrefix}`]; }
      return process.env[`REACT_APP_ENVIROCHAT_CLAUDE${envPrefix}`];
    };

    const apiEndPoints = [
      remainingDrafts ? getApiEndPoint(remainingDrafts[0]) : process.env[`REACT_APP_ENVIROCHAT_GPT${isTestEnvironment ? '_TEST' : ''}`],
      remainingDrafts ? getApiEndPoint(remainingDrafts[1]) : process.env[`REACT_APP_ENVIROCHAT_CLAUDE${isTestEnvironment ? '_TEST' : ''}`],
    ];

    const method = Array.isArray(files) && files.length ? 'POST' : 'GET';
    const draft1 = remainingDrafts ? remainingDrafts[0] : 2;
    const draft2 = remainingDrafts ? remainingDrafts[1] : 3;

    if (method === 'POST') {
      files.forEach((file: any) => formData.append('files', file));
    }

    getDraftData(apiEndPoints[0], draft1, index, method, predQueryString, method === 'POST' ? formData : undefined);
    getDraftData(apiEndPoints[1], draft2, index, method, predQueryString, method === 'POST' ? formData : undefined);
  };

  // Helper function to convert Blob to base64
  const blobToBase64 = (blob: any) => {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  /**
   * Add attachment files in search input
   * @param event
   * @returns
   */
  const uploadAttachment = async (event: any) => {
    if (!event || !event.target) { return; }

    const selectedFiles = Array.from(event.target.files);
    event.target.value = ''; // Reset the file input value to allow selecting the same file again

    const updatedFiles = [...files, ...selectedFiles];
    setFiles(updatedFiles);
    setFilePreviews([]); // Reset file previews

    // Process each file for previews
    const previews = await Promise.all(
      updatedFiles.map(async (file) => {
        if (imageTypes.includes(file.type)) {
          const base64 = await blobToBase64(file);
          return { preview: base64, name: file.name, type: file.type };
        }
        return { preview: URL.createObjectURL(file), name: file.name, type: file.type };
      })
    );

    setFilePreviews(previews);
  };

  /**
   * Remove attachment files in search input
   * @param index
   */
  const removeAttachment = (index: number) => {
    setFiles((prevFiles: any) => prevFiles.filter((_: any, i: number) => i !== index));
    setFilePreviews((prevPreviews: any) => prevPreviews.filter((_: any, i: number) => i !== index));
  };

  /** Search default draft */
  const searchInBot = async (e: any, isSuggestedQuestion: boolean) => {
    if (currentChat !== null) {
      setIsChatUpdate(true);
    }
    // Reset loadedDrafts on new question search.
    dispatch(updateGlobalFlags({ loadedDrafts: [] }));
    const question = isSuggestedQuestion ? e : e.key === 'Enter' ? e.target.value : null;
    if (!question) { return; }

    initializeSearch(question);

    const index = messages.length;
    const chatStatus = determineChatStatus();
    const predQueryString = buildQueryString(question, chatStatus);
    const formData = buildFormData(question, chatStatus);

    // Decide whether to use 'POST' or 'GET' based on the presence of files
    if (process.env.REACT_APP_PROJECT_ID === 'ceres-platform-test') {
      const requestTypes = Array.isArray(files) && files.length ? 'POST' : 'GET';
      getDraftData(
        process.env.REACT_APP_ENVIROCHAT_GEMINI_TEST,
        1,
        index,
        requestTypes,
        predQueryString,
        requestTypes === 'POST' ? formData : undefined
      );
    } else {
      const requestType = Array.isArray(files) && files.length ? 'POST' : 'GET';
      getDraftData(
        process.env.REACT_APP_ENVIROCHAT_GEMINI,
        1,
        index,
        requestType,
        predQueryString,
        requestType === 'POST' ? formData : undefined
      );
    }
    setTimeout(() => {
      otherDrafts(question);
    });
  };

  // Helper function to initialize search parameters and UI states
  const initializeSearch = (question: string) => {
    setFilePreviews([]);
    setTotalDraftLoaded(0);
    setSearchWord('');
    textareaRef.current.style.height = '34px';
    addQuestion(question);
    setMainQuestion(question);
    setTimeout(() => scrollToBottom(), 100);
  };

  // Helper function to determine the current chat status
  const determineChatStatus = () => {
    if (currentChat !== null && currentChat >= 0) {
      return 'ongoing';
    }
    return newChat && newChat.chatData && newChat.chatData.length > 0 ? 'ongoing' : 'new';
  };

  // Helper function to build the form data for 'POST' requests
  const buildFormData = (question: string, chatStatus: string) => {
    const formData = new FormData();
    formData.append('q', question);
    formData.append('chatStatus', chatStatus);

    const localUserId = localStorage.getItem('uid');
    if (localUserId) {
      formData.append('userId', encodeURIComponent(localUserId));
    }

    if (Array.isArray(files) && files.length) {
      files.forEach((file) => {
        formData.append('files', file);
      });
    }

    return formData;
  };

  /** Pin scroller to bottom  */
  const scrollToBottom = () => {
    const out = document.getElementById('out');

    if (out) {
      const scrollOptions: ScrollToOptions = {
        top: out.scrollHeight - out.clientHeight,
        behavior: 'smooth',
      };

      out.scrollTo(scrollOptions);
    }
  };

  /** Integrate create savChat api */
  const saveChatsHandler = async () => {
    if(currentChat !== null) {
      setIsChatUpdate(false);
    }
    try {
      setShowSpinner('save_btn_loader');

      const chatDataToSave = savedChatId && currentChat !== null
        ? { type: 'update', id: savedChatId, record: { ...savedChats[currentChat].payload, chatData: messages } }
        : { type: 'create', record: newChat };

      const response = await saveChats(chatDataToSave);

      if (response && response.data) {
        if (!savedChatId) {
          setSavedChatId(response.data.id);
        }

        showToastMessage({
          type: 'success',
          actionType: chatDataToSave.type === 'create' ? 'create' : 'update',
          title: `Data ${chatDataToSave.type === 'create' ? 'created' : 'updated'} successfully.`,
          description: `${chatDataToSave.type === 'create' ? 'Created' : 'Updated'} chat: ${chatDataToSave.record.mainTitle}`
        });
        setNewChat(null);
        await getSavedChats({ type: 'getAll' });
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(null);
    }
  };

  /** Delete Chat */
  const deleteChatHandler = async () => {
    try {
      setShowDeleteConfitmationPopup(false);
      setShowSpinner('delete_btn_loader');
      if(!(savedChatId && currentChat !== null)) {
        return;
      }

      const chatTitle = currentChat !== null ? savedChats[currentChat].payload.mainTitle : '';
      const response = await saveChats({
        type: 'remove',
        id: savedChatId
      });

      if (response && response.data) {
        setSavedChatId(null);
        setNewChat(null);
        setIsNewChatEnable(true);
        showToastMessage({ type: 'success', actionType: 'delete', title: 'Data deleted successfully.', description: `Deleted chat: ${chatTitle}` });
        await getSavedChats({ type: 'getAll' });
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(null);
    }
  };

  /**
   * Select favorite from prompt modal
   * @param index
   */
  function onFavoriteClick(index: number) {
    const updatedFavorites = favorites.map((item: any, i: number) =>
      index === i ? { ...item, isFavorite: item.hasOwnProperty('isFavorite') ? !item.isFavorite : true } : item
    );

    setFavorites(updatedFavorites);
  }

  /**
   * Save favorites
   */
  const saveFavorites = async () => {
    try {
      setShowSpinner('save_favorites');

      const promptData = favorites.filter(data => data.id || data.isFavorite);
      promptData.forEach(async (item: any, i: number) => {
        if (item.id && item.isFavorite) {
          return;
        } else {
          const actionType = item.id ? 'remove' : 'create';
          await createRemovePrompt(actionType, item, i);
        }
      });

      const successMessage = 'Prompts Saved Successfully.';
      showToastMessage({ type: 'success', title: successMessage });

    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(null);
      setShowQuestionsPopup(false);
    }
  };

  const createRemovePrompt = async (type: string, prompt: any, i: number) => {
    const response = await savePrompts({
      type,
      id: prompt.id,
      record: { promptData: [prompt.question] }
    });

    if(response && response.data) {
      const updatedFavorites = favorites;
      updatedFavorites[i].id = type === 'create' ? response.data.id : '';
      setFavorites(updatedFavorites);
    }
  };

  const promptChangesHandler = (promptsData: any) => {
    // Flatten the nested arrays using flat()
    const promptsList = promptsData.flatMap((items: any) => items.payload.promptData).flat(2);
    setPrompts(promptsList);
    dispatch(updateQuickPrompts(promptsList));
  };

  const handleNavigation = (previousPage: string, searchQuery: string, searchType: string) => {
    // Set the previous page for return navigation
    dispatch(updateGlobalFlags({ previousPage }));

    // Save chat data for display when navigating back to the myenviroai page
    dispatch(updateSavedChatData(newChat));

    // Navigate to the desired page based on the search query and type
    history.push(`/search?q=${searchQuery}&type=${searchType}`);
  };

  const handleSearchClick = async (spinnerType: string, previousPage: string, searchType: string) => {
    setShowSpinner(spinnerType);
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENVIROCHAT_PROMPT_KEYWORDS}`, { prompt: mainQuestion });
      handleNavigation(previousPage, response.data, searchType);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log('Error:', error);  // Improved error logging
      handleNavigation(previousPage, mainQuestion, searchType);
    } finally {
      setShowSpinner(null);
    }
  };

  const onAgencyDocumentClick = () => {
    handleSearchClick('agency_documents', 'myenviroaiToAgency', 'default');
  };

  const onInternetResultsClick = () => {
    handleSearchClick('internet_result', 'myenviroaiToInternetResults', 'ALL');
  };

  const onFacilityMapClick = () => {
    handleNavigation('myenviroaiToFacility', '', ''); // Empty query and type as they are not needed for this navigation
    history.push(`/my-tundra`);  // Navigate to the Facility map page
  };

  const onClickPrintBtn = () => {
    // Function to sanitize text by removing HTML tags
    const sanitizeText = (text: string) => text.replace(/<\/?[^>]+(>|$)/g, '');

    // Function to split text into lines and handle page breaks
    const splitLines = (jspdf: jsPDF, text: string, y: number) => {
      const pageHeight = jspdf.internal.pageSize.getHeight();
      const textWidth = 180; // Width of the text block
      const margin = 10; // Margin from the edge of the page
      const textLines = jspdf.splitTextToSize(text, textWidth);
      const lineHeight = jspdf.getTextDimensions(textLines[0]).h; // Height of a single line

      let startY = y; // Initial Y position

      textLines.forEach((line: any) => {
        if (startY + lineHeight > pageHeight - margin) {
          jspdf.addPage(); // Add a new page
          startY = margin; // Reset startY for the new page
        }
        jspdf.text(line, margin, startY); // Add the line to the PDF
        startY += lineHeight; // Move the Y position for the next line
      });

      return startY;
    };

    // Create a new jsPDF instance
    const pdf = new jsPDF({ format: 'a4', orientation: 'portrait' });

    // Determine the title for the PDF
    let pdfTitle = 'Chat transcript';
    const chatData = savedChatId ? messages : newChat.chatData;

    if (chatData.length > 0) {
      pdfTitle = getPdfTitle(chatData[0]);
    }

    chatData.forEach((data: any, i: number) => {
      pdf.setFontSize(12);
      const questionHeight = splitLines(pdf, sanitizeText(`Q.${i + 1} ${data.q.title}`), 10);

      pdf.setFontSize(10);
      splitLines(pdf, sanitizeText(`Ans.1 ${data.q.a1}`), questionHeight + 4);
      pdf.addPage();
      splitLines(pdf, sanitizeText(`Ans.2 ${data.q.a2}`), 10);
      pdf.addPage();
      splitLines(pdf, sanitizeText(`Ans.3 ${data.q.a3}`), 10);

      if (i < chatData.length - 1) {
        pdf.addPage();
      }
    });

    // Sanitize and save the PDF
    pdfTitle = sanitizeTitle(pdfTitle);
    pdf.save(`${pdfTitle}.pdf`);
  };

  // Function to extract and format the PDF title
  const getPdfTitle = (data: any) => {
    const words = (data.q.title || 'Chat transcript').split(' ');
    return words.length >= 2 ? words.slice(0, 2).join(' ') : words.join(' ');
  };

  // Function to sanitize the title for valid filename characters
  const sanitizeTitle = (title: string) => title.replace(/[^a-zA-Z0-9 \-_]+/g, '');

  // Handle catch error
  function handleErrorMessage(error: Error) {
    const { name, message } = error;
    showToastMessage({ type: 'error', title: name, description: message });
  }

  /** Delete Chat Confirmation Modal */
  function DeleteChatConfirmationModal(modalProps: any) {
    return (
      <Modal
        {...modalProps}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Chat Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure, you want to delete <strong>{ currentChat !== null ? savedChats[currentChat].payload.mainTitle : '' }</strong> chat?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteChatHandler}>
            Delete
          </Button>
          <Button onClick={modalProps.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /** Save prompts popup */
  function QuestionsListModal(modalProps: any) {
    const isSaveEnable = favorites.some((o: any) => o.isFavorite || o.id);

    return (
      <Modal
        {...modalProps}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: 99999 }}
        centered
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add To Saved Prompts
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {favorites.map((item: any, i: number) => (
            <Card key={i} text="primary" className="mb-2">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <Card.Title className="mb-0">{item.question}</Card.Title>
                <IconButton
                  onClick={() => onFavoriteClick(i)}
                  aria-label="fav-icon"
                  component="span"
                >
                  {item.isFavorite ? (
                    <FillStarIcon style={{ color: '#faaf00' }} />
                  ) : (
                    <EmptyStarIcon style={{ color: '#faaf00' }} />
                  )}
                </IconButton>
              </Card.Body>
            </Card>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={saveFavorites} disabled={!isSaveEnable}>
            Save
            {showSpinner === 'save_favorites' && (
              <Spinner animation="border" variant="light" size="sm" className="ml-2" />
            )}
          </Button>
          <Button onClick={modalProps.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   * Default New Chat Screen
   * @returns New Chat Screen
   */
  const DefaultNewChatLayout = () => {
    // eslint-disable-next-line
    const renderPrompts = () => {
      if (showSpinner && showSpinner === 'load_question_list') {
        return (
          <div className="d-flex justify-content-end m-2">
            <Spinner animation="border" variant="primary" />
          </div>
        );
      }

      if (!prompts.length) {
        return null;
      }

      return prompts.slice(0, 4).map((question: string, i) => (
        <button
          key={i}
          type="button"
          className="d-flex btn justify-content-between align-items-center p-3 prompt-btn"
          onClick={() => searchInBot(question, true)}
        >
        <p data-tip={question}>{question}</p>
        <StyledTooltip place="bottom" effect="solid"/>
            <span
              data-tip="Click to send"
              className="btn btn-sm btn-dark arrow-btn"
              onClick={() => onFavoriteClick(i)}
            >
              <ArrowUpwardOutlinedIcon fontSize="small" />
            </span>
            <StyledTooltip place="bottom" effect="solid"/>
        </button>
      ));
    };

    return (
      <div className="newchat-layout">
        <div className="logo">
          <img src={Logo} alt="EnviroAI" className="mb-2" style={{height: 43, width: 48}}/>
          <h1 className="help-question">How can I help you today?</h1>
        </div>
        {/* <div className="suggestion-buttons">{renderPrompts()}</div> */}
      </div>
    );
  };

  /** Three dot message loader */
  // const MessageLoader = () => (
  //   <div className="loader-message d-flex gap-2 align-items-center justify-content-center">
  //     <p className="mt-2 mb-0" style={{fontSize: '14px',fontFamily: 'Roboto, sans-serif' }}>Accessing the world's most powerful environmental brain. Stand by as it sifts through millions of documents and the internet to produce your response</p>
  //     <div className="typing">
  //       <span></span>
  //       <span></span>
  //       <span></span>
  //     </div>
  //   </div>
  // );

  /** Saved chat messages area */
  // const ChatMessages = () => {
  //   if (isNewChatEnable) {
  //     return null;
  //   }

  //   return (
  //     <>
  //       {messages.map((item: any, i: number) => (
  //         <div key={i}>
  //           <ul className="mb-0 question">
  //             <li className="clearfix question">
  //               <div className="float-right">
  //                 <div className="message other-message">
  //                   {item.q.title}
  //                 </div>
  //                 <p className="message-datetime">{ item.timestamp ? moment.unix(item.timestamp).format('DD/MM/YYYY HH:mm') : moment.unix(Math.floor(Date.now() / 1000)).format('DD/MM/YYYY HH:mm')}</p>
  //               </div>
  //             </li>
  //           </ul>
  //           <ul className="mb-0 d-flex justify-content-between">
  //             {[item.q.a1, item.q.a2, item.q.a3].map((answer, index) => (
  //               <li key={index} className="clearfix answer">
  //                 {loading === `enviro${index + 1}` && i === messages.length - 1 ? (
  //                   <MessageLoader />
  //                 ) : (
  //                   answer && (
  //                     <div>
  //                       <div
  //                         className={`message my-message ${((loading === `enviro${index + 2}` || index >= 2) && i === messages.length - 1) ? 'anim' : ''}`}
  //                         dangerouslySetInnerHTML={generateHyperlink(answer, index)}
  //                       />
  //                       <p className="message-datetime">{ item.timestamp ? moment.unix(item.timestamp).format('DD/MM/YYYY HH:mm') : moment.unix(Math.floor(Date.now() / 1000)).format('DD/MM/YYYY HH:mm')}</p>
  //                     </div>
  //                   )
  //                 )}
  //               </li>
  //             ))}
  //           </ul>
  //         </div>
  //       ))}
  //     </>
  //   );
  // };

  // Adjust the height of the textarea dynamically
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '34px'; // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
    }
  };

  // Handle input change
  const handleSearchInput = (event: any) => {
    setSearchWord(event.target.value);
    adjustTextareaHeight();
  };

  // Adjust the height when the window is resized
  useEffect(() => {
    const handleResize = () => {
      adjustTextareaHeight();
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onCopyTextHandler = (title: string) => {
    setSearchWord(title);
    setTimeout(() => {
      adjustTextareaHeight();
    });
  };

  return (
    <>
      { showSavePrompt &&
        <SavedPrompts  context={props.context} closeSavedPrompt={() => setShowSavePrompt(false)} onCopyText={(title: string) => onCopyTextHandler(title)} onPromptsChange={promptChangesHandler} />}
      { showSaveLibrary &&
        <SavedLibrary context={props.context} closeSavedLibrary={() => setShowSaveLibrary(false)} libraryType={1} onCopyText={(title: string) => onCopyTextHandler(title)} />}
      { showSavePrivateLibrary &&
        <SavedLibrary context={props.context} closeSavedLibrary={() => setShowSavePrivateLibrary(false)} libraryType={2} onCopyText={(title: string) => onCopyTextHandler(title)} />}

      { showProfile && <Profile closeProfile={() => setShowProfile(false)} onToggleProfile={(profile: string) => setActiveProfile(profile)} /> }

      <div className="enviro-ai">

        {/* Header */}
        <nav className="navbar navbar-expand-lg navbar-light header fixed-top">
          <div className="collapse navbar-collapse justify-content-between" id="navbarNavAltMarkup">
            <div className="navbar-nav">
                <button id="saved-prompts-btn" className="btn btn-outline-light mr-1" onClick={() => setShowSavePrompt(true)}>
                  <ClearAllIcon /> Saved Prompts
                </button>
                <button id="profile-btn" className="btn btn-outline-light mr-1" onClick={() => setShowProfile(true)}>
                  <PersonIcon /> Profile
                </button>
                <button
                  id="library-btn"
                  className={
                    isExxonMobileEmail(context)
                      ? 'btn btn-outline-light mr-1 btn-disable'
                      : 'btn btn-outline-light mr-1'
                  }
                  onClick={() => {
                    if (!isExxonMobileEmail(context)) {
                      setShowSaveLibrary(true);
                    } else {
                      showToastMessage({
                        type: 'warn',
                        title: 'Warning',
                        description: 'This feature is disabled for ExxonMobil. Please contact the Administrator.',
                      });
                    }
                  }}
                >
                  <LibraryBooksIcon /> Library
                </button>
                <button
                  id="library-btn"
                  className= "btn btn-outline-light"
                  onClick={() => setShowSavePrivateLibrary(true)}
                >
                  <LibraryBooksIcon /> Private Library
                </button>
            </div>
            <img src={HeaderEnviroAILogo} alt="EnviroAI Logo" />
            <button id="new-chat-btn" className="btn btn-outline-light" onClick={() => setIsNewChatEnable(true)}>
                <EditIcon fontSize="small"/> New Chat
              </button>
          </div>
        </nav>

        {/* Chat area */}
        <div className="row clearfix">
          {showDeleteConfitmationPopup && <DeleteChatConfirmationModal show={showDeleteConfitmationPopup} onHide={() => setShowDeleteConfitmationPopup(false)}/>}
          <QuestionsListModal
            show={showQuestionsPopup}
            onHide={() => setShowQuestionsPopup(false)}
          />
          <LibraryQuestionsListModal
            context={props.context}
            showLibraryQuestionsPopup={showLibraryQuestionsPopup}
            setShowLibraryQuestionsPopup={setShowLibraryQuestionsPopup}
            libraryFavorites={libraryFavorites}
            setLibraryFavorites={setLibraryFavorites}
          />
          <div className="col-lg-12">
            <div className="card chat-app">
              <ChatTitleList savedChats={savedChats} setTotalSavedChats={setTotalSavedChats} totalSavedChats={totalSavedChats}
                showSpinner={showSpinner} setShowSpinner={setShowSpinner} setSavedChats={setSavedChats} getSavedChats={getSavedChats}
                editableIndex={editableIndex} currentChat={currentChat} toggleChat={toggleChat} setEditableIndex={setEditableIndex} />
              <div className="chat" id="mychat">
                { showSpinner && showSpinner === 'load_current_chat' ? (<div className="d-flex justify-content-center my-3 w-100 align-items-center h-100">
                    <Spinner animation="border" variant="primary" />
                  </div>) :
                  (messages.length ?
                    <div className="chat-history-wrapper">
                      <div className="chat-history" id="out">
                        {/* Fix re-animation issue by use html instead ChatMessages component */}
                        {messages.map((item: any, i: number) => (
                          <div key={i}>
                            <ul className="mb-0 question">
                              <li className="clearfix question">
                                <div>
                                  {item.attachFiles && item.attachFiles.map((file: any, index: number) => {
                                    return imageTypes.includes(file.type) ? (
                                      <div className="preview-img mr-1">
                                        <img src={file.preview} alt={file.name} width="200" />
                                      </div>
                                    ) : (
                                      <div className="preview-file">
                                        <FileCopy color="primary" />
                                        <div>
                                          <p>{ file.name }</p>
                                          <p>{ file.type }</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <div className="query-content">
                                    <p className="mb-0 message other-message">{item.q.title}</p>
                                  </div>
                                  <p className="message-datetime">{ item.timestamp ? moment.unix(item.timestamp).format('DD/MM/YYYY HH:mm') : moment.unix(Math.floor(Date.now() / 1000)).format('DD/MM/YYYY HH:mm')}</p>
                                </div>
                              </li>
                            </ul>
                            <ul className="mb-0 d-flex justify-content-between">
                              {[item.q.a1, item.q.a2, item.q.a3].map((answer, index) => (
                                <li key={index} className="clearfix answer">
                                  {totalDraftLoaded === index && !answer && i === messages.length - 1 ? (
                                    <MessageLoader />
                                  ) : (
                                    answer && (
                                      <div>
                                        <div
                                          className={`message my-message ${animateStartIndex <= i ? 'anim' : ''}`}
                                          dangerouslySetInnerHTML={generateHyperlink(answer, index)}
                                        />
                                        <p className="message-datetime">{ item.timestamp ? moment.unix(item.timestamp).format('DD/MM/YYYY HH:mm') : moment.unix(Math.floor(Date.now() / 1000)).format('DD/MM/YYYY HH:mm')}</p>
                                      </div>
                                    )
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                        {/* <ChatMessages /> */}
                      </div>
                    </div> : <DefaultNewChatLayout />)
                }

                {/* User search input with quick buttons */}
                <div className="chat-message-wrapper" style={{height: messages.length ? 'auto' : '450px'}}>
                  <div className={`chat-message clearfix ${messages.length ? 'message-chatSearch' : 'chatSearch'}`}>
                    {/* Active profile */}
                    {activeProfile && (newChat || isNewChatEnable) &&
                      <div className="d-flex justify-content-center my-2">
                        <p className="alert active-profile-label">
                          {/* <InfoIcon className="text-black" /> */}
                          <span className="dot-icon"></span>
                          <span>{activeProfile}</span>
                         <HelpIcon data-tip="Active Profile" fontSize="small" id="profile-help" style={{ cursor: 'pointer' }} />
                        </p>
                      </div>
                    }

                    {/* New chat quick buttons */}
                    {messages.length ?
                      <>
                        <div className="quick-buttons">
                          {/* {showDraftBtn &&
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="view-draft-btn" className="mb-2">Click to view more drafts</Tooltip>}
                            >
                              <button
                                id="view-draft-btn"
                                className="btn btn-primary rounded-pill mr-2 px-4"
                                disabled={showSpinner === 'save_prompt_btn_loader' || showSpinner === 'save_btn_loader' || showSpinner === 'view_draft_loader' || showSpinner === 'save_library_btn_loader' || !!loading}
                                onClick={() => otherDrafts('')}>
                                  <DraftsIcon /> View Other Drafts
                                  {showSpinner === 'view_draft_loader' && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                              </button>
                            </OverlayTrigger>
                          } */}
                          {mainQuestion &&
                           <button
                                data-tip="Click to view agency results"
                                id="agency-doc-btn"
                                className="btn btn-primary rounded-pill mr-2 px-4"
                                disabled={showSpinner === 'agency_documents'}
                                onClick={onAgencyDocumentClick}
                              >
                                  <DescriptionIcon /> Agency Documents
                                  {showSpinner === 'agency_documents' && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                              </button>
                          }
                          {mainQuestion &&
                           <button
                                data-tip="Click to view internet results"
                                id="internet-result-btn"
                                className="btn btn-primary rounded-pill mr-2 px-4"
                                disabled={showSpinner === 'internet_result'}
                                onClick={onInternetResultsClick}>
                                  <DescriptionIcon /> Internet Results
                                  {showSpinner === 'internet_result' && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                              </button>
                          }
                          {mainQuestion &&
                            <button
                                data-tip="Click to view facilities maps"
                                id="internet-result-btn"
                                className="btn btn-primary rounded-pill mr-2 px-4"
                                onClick={onFacilityMapClick}>
                                  <MapIcon /> Facilities Maps
                              </button>
                          }
                          {(showSaveBtn && newChat) &&
                            <button
                                data-tip="Click to save chats"
                                id="save-btn"
                                className="btn btn-primary rounded-pill mr-2 px-4"
                                disabled={showSpinner === 'save_prompt_btn_loader' || showSpinner === 'save_btn_loader' || showSpinner === 'view_draft_loader' || showSpinner === 'save_library_btn_loader' || totalDraftLoaded < 3}
                                onClick={saveChatsHandler}>
                                  <SaveIcon /> Save
                                  {showSpinner === 'save_btn_loader' && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                              </button>
                          }
                          {(showSavePromptBtn && newChat) &&
                            <button
                            data-tip={
                              isExxonMobileEmail(context)
                                ? 'This Feature is disabled for ExxonMobil. Please contact the Administrator.'
                                : ''
                            }
                                id="save-prompt-btn"
                                className={
                                  isExxonMobileEmail(context)
                                    ? 'btn btn-primary rounded-pill mr-2 px-4 btn-disable'
                                    : 'btn btn-primary rounded-pill mr-2 px-4'
                                }
                                disabled={showSpinner === 'save_prompt_btn_loader' || showSpinner === 'save_btn_loader' || showSpinner === 'view_draft_loader' || showSpinner === 'save_library_btn_loader' || totalDraftLoaded < 3}
                                 onClick={() => {
                                  if (isExxonMobileEmail(context)) {
                                    showToastMessage({
                                      type: 'warn',
                                      title: 'Warning',
                                      description:
                                        'This Feature is disabled for ExxonMobil. Please contact the Administrator.',
                                    });
                                  } else {
                                    setShowQuestionsPopup(true);
                                  }
                                }}
                                >
                                  <SaveIcon /> Save Prompts
                                  {showSpinner === 'save_prompt_btn_loader' && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                              </button>
                          }
                          {(showSaveLibraryBtn && newChat) &&
                           <button
                                id="save-library-btn"
                                className = "btn btn-primary rounded-pill mr-2 px-4"
                                onClick={() => {
                                    setShowLibraryQuestionsPopup(true);
                                }}
                                disabled={showSpinner === 'save_prompt_btn_loader' || showSpinner === 'save_btn_loader' || showSpinner === 'view_draft_loader' || showSpinner === 'save_library_btn_loader' || totalDraftLoaded < 3}
                             >
                                  <SaveIcon /> Save To Library
                                  {showSpinner === 'save_library_btn_loader' && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                              </button>
                          }
                          {(showSaveBtn || savedChatId) &&
                            <button
                              data-tip="Click to print chat"
                              id="print-btn"
                              className="btn btn-primary rounded-pill mr-2 px-4"
                              disabled={showSpinner === 'save_prompt_btn_loader' || showSpinner === 'save_btn_loader' || showSpinner === 'view_draft_loader' || showSpinner === 'save_library_btn_loader' || totalDraftLoaded < 3}
                              onClick={onClickPrintBtn}>
                                <PrintIcon /> Print
                            </button>
                          }
                          {!newChat && savedChatId && <>
                            <button
                              data-tip="Update saved chats"
                              id="update-btn" className="btn btn-primary rounded-pill mr-2 px-4"
                              disabled={showSpinner === 'save_btn_loader' || showSpinner === 'delete_btn_loader' || totalDraftLoaded < 3 || !isChatUpdate}
                              onClick={saveChatsHandler}>
                              <UpdateIcon /> Update
                              {showSpinner === 'save_btn_loader' && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                            </button>
                            <Button
                              data-tip="Delete saved chats"
                              id="delete-btn"
                              className="btn btn-primary rounded-pill mr-2 px-4"
                              disabled={showSpinner === 'save_btn_loader' || showSpinner === 'delete_btn_loader' || totalDraftLoaded < 3}
                              onClick={() => setShowDeleteConfitmationPopup(true)}
                            >
                              <DeleteIcon /> Delete
                              {showSpinner === 'delete_btn_loader' && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                            </Button>
                          </>
                          }
                        </div>
                        <div className="searchbox">
                          <div className={ filePreviews && filePreviews.length ? 'd-flex mb-1 overflow-auto' : 'd-none' }>
                            {filePreviews.map((file: any, index: number) => {
                              return imageTypes.includes(file.type) ? (
                                <div className="preview-img mr-1">
                                  <img src={file.preview} alt={file.name} width="200" />
                                  <Button variant="outline-dark" className="close-button" onClick={() => removeAttachment(index)}><CloseRounded /></Button>
                                </div>
                              ) : (
                                <div className="preview-file">
                                  <FileCopy color="primary" />
                                  <div>
                                    <p>{ file.name }</p>
                                    <p>{ file.type }</p>
                                  </div>
                                  <Button variant="outline-dark" className="close-button" onClick={() => removeAttachment(index)}><CloseRounded /></Button>
                                </div>
                              );
                            })}
                          </div>
                          <div className="input-group align-items-end">
                            <div className="input-group-append">
                              <div className="image-upload">
                                <label
                                  htmlFor="file-input"
                                  style={{cursor: 'pointer', marginLeft: '10px'}}
                                  className={isExxonMobileEmail(context) ? 'text-secondary my-1' : 'text-dark my-1'}
                                  data-tip="This feature is disabled for ExxonMobil. Please contact the Administrator."
                                  data-for="exxon"
                                  onClick={() => {
                                    if (isExxonMobileEmail(context)) {
                                      showToastMessage({ type: 'warn', title: 'Warning', description: 'This Feature is disabled for ExxonMobil. Please contact the Administrator.' });
                                    }
                                  }}
                                >
                                  <AttachFileIcon />
                                </label>
                                  {/* <img style={{marginLeft: '10px'}} src={ViewTools} alt="tools-logo" />
                                  <SearchIcon style={{marginLeft: '10px'}} /> */}
                                { isExxonMobileEmail(context) && <StyledTooltip id="exxon" place="top" effect="solid" /> }
                                <input id="file-input" type="file" multiple onChange={uploadAttachment} style={{display: 'none'}} disabled={isExxonMobileEmail(context)} />
                              </div>
                            </div>
                            <textarea
                              ref={textareaRef}
                              name="message"
                              value={searchWord}
                              className="form-control"
                              style={{cursor: totalDraftLoaded < 3 ? 'no-drop' : 'text', backgroundColor: '#F4F4F4'}}
                              disabled={totalDraftLoaded < 3}
                              placeholder="Message EnviroChat"
                              onChange={handleSearchInput}
                              onKeyDown={(e) => searchInBot(e, false)}
                              autoFocus
                            />
                            <div className="input-group-append">
                                <button
                                  className="btn btn-sm btn-secondary arrow-btn"
                                  disabled={totalDraftLoaded < 3}
                                  onClick={() => searchInBot(searchWord, true)}
                                >
                                  Send
                                  {/* <ArrowUpwardOutlinedIcon fontSize="small"/> */}
                                </button>
                                {/* <div className="voice-icon">
                                  <img src={VoiceChat} alt="voice-logo" />
                                </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <div className="searchbox">
                        <textarea
                            ref={textareaRef}
                            name="message"
                            value={searchWord}
                            className="form-control"
                            style={{cursor: totalDraftLoaded < 3 ? 'no-drop' : 'text', backgroundColor: '#F4F4F4'}}
                            disabled={totalDraftLoaded < 3}
                            placeholder="Message EnviroChat"
                            onChange={handleSearchInput}
                            onKeyDown={(e) => searchInBot(e, false)}
                            autoFocus
                          />
                        <div className={ filePreviews && filePreviews.length ? 'd-flex mb-1 overflow-auto' : 'd-none' }>
                          {filePreviews.map((file: any, index: number) => {
                            return imageTypes.includes(file.type) ? (
                              <div className="preview-img mr-1">
                                <img src={file.preview} alt={file.name} width="200" />
                                <Button variant="outline-dark" className="close-button" onClick={() => removeAttachment(index)}><CloseRounded /></Button>
                              </div>
                            ) : (
                              <div className="preview-file">
                                <FileCopy color="primary" />
                                <div>
                                  <p>{ file.name }</p>
                                  <p>{ file.type }</p>
                                </div>
                                <Button variant="outline-dark" className="close-button" onClick={() => removeAttachment(index)}><CloseRounded /></Button>
                              </div>
                            );
                          })}
                        </div>
                        <div className="input-group align-items-end justify-content-between">
                          <div className="input-group-append">
                            <div className="image-upload">
                              <label
                                htmlFor="file-input"
                                style={{cursor: 'pointer', marginLeft: '10px'}}
                                className={isExxonMobileEmail(context) ? 'text-secondary my-1' : 'text-dark my-1'}
                                data-tip="This feature is disabled for ExxonMobil. Please contact the Administrator."
                                data-for="exxon"
                                onClick={() => {
                                  if (isExxonMobileEmail(context)) {
                                    showToastMessage({ type: 'warn', title: 'Warning', description: 'This Feature is disabled for ExxonMobil. Please contact the Administrator.' });
                                  }
                                }}
                              >
                                <AttachFileIcon />
                              </label>
                                {/* <img style={{marginLeft: '10px'}} src={ViewTools} alt="tools-logo" />
                                <SearchIcon style={{marginLeft: '10px'}} /> */}
                              { isExxonMobileEmail(context) && <StyledTooltip id="exxon" place="top" effect="solid" /> }
                              <input id="file-input" type="file" multiple onChange={uploadAttachment} style={{display: 'none'}} disabled={isExxonMobileEmail(context)} />
                            </div>
                          </div>
                          <div className="input-group-append">
                              <button
                                className="btn btn-sm btn-secondary arrow-btn"
                                disabled={totalDraftLoaded < 3}
                                onClick={() => searchInBot(searchWord, true)}
                              >
                                Send
                                {/* <ArrowUpwardOutlinedIcon fontSize="small"/> */}
                              </button>
                              {/* <div className="voice-icon">
                                <img src={VoiceChat} alt="voice-logo" />
                              </div> */}
                          </div>
                        </div>
                      </div>
                    }
                    <p className="mb-0 mt-2 text-center">Environmentally Trained Generative AI</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthContext(EnviroAI);
