import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import LoadingButton from '../components/LoadingButton';
import NotificationContainer from '../components/notifications';
import EntitySubscriptionsList from '../components/notifications/EntitySubscriptionList';
import PaperContainer from '../components/PaperContainer';
import { IAuthContext } from '../context/auth/auth-context';
import withAuthContext from '../context/auth/AuthConsumer';
import NOTIFICATION_TYPE from '../types/notification-type';
import { CeresLogger } from '../logger';
import EmailNotification from '../components/notifications/EmailNotification';
import { projectId } from '../services/firebase';
import Filter from '../components/notifications/Filter';

const NotificationPageContainer = styled(PaperContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const SubscribeRNFormContainer = styled.div`
  margin: 4em 0;
  padding: 0 4em 4em;
  overflow: auto;
  height: 100vh;
  form {
    margin-top: 20px;
  }
`;
const TrackingList = styled.div`
  margin-top: 20px;
`;
const SubscriptionListLink = styled.a`
  margin-top: 10px;
  display: inline-block;
`;
const SubscriptionTypeGroup = styled(Form.Group)`
  display: flex;
  .input-group-text {
    padding: 0.375rem 0;
    border: none;
    background: none;
    display: inline;
    margin-right: 5px;
  }
`;
const SubscriptionTypeRadioItem = styled.div`
  margin-left: 10px;
`;
const SubscriptionTypeText = styled.span``;
const TrackbotRobo = styled.div`
  border: 8px solid #999;
  border-radius: 30px;
  padding: 10px 20px;
  width: 180px;
  height: 192px;
  margin: auto;
  & h4 {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
  }
  & img {
    width: 100%;
  }
`;
const Trackbot = styled.div`
  h3 {
    color: #005fbc;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
  }
`;
const CustRow = styled(Row)`
  align-items: center;
`;
const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
const FilterArea = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > span {
    font-weight: 600;
  }
`;

const NotificationPage: React.FC<{ context: IAuthContext }> = (props) => {
  const context = props.context;
  const userEmail = context.getUserEmail();
  const [isFormValidated, setFormValidation] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSubscriptionList, setShowSubscriptionList] = useState(false);
  const [subscripitonType, setSubscripitonType] = useState<NOTIFICATION_TYPE>(NOTIFICATION_TYPE.ALL);
  const [isLoadData, setLoadData] = useState(true);
  const [orderKeyword, setOrderKeyword] = useState<any>('asc');
  const [orderDate, setOrderDate] = useState<any>('desc');
  const [activeOrder, setActiveOrder] = useState<any>('date');
  const [rows, setRows] = useState<any>([]);
  const [isLoad, setLoad] = useState(true);
  const [showFrom, setShowFrom] = useState(0);
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [filterBy, setFilterBy] = useState<any>();
  const showItems = 20;

  const getSubscriptionList = async () => {
    const config = {
      params: {
        email: userEmail,
        action: 'subscription_list',
        records_num_from: 0,
        records_num_show: 500,
        subscription_status: 'All',
      },
    };
    try {
      setIsLoading(true);
      await axios
        .get('https://us-central1-' + projectId + '.cloudfunctions.net/AgencyTracker', config)
        .then((response) => {
          setSubscriptionsList(response.data.data);
        })
        .catch((error) => {
          CeresLogger.error(error);
          toast.error(`Failed to load subscription.`);
        })
        .finally(() => setIsLoading(false));
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  const getSubjectTrack = async (fromPage = 0) => {
    setLoad(false);
    const config = {
      params: {
        email: userEmail,
        action: 'notification_list',
        records_num_from: fromPage,
        records_num_show: showItems,
        subscription_keyword: filterBy ? filterBy.value.split('|||')[0] : '',
        document_source: filterBy ? filterBy.value.split('|||')[1] : '',
        // eslint-disable-next-line no-useless-concat
        sorting: activeOrder === 'keyword' ? 'keyword-' + orderKeyword : 'document_date-' + orderDate,
      },
    };
    try {
      await axios
        .get('https://us-central1-' + projectId + '.cloudfunctions.net/AgencyTracker', config)
        .then((response) => {
          setRows(response.data);
        })
        .catch((error) => {
          CeresLogger.error(error);
        });
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  useEffect(() => {
    getSubjectTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy]);

  const saveSubjectTrack = async () => {
    const config = {
      params: {
        email: userEmail,
        action: 'subscription_add',
        keyword: subscriptionData,
        document_source: subscripitonType,
      },
    };
    try {
      setFormValidation(true);
      setIsLoading(true);
      await axios
        .get('https://us-central1-' + projectId + '.cloudfunctions.net/AgencyTracker', config)
        .then((response) => {
          if (response.data.status === 'success add new keyword subscription') {
            toast.success(`You've successfully added keyword subscription`);
          }
          setSubscriptionData('');
          setSubscripitonType(NOTIFICATION_TYPE.ALL);
          setFormValidation(false);
          setShowFrom(0);
          getSubjectTrack();
          getSubscriptionList();
        })
        .catch((error) => {
          CeresLogger.error(error);
          toast.error(`Failed to register subscription.`);
        })
        .finally(() => setIsLoading(false));
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  return (
    <NotificationPageContainer className="track-area">
      <SubscribeRNFormContainer className="sub-track">
        <CustRow>
          <Col md={4}>
            <h3>Track Agency Filings</h3>
            <Form
              noValidate
              validated={isFormValidated}
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                const form = e.currentTarget as any;
                if (form && !form.checkValidity()) {
                  e.stopPropagation();
                  setFormValidation(true);
                  return;
                }
                saveSubjectTrack();
              }}
            >
              <Form.Group controlId="validationCustom02">
                <Form.Control
                  required
                  value={subscriptionData}
                  onChange={(e: any) => setSubscriptionData(e.target.value)}
                  type="text"
                  placeholder="Words/Subjects to Track"
                  aria-label="Words/Subjects to Track"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter required information for subscription.
                </Form.Control.Feedback>
              </Form.Group>
              <SubscriptionTypeGroup className="form-group" controlId="validationCustom03">
                <SubscriptionTypeRadioItem>
                  <InputGroup.Radio
                    value={NOTIFICATION_TYPE.ALL}
                    checked={subscripitonType === NOTIFICATION_TYPE.ALL}
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        setSubscripitonType(NOTIFICATION_TYPE.ALL);
                      }
                    }}
                  />
                  <SubscriptionTypeText>ALL</SubscriptionTypeText>
                </SubscriptionTypeRadioItem>
                <SubscriptionTypeRadioItem>
                  <InputGroup.Radio
                    value={NOTIFICATION_TYPE.LDEQ}
                    checked={subscripitonType === NOTIFICATION_TYPE.LDEQ}
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        setSubscripitonType(NOTIFICATION_TYPE.LDEQ);
                      }
                    }}
                  />
                  <SubscriptionTypeText>LDEQ</SubscriptionTypeText>
                </SubscriptionTypeRadioItem>
                <SubscriptionTypeRadioItem>
                  <InputGroup.Radio
                    value={NOTIFICATION_TYPE.TCEQ}
                    checked={subscripitonType === NOTIFICATION_TYPE.TCEQ}
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        setSubscripitonType(NOTIFICATION_TYPE.TCEQ);
                      }
                    }}
                  />
                  <SubscriptionTypeText>TCEQ</SubscriptionTypeText>
                </SubscriptionTypeRadioItem>
              </SubscriptionTypeGroup>
              <LoadingButton isLoading={isLoading}>Subscribe</LoadingButton>
            </Form>

            <SubscriptionListLink
              href="#"
              onClick={() => {
                setShowSubscriptionList(true);
                setLoadData(true);
              }}
            >
              Manage Subscriptions
            </SubscriptionListLink>
          </Col>

          <Col md={4}>
            <Trackbot>
              <h3>Launch!</h3>
              <TrackbotRobo>
                <img src="/ceres-robo.png" alt="Trackbot" />
                <h4>TrackBot</h4>
              </TrackbotRobo>
            </Trackbot>
          </Col>

          <Col md={4}>
            <EmailNotification userEmail={userEmail} />
          </Col>
        </CustRow>
        <TrackingList>
          <ContentHeader>
            <h3>Tracking List</h3>
            <FilterArea>
              <span>Filter</span>
              <Filter subscriptionsList={subscriptionsList} filterBy={filterBy} setFilterBy={setFilterBy} />
            </FilterArea>
          </ContentHeader>
          <NotificationContainer
            getSubjectTrack={getSubjectTrack}
            showItems={showItems}
            isLoad={isLoad}
            orderDate={orderDate}
            setLoad={setLoad}
            setOrderDate={setOrderDate}
            setOrderKeyword={setOrderKeyword}
            activeOrder={activeOrder}
            setActiveOrder={setActiveOrder}
            orderKeyword={orderKeyword}
            rows={rows}
            showFrom={showFrom}
            setShowFrom={setShowFrom}
            getSubscriptionList={getSubscriptionList}
          />
        </TrackingList>
      </SubscribeRNFormContainer>
      <EntitySubscriptionsList
        onHide={() => setShowSubscriptionList(false)}
        show={showSubscriptionList}
        isLoadData={isLoadData}
        setLoadData={setLoadData}
        getSubjectTrack={getSubjectTrack}
        setShowFrom={setShowFrom}
        subscriptionsList={subscriptionsList}
        setSubscriptionsList={setSubscriptionsList}
      />
    </NotificationPageContainer>
  );
};

export default withAuthContext(NotificationPage);
