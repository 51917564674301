import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { projectId } from '../../../services/firebase';
import { CeresLogger } from '../../../logger';
import {
  MuiThemeProvider,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { IJob, IMap } from '../../../store/aiearth/types';
import { updateMap, updateJob } from '../../../store/aiearth/action';

interface IAiEarthMapProps {
  gMap: any;
  gMaps: any;
  setSubjectPropertyEsaData?: any;
  setSelectOpen: any;
  selectJobOpen: any;
  setSelectJobOpen: any;
}

const useStyles = makeStyles({
  root: {
    height: 220,
    paddingTop: '20px',
  },
});
const MatSlider = styled.div`
  padding: 10px 4px;
  background-color: #eee;
  text-align: center;
  font-size: 0.9rem;
  width: 45px;
`;

const MapControlsContainer = styled.div`
  #jobLayersBtn {
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      color: black !important;
      border-color: rgba(0, 0, 0, 0.23); !important;
      border-width: 1px !important;
    }
  }
`;
const CustomJobFormControl = styled(FormControl)`
  width: 8.3rem;
    border-radius: 4px;
    padding: 2px;
    z-index: 9999;
    margin: 0 4px 0 14px !important;
  & :disabled{
    color: #a9a9a9;
  }
  & label {
    font-size: 1.2rem;
    right: 2.5rem;
    color: black !important;
  }
  & li span:{
        padding:'1px',
        font-size:'0.7rem'
      }
`;
const Popup = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  position: absolute;
  top: 54px;
  z-index: 9;
  right: 55px;
  border: 1px solid #005fbc;
`;
const PopupHead = styled.div`
  text-transform: uppercase;
  padding: 5px 15px;
  font-weight: 500;
  background-color: #005fbc;
`;
const PopupBody = styled.div`
  padding-left: 12px;
  display: flex;
  & span {
    margin-bottom: 0 !important;
    font-size: 15px;
  }
`;

const theme1 = createTheme({
  overrides: {
    MuiMenuItem: {
      root: {
        '& span': {
          fontSize: '0.7rem',
          padding: '1px',
        },
      },
    },
  },
});

const JobMaps: React.FC<IAiEarthMapProps> = (props) => {
  const { gMap, gMaps, setSelectOpen, selectJobOpen, setSelectJobOpen } = props;
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const mapState: IMap = store.map;
  const jobState: IJob = store.job;

  const [getCenter, setCenter] = useState<any>();
  const [isTopo, setTopo] = useState(false);
  const [getSliderNumber, setSliderNumber] = useState<any>(100);
  const [getOpacity, setOpacity] = useState<any>('1');
  const [historicalTitle, setHistoricalTitle] = useState<string>('');
  const [historicalSelect, setHistoricalSelect] = useState<number>(0);
  const [historicalArea, setHistoricalArea] = useState<any>();

  const fetchTopoImages = async (jobId: string) => {
    const body = { jobId };
    setHistoricalTitle('Topo Maps');
    setHistoricalSelect(0);
    jobState.topoImages = [];
    dispatch(updateJob(jobState));
    try {
      await axios
        .post(
          'https://us-central1-' +
            projectId +
            '.cloudfunctions.net/api/getTopoImages',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/getTopoImages',
          body,
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response) => {
          const hTopoImages = response.data[0];
          if (hTopoImages) {
            dispatch(
              updateJob({
                ...jobState,
                ...{
                  topoImages: hTopoImages.job_topo_images,
                  topoModal: true,
                },
              })
            );
            const latlng = new gMaps.LatLng(
              hTopoImages.job_topo_images.centroid_lat,
              hTopoImages.job_topo_images.centroid_lon
            );
            setCenter(latlng);
            if (
              typeof hTopoImages.job_topo_images.job_topos_json !==
                'undefined' &&
              hTopoImages.job_topo_images.job_topos_json[0]
            ) {
              const toposArea = {
                ne_lat: hTopoImages.job_topo_images.ne_lat,
                ne_lng: hTopoImages.job_topo_images.ne_lng,
                sw_lat: hTopoImages.job_topo_images.sw_lat,
                sw_lng: hTopoImages.job_topo_images.sw_lng,
              };
              setHistoricalArea(toposArea);
              historicalImageItem(
                hTopoImages.job_topo_images.job_topos_json[0],
                toposArea
              );
            }
          }
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally(() => {
          gMap.setMapTypeId('roadmap');
          if (Object.keys(jobState.esaData).length > 0) {
            props.setSubjectPropertyEsaData(jobState.esaData, 'topos');
          }
        });
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  const fetchAerialImages = async (jobId: string) => {
    const body = { jobId };
    setHistoricalTitle('Aerial Maps');
    setHistoricalSelect(0);
    jobState.topoImages = [];
    dispatch(updateJob(jobState));
    try {
      await axios
        .post(
          'https://us-central1-' +
            projectId +
            '.cloudfunctions.net/api/getAerialImages',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/getAerialImages',
          body,
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response) => {
          const hAerialImages = response.data[0];
          if (hAerialImages) {
            dispatch(
              updateJob({
                ...jobState,
                ...{
                  topoImages: hAerialImages.job_aerial_images,
                  topoModal: true,
                },
              })
            );
            const latlng = new gMaps.LatLng(
              hAerialImages.job_aerial_images.centroid_lat,
              hAerialImages.job_aerial_images.centroid_lon
            );
            setCenter(latlng);
            if (
              typeof hAerialImages.job_aerial_images.job_topos_json !==
                'undefined' &&
              hAerialImages.job_aerial_images.job_topos_json[0]
            ) {
              const toposArea = {
                ne_lat: hAerialImages.job_aerial_images.ne_lat,
                ne_lng: hAerialImages.job_aerial_images.ne_lng,
                sw_lat: hAerialImages.job_aerial_images.sw_lat,
                sw_lng: hAerialImages.job_aerial_images.sw_lng,
              };
              setHistoricalArea(toposArea);
              historicalImageItem(
                hAerialImages.job_aerial_images.job_topos_json[0],
                toposArea
              );
            }
          }
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally(() => {
          gMap.setMapTypeId('roadmap');
          if (Object.keys(jobState.esaData).length > 0) {
            props.setSubjectPropertyEsaData(jobState.esaData, 'aerial');
          }
        });
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  const fetchSanbornImages = async (jobId: string) => {
    const body = { jobId };
    setHistoricalTitle('Sanborn Maps');
    setHistoricalSelect(0);
    jobState.topoImages = [];
    dispatch(updateJob(jobState));
    try {
      await axios
        .post(
          'https://us-central1-' +
            projectId +
            '.cloudfunctions.net/api/getSanbornImages',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/getSanbornImages',
          body,
          {
            headers: {
              Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
            },
          }
        )
        .then((response) => {
          const hAerialImages = response.data[0];
          if (hAerialImages) {
            dispatch(
              updateJob({
                ...jobState,
                ...{
                  topoImages: hAerialImages.job_aerial_images,
                  topoModal: true,
                },
              })
            );
            const latlng = new gMaps.LatLng(
              hAerialImages.job_aerial_images.centroid_lat,
              hAerialImages.job_aerial_images.centroid_lon
            );
            setCenter(latlng);
            if (
              typeof hAerialImages.job_aerial_images.job_topos_json !==
                'undefined' &&
              hAerialImages.job_aerial_images.job_topos_json[0]
            ) {
              const toposArea = {
                ne_lat: hAerialImages.job_aerial_images.ne_lat,
                ne_lng: hAerialImages.job_aerial_images.ne_lng,
                sw_lat: hAerialImages.job_aerial_images.sw_lat,
                sw_lng: hAerialImages.job_aerial_images.sw_lng,
              };
              setHistoricalArea(toposArea);
              historicalImageItem(
                hAerialImages.job_aerial_images.job_topos_json[0],
                toposArea
              );
            }
          }
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally(() => {
          gMap.setMapTypeId('roadmap');
          if (Object.keys(jobState.esaData).length > 0) {
            props.setSubjectPropertyEsaData(jobState.esaData, 'aerial');
          }
        });
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  const setJobLayers = async (layersArray: any) => {
    removeTopos();
    gMap.setMapTypeId('roadmap');
    if (mapState.marker) {
      mapState.marker.setMap(null);
    }
    if (mapState.subjectPropertyShowLayer) {
      mapState.subjectPropertyShowLayer.forEach((feature: any) => {
        mapState.subjectPropertyShowLayer.remove(feature);
      });
    }
    if (mapState.oilGasJobMaps) {
      mapState.oilGasJobMaps.forEach((feature: any) => {
        mapState.oilGasJobMaps.remove(feature);
      });
    }
    if (mapState.adjoiningPropertiesBoundaries) {
      mapState.adjoiningPropertiesBoundaries.forEach((feature: any) => {
        mapState.adjoiningPropertiesBoundaries.remove(feature);
      });
    }
    if (mapState.pipeLinesJobMaps) {
      mapState.pipeLinesJobMaps.forEach((feature: any) => {
        mapState.pipeLinesJobMaps.remove(feature);
      });
    }
    if (mapState.adjoiningPropertyLayer) {
      mapState.adjoiningPropertyLayer.forEach((feature: any) => {
        mapState.adjoiningPropertyLayer.remove(feature);
      });
    }
    if (mapState.waterWellsPointer) {
      mapState.waterWellsPointer.forEach((feature: any) => {
        mapState.waterWellsPointer.remove(feature);
      });
    }
    if (mapState.monitorWellsPointer) {
      mapState.monitorWellsPointer.forEach((feature: any) => {
        mapState.monitorWellsPointer.remove(feature);
      });
    }
    if (mapState.soilsPropertyLayer) {
      mapState.soilsPropertyLayer.forEach((feature: any) => {
        mapState.soilsPropertyLayer.remove(feature);
      });
    }
    jobState.topoImages = [];
    jobState.topoModal = false;
    jobState.jobFeatureTab = false;

    dispatch(updateJob(jobState));

    if (layersArray.includes('Phase1-ASTM')) {
      props.setSubjectPropertyEsaData(jobState.esaData, 'astm');
    }
    if (layersArray.includes('Phase1-VEC')) {
      props.setSubjectPropertyEsaData(jobState.esaData, 'vec');
    }
    if (layersArray.includes('Historical Topos')) {
      fetchTopoImages(jobState.activeJob.jobId);
    }
    if (layersArray.includes('Historical Aerials')) {
      fetchAerialImages(jobState.activeJob.jobId);
    }
    if (layersArray.includes('Historical Sanborns')) {
      fetchSanbornImages('9b710f1b-1bf1-4af8-8fc7-82503401df70');
    }
    if (layersArray.includes('Oil/Gas')) {
      props.setSubjectPropertyEsaData(jobState.esaData, 'oilgas');
      gMap.setMapTypeId('roadmap');
    }
    if (layersArray.includes('Adjoining Properties')) {
      props.setSubjectPropertyEsaData(jobState.esaData, 'adjoin');
    }
    if (layersArray.includes('Water Wells')) {
      props.setSubjectPropertyEsaData(jobState.esaData, 'water');
    }
    if (layersArray.includes('Monitor Wells')) {
      props.setSubjectPropertyEsaData(jobState.esaData, 'monitor');
    }
    if (layersArray.includes('Soils')) {
      props.setSubjectPropertyEsaData(jobState.esaData, 'soils');
    }
  };

  const historicalTopos = (historicalItems: string, toposArea: any) => {
    const bounds = new gMaps.LatLngBounds(
      new gMaps.LatLng(toposArea.sw_lat, toposArea.sw_lng),
      new gMaps.LatLng(toposArea.ne_lat, toposArea.ne_lng)
    );
    const srcImage = 'data:image/jpeg;base64,' + historicalItems;
    class USGSOverlay extends gMaps.OverlayView {
      private boundsA: google.maps.LatLngBounds;
      private imageA: string;
      private divA: HTMLElement | null;

      constructor(bound: google.maps.LatLngBounds, image: string) {
        super();
        this.boundsA = bound;
        this.imageA = image;
        this.divA = null;
      }

      public onAdd() {
        this.divA = document.createElement('div');
        this.divA.style.borderStyle = 'none';
        this.divA.style.borderWidth = '0px';
        this.divA.style.position = 'absolute';

        const img = document.createElement('img');
        img.src = this.imageA;
        img.className = 'image-opacity';
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        img.style.opacity = getOpacity;
        this.divA.appendChild(img);

        const panes = this.getPanes()!;
        panes.overlayLayer.appendChild(this.divA);
      }

      public draw() {
        const overlayProjection = this.getProjection();
        const sw = overlayProjection.fromLatLngToDivPixel(
          this.boundsA.getSouthWest()
        )!;
        const ne = overlayProjection.fromLatLngToDivPixel(
          this.boundsA.getNorthEast()
        )!;

        if (this.divA) {
          this.divA.style.left = sw.x + 'px';
          this.divA.style.top = ne.y + 'px';
          this.divA.style.width = ne.x - sw.x + 'px';
          this.divA.style.height = sw.y - ne.y + 'px';
        }
      }
      public onRemove() {
        if (this.divA) {
          (this.divA.parentNode as HTMLElement).removeChild(this.divA);
          this.divA = null;
        }
      }
    }
    const overlay = new USGSOverlay(bounds, srcImage);
    mapState.mapOverLay = overlay;
    dispatch(updateMap(mapState));
    setTopo(true);
  };

  useEffect(() => {
    if (mapState.mapOverLay && isTopo) {
      setTopo(false);
      gMap.panTo(getCenter);
      if (historicalTitle === 'Aerial Maps') {
        gMap.setZoom(17);
      } else if (historicalTitle === 'Topo Maps') {
        gMap.setZoom(15);
      } else if (historicalTitle === 'Sanborn Maps') {
        gMap.setZoom(18);
      }
      mapState.mapOverLay.setMap(gMap);
    }
  }, [mapState.mapOverLay, getCenter, isTopo, setTopo, gMap, historicalTitle]);

  useEffect(() => {
    const getImage: any = document.getElementsByClassName('image-opacity')[0];
    if (getImage) {
      getImage.style.opacity = getOpacity;
    }
  }, [getOpacity]);

  const removeTopos = () => {
    if (mapState.mapOverLay) {
      mapState.mapOverLay.setMap(null);
      mapState.mapOverLay = null;
      dispatch(updateMap(mapState));
    }
  };

  const historicalImageItem = (items: any, area: any = historicalArea) => {
    removeTopos();
    historicalTopos(items.topobase64, area);
    let type = '';
    if (historicalTitle === 'Aerial Maps') {
      type = 'aerial';
    } else if (historicalTitle === 'Topo Maps') {
      type = 'topos';
    }
    props.setSubjectPropertyEsaData(jobState.esaData, type);
  };

  const handleJobLayersData = async (event: any) => {
    const layerArray = event.target.value;
    mapState.jobLayers = mapState.jobLayers.length ? mapState.jobLayers.includes(layerArray) ? [] : [layerArray] : [layerArray];
    dispatch(updateMap(mapState));
    setJobLayers(layerArray);
  };
  const selectJobClose = () => {
    setSelectJobOpen(false);
  };
  const classes = useStyles();

  return (
    <MapControlsContainer>
      <MuiThemeProvider theme={theme1}>
        <CustomJobFormControl size="small" variant="outlined" id="jobLayersBtn">
          <InputLabel shrink={false}>Job Maps</InputLabel>
          <Select
            id="myJobCheck"
            classes={{ select: 'layersBtn' }}
            value={mapState.jobLayers}
            onMouseEnter={() => {
              setSelectOpen(false);
              setSelectJobOpen(true);
            }}
            onChange={handleJobLayersData}
            onMouseMove={(e: any) => {
              const popup: any = document.querySelectorAll(
                '.layersPopup > .MuiPaper-elevation8'
              )[0];
              if (popup) {
                popup.onmouseleave = selectJobClose;
                const popupLabels: any =
                  document.getElementById('jobLayersBtn');
                if (
                  popup.contains(e.target) === true ||
                  popupLabels.contains(e.target) === true
                ) {
                  setSelectOpen(false);
                  setSelectJobOpen(true);
                } else {
                  setSelectJobOpen(false);
                }
              }
            }}
            onClick={(e: any) => {
              if(!e.target.value) {
                setSelectJobOpen(!selectJobOpen);
              }
            }}
            open={selectJobOpen}
            renderValue={(selected) => ''}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              className: 'layersPopup',
            }}
          >
            {[
              'Phase1-ASTM',
              'Phase1-VEC',
              'Historical Topos',
              'Historical Aerials',
              'Historical Sanborns',
              'Oil/Gas',
              'Adjoining Properties',
              'Water Wells',
              'Monitor Wells',
              'Soils',
            ].map((jobs, index) => (
              <MenuItem key={index} value={jobs}>
                <Checkbox checked={mapState.jobLayers.indexOf(jobs) > -1} />
                <ListItemText primary={jobs} />
              </MenuItem>
            ))}
          </Select>
        </CustomJobFormControl>
      </MuiThemeProvider>
      {jobState.topoModal === true && (
        <Popup>
          <PopupHead>{historicalTitle}</PopupHead>
          <PopupBody>
            {jobState.topoImages && jobState.topoImages.job_topos_json && (typeof jobState.topoImages.job_topos_json !== 'undefined' &&
            jobState.topoImages.job_topos_json !== null &&
            Object.keys(jobState.topoImages.job_topos_json).length > 0) ? (
              <RadioGroup aria-label="gender" name="gender1">
                {jobState.topoImages.job_topos_json.map(
                  (topos: any, index: any) => (
                    <FormControlLabel
                      key={topos.year_group}
                      value={topos.year_group}
                      control={<Radio />}
                      label={topos.year_group}
                      onClick={() => {
                        historicalImageItem(topos);
                        setHistoricalSelect(index);
                      }}
                      checked={index === historicalSelect}
                    />
                  )
                )}
              </RadioGroup>
            ) : (
              <small>Data Not Found!</small>
            )}
            <MatSlider>
              <span>{getSliderNumber}%</span>
              <div className={classes.root}>
                <Slider
                  orientation="vertical"
                  defaultValue={100}
                  aria-labelledby="vertical-slider"
                  onChange={(e, val: any) => {
                    setSliderNumber(val);
                    const percent = val / 100;
                    setOpacity(percent);
                  }}
                />
              </div>
            </MatSlider>
          </PopupBody>
        </Popup>
      )}
    </MapControlsContainer>
  );
};

export default JobMaps;
