import React, { useEffect, useState } from 'react';
import LOADER_MESSAGES from '../../constants/loader-messages';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-message {
    font-family: 'Aldo'; /* Use a modern, clean font */
    font-size: 1.2rem; /* Slightly larger font size */
    font-weight: bold; /* Bold text for emphasis */
    color: #678ca2; /* Subtle gray color */
    font-style: italic;
  }
  .quote-with-author {
    color: #678ca2;
  }
  .quote-without-author {
    font-family: 'Helvetica', sans-serif
    font-size: 1.2rem; /* Slightly smaller font size */
    font-style: normal; /* Remove italicization */
    color: #7c8f99; /* Use a warmer brown tone for differentiation */
  }
`;

interface ILoaderMessage {
  quote: string;
  author?: string;
}

const MessageLoader = () => {
  const [currentMessage, setCurrentMessage] = useState<ILoaderMessage>(
    () => LOADER_MESSAGES[Math.floor(Math.random() * LOADER_MESSAGES.length)]
  );

  useEffect(() => {
    let lastMessageWithAuthor = false;

    const interval = setInterval(() => {
      const filteredMessages = LOADER_MESSAGES.filter((msg) =>
        lastMessageWithAuthor ? !msg.author : !!msg.author
      );

      const randomIndex = Math.floor(Math.random() * filteredMessages.length);
      const nextMessage = filteredMessages[randomIndex];

      setCurrentMessage(nextMessage);
      lastMessageWithAuthor = !lastMessageWithAuthor;
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <LoaderContainer>
      <p
        className={`mt-2 mb-0 loader-message ${
          currentMessage.author ? 'quote-with-author' : 'quote-without-author'
        }`}
      >
        {currentMessage.author ? (
    `"${currentMessage.quote}" - ${currentMessage.author}`
  ) : (
    currentMessage.quote
  )}
      </p>
      <div className="typing">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </LoaderContainer>
  );
};

export default MessageLoader;
