const LOADER_MESSAGES = [
  { quote: 'Powering up the ultimate environmental superbrain... Deploying an agentic workflow to analyze millions of documents and the entire internet for the most comprehensive answer!' },
  { quote: 'Unleashing the environmental superbrain... Utilizing a network of agents to sift through millions of documents and internet sources, crafting the ideal response! ' },
  { quote: 'Launching the environmental superbrain... A team of specialized agents is scanning millions of documents and exploring the internet to construct the most precise answer! ' },
  { quote: 'Engaging the environmental superbrain’s full power... Activating different agents to access millions of environmental documents and navigate the internet for a comprehensive response! ' },
  { quote: 'Unleashing the environmental superbrain... Millions of documents and internet sources are being synthesized to provide the perfect response! ' },
  { quote: 'Launching the superbrain... It’s scanning millions of documents and exploring the internet to construct the most precise answer!' },
  { quote: 'Engaging the environmental superbrain’s full power ... Millions of documents and the internet’s vast knowledge are being synthesized to craft a comprehensive response!'},
  { quote: 'Engaging EnviroAI: The planet’s first environmental brain is crunching numbers, facts, and the internet itself to bring you answers! ' },
  { quote: 'The AI is diving into an ocean of environmental data... Accessing millions of sources and crunching insights like a cosmic eco-processor!' },
  { quote: 'Standby: The AI is processing millions of documents, data streams, and internet sources. Prepare for an answer forged from the depths of environmental intelligence!' },
  { quote: 'Our agentic system is scanning environmental documents like a data tornado... Millions of insights are being analyzed for your request.' },
  { quote: 'Accessing the world’s most powerful environmental brain... Stand by as it sifts through millions of documents and the internet to produce your response. ' },
  { quote: 'Engaging the world’s first agentic environmental brain... Right now, it’s digesting millions of data points to prepare your response!' },
  { quote: 'Executing in-depth computational analysis... Gathering precise environmental insights.' },
  { quote: 'Conducting complex computations with the AI environmental brain... Your data will be ready shortly.' },
  { quote: 'The Enviro-AI Brain is multitasking like a squirrel in a forest. Give us a moment to find the perfect acorn of knowledge!' },
  { quote: 'Agentic computations in progress... Our AI is currently juggling ginormously-sized environmental facts. Impressive, right?' },
  { quote: 'Our AI agent is doing some mental gymnastics. Please wait while it somersaults through terabytes of environmental intelligence' },
  { quote: 'Deploying the EnviroAI agents... They’ll be back with the answers before you can say ’carbon sequestration’! ' },
  { quote: 'Launching environmental agents... Scanning the web and millions of environmental documents faster than a coffee-fueled squirrel!' },
  { quote: 'Deploying agents... Scanning millions of files and the internet. This might take a sec—we’re basically building an environmental encyclopedia!' },
  { quote: 'Superbrain at work... It’s wrestling with millions of documents and the web. This kind of power takes a minute, trust us!' },
  { quote: 'Engaging the environmental brain... It’s like a tiny army of agents wading through oceans of data. Almost there!"' },
  { quote: 'Activating environmental agents... They’re currently elbow-deep in millions of environmental documents and the web, hunting for that environmental-gold!' },
  { quote: 'Environmental superbrain loading... Our agents are working faster than a caffeinated raccoon in a recycling bin!' },
  { quote: 'Launching environmental agents... They’re out there, wrangling data like cowboys on the digital prairie!' },
  { quote: 'Environmental agents on the case... Scouring the internet and millions of documents. It’s like searching for an eco-needle in a data haystack!' },
  { quote: 'Environmental superbrain thinking... This level of environmental genius doesn’t happen in a snap, but it’ll be worth it!' },
  { quote: 'Deploying environmental agents... Right now, they’re reading more documents than a grad student during finals week!' },
  { quote: 'Our environmental AI is currently in deep meditation with nature’s secrets... Please bear with us as it returns from the data forest.' },
  { quote: 'A ship is safe in harbor, but that’s not what ships are for.', author: 'Paulo Coelho' },
  { quote: 'Exploration is curiosity put into action.', author: 'Don Walsh' },
  { quote: 'People ask me, ‘What is the use of climbing Mount Everest?’ and my answer is ‘because it’s there.’', author: 'George Mallory' },
  { quote: 'I will come again and conquer you because as a mountain you can’t grow… but as a human, I can.', author: 'Edmund Hillary' },
  { quote: 'The future belongs to those who believe in the beauty of their dreams.', author: 'Eleanor Roosevelt' },
  { quote: 'Dreams are the touchstones of our character.', author: 'Henry David Thoreau' },
  { quote: 'The secret of genius is to carry the spirit of the child into old age which means never losing your enthusiasm.', author: 'Aldous Huxley' },
  { quote: 'Children are happy because they don’t have a file in their minds called ’All The Things That Could Go Wrong’', author: 'Marianne Williamson' },
  { quote: 'Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world.', author: 'Harriet Tubman' },
  { quote: 'You see things and you say ’Why?’ But I dream things that never were; and I say ’Why not?’', author: 'George Bernard Shaw' },
  { quote: 'The purpose of life is to live it, to taste it, to experience to the utmost, to reach out eagerly and without fear for newer and richer experience.', author: 'Eleanor Roosevelt' },
  { quote: 'We shall not cease from exploration, and the end of all our exploring will be to arrive where we started and know the place for the first time.', author: 'T. S. Eliot' },
  { quote: 'Only those who risk going too far can possibly find out how far one can go.', author: 'T.S. Eliot' },
  { quote: 'Until you cross the bridge of your insecurities, you can’t begin to explore your possibilities.', author: 'Tim Fargo' },
  { quote: 'Go back... Way back. To that little innocent girl walking around barefoot on the sidewalk in the summer sun. Worry less. Be like her.' },
  { quote: 'If we all could see the world through the eyes of a child, we would see the magic in everything.', author: 'Chee Vai Tang' },
  { quote: 'Do just once what others say you can’t do, and you will never pay attention to their limitations again.', author: 'James Cook' },
  { quote: 'Carpe Diem. Seize the day (boys). Make your life extraordinary.', author: 'John Keating, Dead Poets Society' },
  { quote: 'Be fearless in the pursuit of what sets your soul on fire.', author: 'Jennifer Lee' },
  { quote: 'It is not the strongest of the species that survives, nor the most intelligent, but the one most responsive to change.', author: 'Charles Darwin' },
  { quote: 'Exploration is a wonderful way to open our eyes to the world, and to truly see that impossible is just a word.', author: 'Richard Branson' },
  { quote: 'Life should not be a journey to the grave with the intention of arriving safely in a pretty and well-preserved body, but rather to skid in broadside in a cloud of smoke, thoroughly used up, totally worn out, and loudly proclaiming, ’Wow! What a Ride!’', author: 'Hunter S. Thompson' },
  { quote: 'My only fear is that I might awaken in my bed, destined to live a common life once again.', author: 'Marco Polo' },
  { quote: 'I haven’t been everywhere, but it’s on my list.', author: 'Susan Sontag' },
  { quote: 'So many of our dreams at first seem impossible, then they seem improbable, and then, when we summon the will, they soon become inevitable.', author: 'Christoper Reeve' },
  { quote: 'The bigger the dream... the bigger the potential reality.', author: 'Jed Anderson' },
  { quote: 'Trust in dreams, for in them is hidden the gate to eternity.', author: 'Khalil Gibran' },
  { quote: 'The important thing is to dare to dream big, then take action to make it come true.', author: 'Joe Girard' },
  { quote: 'Never give up on what you really want to do. The person with big dreams is more powerful than the one with all the facts.', author: 'H. Jackson Brown, Jr.' },
  { quote: 'You are never too old to set another goal or dream a new dream.', author: 'C.S. Lewis' },
  { quote: 'We were meant to explore this earth like children do, unhindered by fear, propelled by curiosity and a sense of discovery. Allow yourself to see the world through new eyes and know there are amazing adventures here for you.', author: 'Laurel Bleadon Maffei' },
  { quote: 'We only know a tiny proportion about the complexity of the natural world. Wherever you look, there are still things we don’t know about and don’t understand. There are always new things to find out if you go looking for them.',
    author: 'David Attenborough' },
  { quote: 'Learn to get excited like a child. There is nothing that has more magic than childish excitement.', author: 'Jed Anderson' },
  { quote: 'In our dreams lies our unfinished work for the world.', author: 'Joan Chittister' },
  { quote: 'When you cease to dream, you cease to live.', author: 'Malcolm Forbes' },
  { quote: 'Dream big dreams! Only big dreams have the power to move your mind and spirit.', author: 'Brian Tracy' },
  { quote: 'Dream big, talk big, and turn your faith loose!', author: 'Kenneth Copeland' },
  { quote: 'Give yourself permission to act like a child – to view the world with wonder – to let your mind be free.' },
  { quote: 'I eventually realized I had to grow-up and become a child.', author: 'Jed Anderson' },
  { quote: 'Reach high, for stars lie hidden in your soul. Dream deep, for every dream precedes the goal.', author: 'Pamela Vaull Starr' },
  { quote: 'Now is the time for us to dream new dreams, move out into uncharted waters and stay positive.', author: 'Jane Freeman' },
  { quote: 'Children see magic because they look for it.', author: 'Christopher Moore' },
  { quote: 'We don’t stop playing because we grow old; we grow old because we stop playing.', author: 'George Bernard Shaw' },
  { quote: 'You have to have a big vision and take very small steps to get there. You have to be humble as you execute but visionary and gigantic in terms of your aspirations.', author: 'Jason Calacanis' },
  { quote: 'This world is a dream within a dream; and as we grow older, each step is an awakening.', author: 'Walter Scott' },
  { quote: 'The most creative people have this childlike facility to play.', author: 'John Cleese' },
  { quote: 'It took me four years to paint like Raphael, but a lifetime to paint like a child.', author: 'Pablo Picasso' },
  { quote: 'Always dream big dreams. Big dreams attract big people.', author: 'Dave Liniger' },
  { quote: 'Dream big and your problems become small.', author: 'Vishen Lakhiani' },
  { quote: 'All big things in this world are done by people who are naïve and have an idea that is obviously impossible.', author: 'Frank Richards' },
  { quote: 'Whatever you do, or dream you can, begin it. Boldness has genius and power and magic in it.', author: 'Goethe' },
  { quote: 'Twenty years from now you will be more disappointed by the things that you didn’t do than by the ones you did do. So throw off the bowlines. Sail away from the safe harbor. Catch the trade winds in your sails. Explore. Dream. Discover.',
    author: 'Mark Twain' },
  { quote: 'We were born to be free, to expand our horizons by going where we have never gone before, and not to hang out in the relative comfort and safety of the nest, the known.', author: 'Dennis Merritt Jones' },
  { quote: 'The only thing that is impossible is impossibility.', author: 'Michio Kaku' },
  { quote: 'The cosmos is within us. We are made of star-stuff. We are a way for the universe to know itself.', author: 'Carl Sagan' },
  { quote: 'Imagination is more important than knowledge. For knowledge is limited, whereas imagination embraces the entire world, stimulating progress, giving birth to evolution.', author: 'Albert Einstein' },
  { quote: 'The only way to discover the limits of the possible is to go beyond them into the impossible.', author: 'Arthur C. Clarke' },
  { quote: 'Impossible is just a big word thrown around by small men who find it easier to live in the world they’ve been given than to explore the power they have to change it. Impossible is not a fact. It’s an opinion. Impossible is not a declaration. It’s a dare. Impossible is potential. Impossible is temporary. Impossible is nothing.', author: 'Muhammad Ali' }
];

export default LOADER_MESSAGES;
