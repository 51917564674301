import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { buttonFavorite, searchFavorite } from '../../services/firebase';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { Edit } from 'react-feather';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { OverlayTrigger } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

const RecordOptionsContainer = styled.div`
  max-width: 22rem;
  border-radius: 1rem;
  color: #fff;
  padding: 0.5rem;
  border: solid 1px #fff;
  padding: 0.5rem 0.75rem;
  background: #0042;
  position: relative;
  overflow-y: hidden;

  h2 {
    margin-right: 0.5rem;
  }

  .options-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #0042;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      border-radius: 20px;
    }
  }

  .options {
    column-count: 2;

    .MuiBadge-root {
      width: 100%;
    }

    .MuiBadge-badge {
      cursor: pointer;
    }
  }

  .close-button {
    border: 0;
    background: none;
    color: #fff;
    font-size: 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  .style-button {
    background: none;
    border: solid 1px #fff;
    color: #fff;
    border-radius: 4px;
  }
`;

const FavoritesOptionsButton = styled.button<{ isSelected: boolean }>`
  border-radius: 0.25rem;
  margin: 0.5rem 0;
  border: solid 1px #fff;
  color: #fff;
  background-color: rgb(128 128 128 / 80%);
  width: 100%;
  padding: 2px;
  ${(props) => (props.isSelected ?
    `
      background: #fff;
      color: black;
    ` : '')};
`;

const CloseButton = styled.div`
 display: flex;
 align-items: center;
 justify-content: space-between;
`;

interface IMyTundraFavoriteButtons {
  selectedRecord: any;
  setShowRecordOptions: any;
  setSelectedRecordOption: any;
  setShowCustomizeButtons: any;
  setIframeUrl: any;
  optionsList: any;
  setOptionsList: any;
  setMapType: any;
  mapType: any;
  resetMap: any;
  setcopySnackbar: any;
  setIframeSpinner: any;
}

const MyTundraFavoriteButtons: React.FC<IMyTundraFavoriteButtons> = ({
  selectedRecord,
  setShowRecordOptions,
  setSelectedRecordOption,
  setShowCustomizeButtons,
  setIframeUrl,
  optionsList,
  setOptionsList,
  mapType,
  setMapType,
  resetMap,
  setcopySnackbar,
  setIframeSpinner,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [currentButton, setCurrentButton] = useState<number>(0);
  const [selectedButton, setSelectedButton] = useState<string>();
  const [showDisplayNameModal, setShowDisplayNameModal] = useState<boolean>(false);
  const buttonRef = useRef(null);
  const options = ['+ Add Buttons', 'Edit Buttons'];
  const [titleheight, setTitleHeight] = useState(0);
  const titleRef: any = useRef(null);
  const [displayName, setDisplayName] =  useState(selectedRecord.displayName);
  const [displayNameInput, setDisplayNameInput] =  useState(selectedRecord.displayName);
  const [isDisplayNameUpdating, setIsDisplayNameUpdating] =  useState(false);

  // optionsList = optionsList.map((elem: any) => {
  //   if(elem.name === 'Air Emissions System' && selectedRecord.entityNumber !== 'RN100218841') {
  //     return null;
  //   } else {
  //     return elem;
  //   }
  // }).filter((x: any) => x);

  React.useEffect(() => {
    const titleElHeight = (titleRef && titleRef.current && titleRef.current.clientHeight) ? titleRef.current.clientHeight : 0;
    setTitleHeight(titleElHeight);
  }, [titleRef]);

  const removeButtonFavorite = async (option: any) => {
    const list = optionsList.filter((listOption: any) => listOption.buttonId !== option.buttonId);
    setOptionsList(list);

    await buttonFavorite({
      actionType: 'remove',
      record: option
    });
  };

  const useStyles = makeStyles({
    label: {
      textTransform: 'capitalize',
    },
    paper: { width: '35%' },
  });
  const classes = useStyles();

  const getButtonDropDown = () => {
    const onClickHandlers = [() => {
      setIsEditable(false);
      setShowCustomizeButtons(true);
      setSelectedRecordOption('');
      setIframeUrl('');
    }, () => {
      if(isEditable) {
        setCurrentButton(0);
      }
      setIsEditable((prevValue) => !prevValue);
    }];

    const onClickHandler = (index: number) => {
      onClickHandlers[index]();
    };

    const handleMenuItemClick = (index: number) => {
      setIsOpen(false);
      setCurrentButton(index);
      onClickHandler(index);
    };

    const buttonStyles = {
      borderRadius: '4px',
      border: 'solid 1px #fff',
      color: '#fff',
      background: 'rgb(128 128 128 / 80%)',
      padding: '4px'
    };

    return (
      <>
        <ButtonGroup variant="contained" color="primary" ref={buttonRef} aria-label="split button">
          <Button style={{...buttonStyles, borderRight: '0.5rem'}} classes={{label: classes.label}}  onClick={() => onClickHandler(currentButton)}>
            {currentButton === 1 && isEditable ? 'Disable Edit' : options[currentButton] }
          </Button>
          <Button
            style={buttonStyles}
            onClick={() => setIsOpen((prevOpen) => !prevOpen)}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={isOpen}
          role={undefined}
          anchorEl={buttonRef.current}
          placement="bottom"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === currentButton}
                        onClick={() => handleMenuItemClick(index)}
                      >
                        {option}
                      </MenuItem>
                    )).filter((key, index) => index !== currentButton)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>);
  };

  const resetMapHandler = (e: any) => {
    if(mapType) {
      resetMap(e);
      setMapType('');
    }
  };

  const onDisplayNameInputChange = (e: any) => {
    setDisplayNameInput(e.target.value);
  };

  const favoritesFn = async ({type, bodyData}: {type: string, bodyData?: any}) => {
    try {
      const requestBody: any = {
        type,
      };
      if(type === 'update_displayname') {
        requestBody.record = bodyData;
      }
      if(type === 'update_displayname') {
        setIsDisplayNameUpdating(true);
        const result = await searchFavorite(requestBody);
        setIsDisplayNameUpdating(false);
        toast.success(`Display Name Updated SuccessFully.`);
        if(result.data) {
          setDisplayName(result.data.displayName);
          selectedRecord.displayName = result.data.displayName;
        }
      }
      setShowDisplayNameModal(false);
    } catch (error) {
        setIsDisplayNameUpdating(false);
        toast.error(`Error while updating site display name.`);
      }

  };

  const resetHandler =() => {
    setDisplayNameInput('');
  };

  const CloseHandler =() => {
    setShowDisplayNameModal(false);
    setDisplayName(selectedRecord.displayName);
  };

  const onFavoriteButtonClick = (e: any, option: any) => {
    resetMapHandler(e);
    setShowCustomizeButtons(false);
    setSelectedRecordOption('');
    setIframeUrl(option.href);
    setSelectedButton(option.name);
    setIframeSpinner(true);

    if(option.buttonId === 29 || option.buttonId === 12) {
      // Copy for EJ Screen
      setcopySnackbar(true);
    } else {
      setcopySnackbar(false);
    }
  };

  return (
    <RecordOptionsContainer>
    <h2 ref={titleRef}>{displayName ? displayName : selectedRecord.entityName }
    <OverlayTrigger
          overlay={<ReactTooltip place="bottom" effect="solid" />}
        >
          <Button data-tip="Edit Entity Display Name" style={{color: 'white' }} onClick={() => setShowDisplayNameModal(true)}>
            <Edit />
          </Button>
        </OverlayTrigger>
    </h2>
    <Dialog
        open={showDisplayNameModal}
        onClose={() => setShowDisplayNameModal(false)}
        classes={{ paper: classes.paper}}
      >
      <CloseButton>
          <DialogTitle>Display Name</DialogTitle>
          <div>
          <Button  style={{fontSize: 'large'}} onClick={() => {CloseHandler();}}>X</Button>
          </div>
      </CloseButton>
          <DialogContent>
            <TextField
              fullWidth
              disabled
              id="default-name"
              label="Default Name"
              name="adddefaultName"
              value={selectedRecord.entityName}
              />
            <br /><br />
            <TextField
              autoFocus
              fullWidth
              id="display-name"
              label="Display Name"
              name="adddisplayname"
              value={displayNameInput}
              onChange={onDisplayNameInputChange}
            />
          </DialogContent>
          <DialogActions>
             <Button
              variant="contained"
              onClick={resetHandler}
              color="primary"
              style={{visibility: displayName ? 'visible' : 'hidden'}}
            >
              Reset
            </Button>
            <Button
              disabled={isDisplayNameUpdating}
              variant="contained"
              onClick={() => {
                favoritesFn({type: 'update_displayname', bodyData: {
                  contentId:selectedRecord.contentId,
                  displayName: displayNameInput
              }});
            }}
              color="primary"
            >
             Save
             {isDisplayNameUpdating ?
              <CircularProgress style={{color: '#fff', marginLeft: '10px'}} size={20} />
              : null}
            </Button>
          </DialogActions>
      </Dialog>
      <OverlayTrigger
          overlay={<ReactTooltip place="bottom" effect="solid" />}
        >
        <button data-tip="Close" className="close-button" onClick={() => {setShowRecordOptions(false); setSelectedRecordOption(''); setMapType('');}}>X</button>
     </OverlayTrigger>
    <div className="options-wrapper" style={{height: `calc(100% - ${titleheight + 8}px)`}}>
      <div className="options">
        {
          optionsList.map((option: any, index: number) => {
            if(option.type === 'link') {
              return (
                isEditable && !option.isDefault ? <Badge badgeContent={'-'} color="secondary" onClick={() => removeButtonFavorite(option)}>
                    <FavoritesOptionsButton
                      isSelected={selectedButton === option.name}
                      key={index}
                      onClick={(e: any) => onFavoriteButtonClick(e, option)}
                    >
                      {option.name}
                    </FavoritesOptionsButton>
                  </Badge>
                : <FavoritesOptionsButton
                    isSelected={selectedButton === option.name}
                    key={index}
                    onClick={(e: any) => onFavoriteButtonClick(e, option)}
                  >
                    {option.name}
                  </FavoritesOptionsButton>
              );
            } else if(option.type === 'map') {
              return (
                isEditable && !option.isDefault ? <Badge badgeContent={'-'} color="secondary" onClick={() => removeButtonFavorite(option)}>
                    <FavoritesOptionsButton
                      isSelected={selectedButton === option.name}
                      key={index}
                      onClick={() => {setMapType(option.mapType); setSelectedRecordOption(''); setIframeUrl(''); setSelectedButton(option.name);}}
                    >
                      {option.name}
                    </FavoritesOptionsButton>
                  </Badge>
                : <FavoritesOptionsButton
                    isSelected={selectedButton === option.name}
                    key={index}
                    onClick={() => {setMapType(option.mapType); setSelectedRecordOption(''); setIframeUrl(''); setSelectedButton(option.name);}}
                  >
                    {option.name}
                  </FavoritesOptionsButton>
              );
            }

            return (
              isEditable && !option.isDefault ? <Badge badgeContent={'-'} color="secondary" onClick={() => removeButtonFavorite(option)}>
                <FavoritesOptionsButton
                  isSelected={selectedButton === option.name}
                  key={index}
                  onClick={(e: any) => {resetMapHandler(e); setSelectedRecordOption(option.name); setShowCustomizeButtons(false); setIframeUrl(''); setSelectedButton(option.name);}}
                >
                  {option.name}
                </FavoritesOptionsButton>
              </Badge>
              : <FavoritesOptionsButton
                  isSelected={selectedButton === option.name}
                  key={index}
                  onClick={(e: any) => {resetMapHandler(e); setSelectedRecordOption(option.name); setShowCustomizeButtons(false); setIframeUrl(''); setSelectedButton(option.name);}}
                >
                {option.name}
              </FavoritesOptionsButton>
            );
          })
        }
      </div>
      {getButtonDropDown()}
    </div>
  </RecordOptionsContainer>
  );
};

export default MyTundraFavoriteButtons;
