import { Edit, Error, Info, Delete, Done, Save, Warning } from '@material-ui/icons';
import React from 'react';
import { toast } from 'react-toastify';

interface IToastMessageType {
  type: 'success' | 'info' | 'error'| 'warn' ;
  actionType?: 'create' | 'update' | 'delete' | 'save';
  title: string;
  description?: string;
}

const showToastMessage = ({type, actionType, title, description}: IToastMessageType) => {
  let toastIcon = null;
  let actionIcon = null;

  const clippedDescription = () => {
    if(!description) {
      return;
    }
    return description && description.length <= 80 ? description : description.substring(0,80) + '...';
  };

  if(type === 'success') {
    switch (actionType) {
      case 'create':
        actionIcon = <Done className="mr-2"/>;
        break;

      case 'update':
          actionIcon = <Edit className="mr-2"/>;
          break;

      case 'delete':
        actionIcon = <Delete className="mr-2"/>;
        break;

      default:
        actionIcon = <Save className="mr-2"/>;
        break;
    }
  }

  switch (type) {
    case 'success':
      toastIcon = toast.success(
        <div className="d-flex align-items-center ">
          {actionIcon}
          <div>
            <h6 className="mb-0">{title}</h6>
            {description && <p className="mt-1 mb-0 fs-12">{clippedDescription()}</p>}
          </div>
        </div>
      );
      break;

    case 'info':
      toastIcon = toast.info(
        <div className="d-flex align-items-center ">
          <Info className="mr-2"/>
          <div>
            <h6 className="mb-0">{title}</h6>
            {description && <p className="mt-1 mb-0 fs-12">{clippedDescription()}</p>}
          </div>
        </div>
      );
      break;

      case 'warn':
      toastIcon = toast.warn(
        <div className="d-flex align-items-center ">
          <Warning className="mr-2"/>
          <div>
            <h6 className="mb-0">{title}</h6>
            {description && <p className="mt-1 mb-0 fs-12">{clippedDescription()}</p>}
          </div>
        </div>
      );
      break;

    default:
      toastIcon = toast.error(
        <div className="d-flex align-items-center ">
          <Error className="mr-2"/>
          <div>
            <h6 className="mb-0">{title}</h6>
            {description && <p className="mt-1 mb-0 fs-12">{clippedDescription()}</p>}
          </div>
        </div>
      );
      break;
  }

  return toastIcon;
};

export default showToastMessage;
