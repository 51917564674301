import 'react-dropdown-tree-select/dist/styles.css';

import * as R from 'ramda';

import GoogleMapReact, { ChangeEventValue, Maps } from 'google-map-react';
import { IAIEarth, IAir, IJob, IMap } from '../../../store/aiearth/types';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { OverlayTrigger } from 'react-bootstrap';
import React, { useEffect, useState, useRef } from 'react';
import { updateAIEarth, updateAir, updateJob, updateMap } from '../../../store/aiearth/action';
import { useDispatch, useSelector } from 'react-redux';
// import Button from '@material-ui/core/Button';
import CeresBotLogo from '../../../assets/ceres-robo-map.png';
import { CeresLogger } from '../../../logger';
import Checkbox from '@material-ui/core/Checkbox';
import CustomWellMarkers from './CustomWellMarkers';
import FormControl from '@material-ui/core/FormControl';
import { ISearchRecord } from '../../../types/search';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MapTypeIcon from '../../../assets/map-type.png';
import MenuItem from '@material-ui/core/MenuItem';
import PlacesSearch from './PlacesSearch';
import RecordMarker from './RecordMarker';
// import { Search } from 'react-feather';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { isValidLatLong } from '../AiEarthContainer';
import { projectId } from '../../../services/firebase';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import { union } from '@turf/turf';
import useSupercluster from 'use-supercluster';
import JobMaps from './JobMaps';
import { ThreeDmap } from './ThreeDmap';
import TrackbotTabModal from './TrackbotTabModal';
import { renderEPNInfo } from './GoogleMapEPNInfo';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

// tslint:disable-next-line:no-var-requires
const googleMapReactUtil = require('google-map-react/utils');

interface IAiEarthMapProps {
  records: ISearchRecord[];
  programQueryEPNResult?: any;
  showNavigation: any;
  setShowNavigation: any;
  activeMarkerRecord: any;
  setActiveMarkerRecord: any;
  setActivePanel: any;
  gMap: any;
  setGMap: any;
  gMaps: any;
  setGMaps: any;
  rectangle?: any;
  setRectangle?: any;
  getCenterWithOffset: any;
  zoomLevel: any;
  setZoomLevel: any;
  setShowDocPanel: any;
  showProgramQueryPanel: any;
  showProgramQueryDocPanel: any;
  setShowProgramQueryDocPanel: any;
  boundingBox?: any;
  onRectDrag?: any;
  setStreetLocation?: any;
  setAutoScroll: any;
  setSubjectPropertyEsaData?: any;
  resetJobMap: any;
  context: any;
}

interface IMapBoundingBox {
  nw: {
    lat: number;
    lng: number;
  };
  se: {
    lat: number;
    lng: number;
  };
}
const MainMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px);
`;
const MarkerPinGeneric = styled.div`
  width: 7rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  background: #ff0000b3;
  cursor: pointer;
  z-index: 10;
  color: #e9ecef;
  padding: 2px 0 2px 6px;
`;

// const SearchButton = styled(Button)`
//   padding: 7px 10px !important;
//   border-color: rgba(0, 0, 0, 0.23) !important;
//   border: 1px !important;
//   border-style: solid !important;
//   color: #000000 !important;
//   transition: visibility 0s, opacity 0.5s linear;
//   min-width: 55px !important;
//   margin-left: 10px !important;
//   margin-bottom: 4px !important;
// `;

const MarkerTransparent = styled.div`
  width: 16px;
  height: 22px;
  z-index: 999999;
  margin-top: -23px;
  margin-left: -8px;
`;
const MarkerPinStandard = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  background: #005fbc;
  background: var(--primary);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  cursor: pointer;
  z-index: 10;
  &:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #fff;
    background: var(--light);
    position: absolute;
    border-radius: 50%;
  }
`;

const ClusterMarker = styled.div`
  color: #fff;
  background: #005fbc;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 11;
`;

const InnerMapContainer = styled.div`
  display: flex;
  flex: 1;
`;
const MapControlsContainer = styled.div`
  height: 3rem;
  display: flex;
  padding-top: 0.3rem;
  flex-direction: row;
  justify-content: flex-start;
  background: #005fbc;

  #layersBtn {
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      color: black !important;
      border-color: rgba(0, 0, 0, 0.23); !important;
      border-width: 1px !important;
    }
  }
`;

const MapTypeContainer = styled.div`
  cursor: pointer;
  width: 48px;
  margin-right: 10px;
  text-align: center;
  & p {
    z-index: 1;
    padding: 0px;
    margin: -3px;
    border-radius: 5px;
    color: #000;
    font-size: 11px;
    font-weight: 600;
  }
  & img {
    width: 30px;
  }
`;

const CustomFormControl = styled(FormControl)`
  width: 6.5rem;
  border-radius: 4px;
  padding: 2px;
  z-index: 99999;
  & :disabled{
    color: #a9a9a9;
  }
  & label {
    font-size: 1.2rem;
    right: 5rem;
    color: black !important;
  }
  & li span:{
        padding:'1px',
        font-size:'0.7rem'
      }
`;
const IconPegman = styled.img`
  width: 30px;
  cursor: pointer;
  margin: 5px auto;
  display: block;
`;

const theme1 = createTheme({
  overrides: {
    MuiMenuItem: {
      root: {
        '& span': {
          fontSize: '0.7rem',
          padding: '1px',
        },
      },
    },
  },
});

const Marker = ({ children }: any) => children;
let areaInfoWindow: any = null;
const getBoundingBox = (uniqueEntityRecords: ISearchRecord[]): IMapBoundingBox => {
  const locations: Array<{ lat: number; lon: number }> = R.map(
    (record: ISearchRecord) => record.location,
    uniqueEntityRecords
  );
  if (R.isEmpty(locations)) {
    return {
      nw: {
        lat: 29.0,
        lng: -95.0,
      },
      se: {
        lat: 29.5,
        lng: -95.5,
      },
    };
  }

  const [latitudes, longitudes] = R.compose<
    Array<{ lat: number; lon: number }>,
    Array<Array<[string, number]>>,
    Array<[string, number]>,
    {
      [key: string]: Array<[string, number]>;
    },
    [number[], number[]]
  >(
    (groupedData: { [key: string]: Array<[string, number]> }): [number[], number[]] => {
      const groupedLats = groupedData.lat || [];
      const groupedLongs = groupedData.lon || [];
      const getLocValue = R.map((item: [string, number]) => R.last(item) as number);
      return [getLocValue(groupedLats), getLocValue(groupedLongs)];
    },
    R.groupBy(([locKey, _]) => locKey),
    R.reduce((acc, item) => [...acc, ...item], [] as Array<[string, number]>),
    R.map(R.toPairs)
  )(locations);

  // const latitudes = R.flatten(R.map(R.toPairs, locations));
  return {
    nw: {
      lat: Math.max(...latitudes),
      lng: Math.min(...longitudes),
    },
    se: {
      lat: Math.min(...latitudes),
      lng: Math.max(...longitudes),
    },
  };
};

const getMapOptions = (map: Maps): any => ({
  gestureHandling: 'greedy',
  scaleControl: true,
  mapTypeControl: true,
  streetViewControl: true,
  disableDoubleClickZoom: true,
  heading: 0,
  mapTypeControlOptions: {
    position: map.ControlPosition.TOP_LEFT,
    mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain'],
    zoomControlOptions: {
      position: map.ControlPosition.RIGHT_BOTTOM,
      style: map.ZoomControlStyle.SMALL,
    },
  },
  zoomControlOptions: {
    position: map.ControlPosition.RIGHT_TOP,
  },
  streetViewControlOptions: {
    position: map.ControlPosition.RIGHT_TOP,
  },
});

/**
 * hook, that's triggered when the google map is loaded.
 * @param param0.map - Current Map instance.
 * @param param0.maps - google maps object.
 */

const getUniqueSearchEntityRecords = (records: ISearchRecord[]) => {
  return R.compose<ISearchRecord[], ISearchRecord[], ISearchRecord[], ISearchRecord[]>(
    R.uniqBy(R.prop('entityNumber')),
    R.filter((record: ISearchRecord) => !!record && isValidLatLong(record.location.lat, record.location.lon)),
    R.filter((record: ISearchRecord) => !!record && !!record.entityNumber)
  )(records);
};

const EPNPinJob: React.FC<any> = (props) => {
  return (
    <OverlayTrigger
      // key={props.record.contentId}
      trigger="hover"
      placement="bottom"
      delay={{ show: 200, hide: 1200 }}
      overlay={
        <ReactTooltip effect="solid" html={true} />
      }
    >
      <MarkerTransparent
          data-tip={
            ReactDOMServer.renderToString(
              <div>
                <strong
            onClick={() => {
              props.setIsFeature(true);
              props.setAutoScroll(true);
              props.setJobFeature(props.details);
            }}
            style={{ cursor: 'pointer' }}
          >
            {props.entityName}
          </strong>
          <br />
          {props.name}
          <br />
          <IconPegman
            src="/icon/pegman.jpg"
            onClick={() => {
              props.setStreetView({ lat: props.lat, lon: props.lng });
            }}
          />
          </div>
            )
          }
        onClick={() => {
          props.setIsFeature(true);
          props.setAutoScroll(true);
          props.setJobFeature(props.details);
        }}
      ></MarkerTransparent>
    </OverlayTrigger>
  );
};

const EPNPin: React.FC<any> = (props) => {
  const [show, setShow] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [selected, setSelected] = useState<string>('0');

  if (props.marker === 'STANDARD') {
    return (
      <OverlayTrigger
        // key={props.record.contentId}
        trigger="hover"
        placement="bottom"
        delay={{ show: 200, hide: 1200 }}
        overlay={
          <ReactTooltip effect="solid" html={true} />
        }
      >
        <MarkerPinStandard
          data-tip={
            ReactDOMServer.renderToString(
              <div>
              <strong>{props.entityName}</strong>
            <br />
            {props.name}
            <br />
            <IconPegman
              src="/icon/pegman.jpg"
              onClick={() => {
                props.setStreetView({ lat: props.lat, lon: props.lng });
              }}
            />
              </div>
            )
          }
          onClick={() => {
            if (!props.showProgramQueryPanel) {
              props.setActivePanel(true);
              props.setShowDocPanel(false);
            } else {
              props.setShowProgramQueryDocPanel(true);
            }
            props.setAutoScroll(true);
          }}
        ></MarkerPinStandard>
      </OverlayTrigger>
    );
  } else {
    return (
      <div>
        <OverlayTrigger
          // key={props.record.contentId}
          trigger="click"
          placement="bottom"
          rootClose={show}
          container={props.containerRef.current}
          overlay={
            props.details &&
            renderEPNInfo(
              props.details,
              props.entityName,
              props.entity,
              props.openInfoModal,
              props.setInfoModal,
              props.openTabResultsModal,
              props.setTabResultsModal,
              props.openTabResultsType,
              props.setTabResultsType,
              props.setTrackbotTabModal,
              props.tabKeyword,
              props.setTabKeyword,
              setShow,
              modalTitle,
              setModalTitle,
              selected,
              setSelected,
              props.context,
              props.containerRef.current
            )
          }
        >
          <MarkerPinGeneric>{props.name}</MarkerPinGeneric>
        </OverlayTrigger>
      </div>
    );
  }
};
// tslint:disable-next-line: prefer-const
let autocompleteService: any = { current: null };
const AiEarthMap: React.FC<IAiEarthMapProps> = (props) => {
  const {
    activeMarkerRecord,
    setActiveMarkerRecord,
    setActivePanel,
    setGMap,
    setGMaps,
    setRectangle,
    getCenterWithOffset,
    setZoomLevel,
    setStreetLocation,
    gMap,
    gMaps,
    resetJobMap,
    context,
  } = props;
  const dispatch = useDispatch();
  let zoomLevel = 6;
  const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY as string;
  const containerRef: any = useRef<HTMLDivElement>(null);
  const [openInfoModal, setInfoModal] = useState(false);
  const [openTabResultsModal, setTabResultsModal] = useState(false);
  const [openTrackbotTabModal, setTrackbotTabModal] = useState(false);
  const [openTabResultsType, setTabResultsType] = useState('');
  const [tabKeyword, setTabKeyword] = useState('');
  const uniqueEntityRecords = getUniqueSearchEntityRecords(props.records);
  const [bounds, setBounds] = useState([0, 0, 0, 0]);
  const [mapBounds, setMapBounds] = useState({});
  const [parcelLayerRef, setParcelLayerRef] = useState<any>();
  const [selectedParcel, setSelectedParcel] = useState<any>();
  const [selectedParcelAdjProp, setSelectedParcelAdjProp] = useState<any>();
  const [selectedParcelArray, setSelectedParcelArray] = useState<any>([]);
  const [selectedParcelAdjPropArray, setSelectedParcelAdjPropArray] = useState<any>([]);
  const [oilGasWellsLayerRef, setOilGasWellsLayerRef] = useState<any>();
  const [soilLayerRef, setSoilLayerRef] = useState<any>();
  const [msdLayerRef, setMsdLayerRef] = useState<any>();
  const [nplLayerRef, setNplLayerRef] = useState<any>();
  const [ncLayerRef, setNCLayerRef] = useState<any>();
  const [getStreetLabel, setStreetLabel] = useState<any>([]);
  // const [getSearchDisplay, setSearchDisplay] = useState<boolean>(false);
  const [getStreetView, setStreetView] = useState<any>();
  const mapTypeIds = ['satellite', 'roadmap', 'roadmap'];
  const mapTypeLabels = ['2D Map', '3D Map', 'Satellite'];
  const [mapIndex, setMapIndex] = useState(activeMarkerRecord ? 1 : 0);
  const [mapTypeLabel, setMapTypeLabel] = useState<any>(mapTypeLabels[mapIndex]);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [selectJobOpen, setSelectJobOpen] = useState<boolean>(false);
  const [isResetMap, setResetMap] = useState<boolean>(true);
  const [isFeature, setIsFeature] = useState<boolean>(true);
  const [getJobFeature, setJobFeature] = useState<any>({});
  const [findingsTypes, setFindingsTypes] = useState<string>('');
  const [soilsPropertySelect, setSoilsPropertySelect] = useState({});
  const store: any = useSelector<any>((state): any => state);
  const aiEarthState: IAIEarth = store.aiearth;
  const mapState: IMap = store.map;
  const jobState: IJob = store.job;
  const airState: IAir = store.air;

  useEffect(() => {
    if (Object.values(soilsPropertySelect).length > 0) {
      setSoilsPropertySelect({});

      dispatch(
        updateJob({
          ...jobState,
          ...{
            jobInit: false,
            mapFindingTab: true,
            searchFinding: true,
            jobFeatureTab: false,
            mapFindingsTypes: 'Soils Summary',
            soilSelect: soilsPropertySelect,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [soilsPropertySelect]);

  const routeLocation: string = window.location.pathname;

  useEffect(() => {
    if (mapState.gMap && mapState.gMap.mapTypeId !== mapTypeIds[mapIndex]) {
      const index = mapTypeIds.indexOf(mapState.gMap.mapTypeId);
      const idNum = index === 0 ? 0 : index === 1 && mapState.gMap.tilt === 0 ? 2 : 1;
      setMapIndex(idNum);
      setMapTypeLabel(mapTypeLabels[idNum]);
    }
  }, [mapState.gMap, mapTypeIds, mapIndex, mapTypeLabels]);

  useEffect(() => {
    if (isFeature) {
      setIsFeature(false);
      if (findingsTypes === 'adjoining_properties_boundaries') {
        jobState.mapFindingsTypes = findingsTypes;
      }

      dispatch(
        updateJob({
          ...jobState,
          ...{
            jobInit: false,
            mapFindingTab: true,
            jobFeatureTab: findingsTypes ? false : true,
            jobFeature: getJobFeature,
          },
        })
      );
    }
  }, [getJobFeature, isFeature, jobState, findingsTypes, dispatch]);

  useEffect(() => {
    setResetMap(true);
  }, [routeLocation]);
  useEffect(() => {
    if (isResetMap) {
      setResetMap(false);
      resetJobMap();
    }
  }, [routeLocation, resetJobMap, isResetMap]);
  /* tslint:disable: object-literal-key-quotes */
  const LayerZoom: any = {
    Pipeline: 13,
    Parcel: 16,
    'Oil/Gas': 13,
    Soil: 16,
    MSD: 16,
    NPL: 10,
    Contour: 13,
  };

  const showLayers = (layer: any) => {
    if (props.gMap && props.gMap.getZoom() > LayerZoom[layer]) {
      return false;
    } else {
      return true;
    }
  };

  // tslint:disable-next-line: prefer-const
  let mapCenter: any;
  let mapZoom: any;
  const boundingBox = getBoundingBox(uniqueEntityRecords);
  if (boundingBox.nw.lat !== boundingBox.se.lat && boundingBox.nw.lng !== boundingBox.se.lng) {
    const { center, zoom } = googleMapReactUtil.fitBounds(boundingBox, {
      width: 1172,
      height: 235,
    });
    mapCenter = center;
    mapZoom = zoom;
  } else {
    mapCenter = { lat: boundingBox.nw.lat, lng: boundingBox.nw.lng };
    mapZoom = 18;
  }
  if (uniqueEntityRecords.length < 1) {
    mapZoom = 5;
  } else if (uniqueEntityRecords.length === 1) {
    mapZoom = 18;
  }
  const fetchPipelines = async (coords: any) => {
    const body = coords;
    try {
      const response = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/programQueryPipelines',
        // 'http://localhost:5000/ceres-platform-test/us-central1/api/programQueryPipelines',
        body,
        {
          headers: {
            Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
          },
        }
      );
      const resultLists = response.data[0].query_rrc_pipelines;
      if (resultLists) {
        return resultLists.geojson;
      }
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };
  const fetchParcels = async (coords: any) => {
    const body = coords;
    try {
      const response = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/programQueryParcels',
        // 'http://localhost:5000/ceres-platform-test/us-central1/api/programQueryParcels',
        body,
        {
          headers: {
            Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
          },
        }
      );
      const resultLists = response.data[0].query_parcels_tx;
      if (resultLists) {
        return resultLists.geojson;
      }
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  const fetchOilGasWells = async (coords: any) => {
    const body = coords;
    try {
      const response = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/programQueryWells',
        body,
        {
          headers: {
            Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
          },
        }
      );
      const resultLists = response.data[0].query_rrc_wells;
      if (resultLists) {
        return resultLists.geojson;
      }
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  const fetchSoil = async (coords: any) => {
    const body = coords;
    try {
      const response = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/programQuerySoilLayer',
        body,
        {
          headers: {
            Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
          },
        }
      );
      const resultLists = response.data[0].query_soil;
      if (resultLists) {
        return resultLists.geojson;
      }
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  const fetchMSD = async (coords: any) => {
    const body = coords;
    try {
      const response = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/programQueryMSDLayer',
        body,
        {
          headers: {
            Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
          },
        }
      );
      const resultLists = response.data[0].query_msd;
      if (resultLists) {
        return resultLists.geojson;
      }
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  const fetchNPL = async (coords: any) => {
    const body = coords;
    try {
      const response = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/programQueryNPLLayer',
        body,
        {
          headers: {
            Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
          },
        }
      );
      const resultLists = response.data[0].query_npl;
      if (resultLists) {
        return resultLists.geojson;
      }
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  const fetchNC = async (coords: any) => {
    const body = coords;
    try {
      const response = await axios.post(
        'https://us-central1-' + projectId + '.cloudfunctions.net/api/programQueryNationalContourLayer',
        body,
        {
          headers: {
            Authorization: 'rD!ORaqjfQc2m)fl$oRwX~*t7Cn0?%',
          },
        }
      );
      const resultLists = response.data[0].query_nat_contours;
      if (resultLists) {
        return resultLists.geojson;
      }
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };
  const setPipelines = async () => {
    const result = await fetchPipelines(mapBounds);
    if (mapState.pipelineLayerRef) {
      mapState.pipelineLayerRef.forEach((feature: any) => {
        mapState.pipelineLayerRef.remove(feature);
      });
    }
    if (result && result.features) {
      mapState.pipelineLayerRef.addGeoJson(result);
    }
  };

  const setParcels = async (coords?: any) => {
    const result = await fetchParcels(coords ? coords : mapBounds);
    if (parcelLayerRef) {
      parcelLayerRef.forEach((feature: any) => {
        parcelLayerRef.remove(feature);
      });
      removeStreetLabel();
    }
    if (result && result.features) {
      parcelLayerRef.addGeoJson(result);
    }

    if (parcelLayerRef && gMap.getZoom() > 18) {
      const markers: any = getStreetLabel;
      parcelLayerRef.forEach((feature: any) => {
        const myLatlng = new gMaps.LatLng(
          parseFloat(feature.getProperty('centroid_y')),
          parseFloat(feature.getProperty('centroid_x'))
        );
        const marker = new gMaps.Marker({
          position: myLatlng,
          icon: {
            url: ' ',
            anchor: new gMaps.Point(12, 12),
          },
          label: {
            text: feature.getProperty('Street Number'),
            fontSize: '12px',
            color: '#000',
          },
          map: gMap,
        });
        markers.push(marker);
      });
      setStreetLabel(markers);
    } else {
      removeStreetLabel();
      setStreetLabel([]);
    }
  };

  const removeStreetLabel = async () => {
    const markers = getStreetLabel;
    if (markers.length > 0) {
      for (const each of markers) {
        each.setMap(null);
      }
    }
  };

  const setOilGasWells = async (coords?: any) => {
    const result = await fetchOilGasWells(coords ? coords : mapBounds);
    if (oilGasWellsLayerRef) {
      oilGasWellsLayerRef.forEach((feature: any) => {
        oilGasWellsLayerRef.remove(feature);
      });
    }
    if (result && result.features) {
      oilGasWellsLayerRef.addGeoJson(result);
    }
  };

  const setSoil = async (coords?: any) => {
    const result = await fetchSoil(coords ? coords : mapBounds);
    if (soilLayerRef) {
      soilLayerRef.forEach((feature: any) => {
        soilLayerRef.remove(feature);
      });
    }
    if (result && result.features) {
      soilLayerRef.addGeoJson(result);
    }
  };

  const setNPL = async (coords?: any) => {
    const result = await fetchNPL(coords ? coords : mapBounds);
    if (nplLayerRef) {
      nplLayerRef.forEach((feature: any) => {
        nplLayerRef.remove(feature);
      });
    }
    if (result && result.features) {
      nplLayerRef.addGeoJson(result);
    }
  };

  const setMSD = async (coords?: any) => {
    const result = await fetchMSD(coords ? coords : mapBounds);
    if (msdLayerRef) {
      msdLayerRef.forEach((feature: any) => {
        msdLayerRef.remove(feature);
      });
    }
    if (result && result.features) {
      msdLayerRef.addGeoJson(result);
    }
  };

  const setNC = async (coords?: any) => {
    const result = await fetchNC(coords ? coords : mapBounds);
    if (ncLayerRef) {
      ncLayerRef.forEach((feature: any) => {
        ncLayerRef.remove(feature);
      });
    }
    if (result && result.features) {
      ncLayerRef.addGeoJson(result);
    }
  };

  /* eslint-disable */
  const setLayers = async (layersArray: any, activate?: boolean, coords?: any) => {
    if (props.gMap.getZoom() > 16) {
      if (layersArray.includes('Parcel')) {
        setParcels(coords);
      }
      if (layersArray.includes('Oil/Gas')) {
        setOilGasWells(coords);
      }
      if (layersArray.includes('Soil')) {
        setSoil(coords);
      }
      if (layersArray.includes('MSD')) {
        setMSD(coords);
      }
    }
    if (props.gMap.getZoom() > LayerZoom.NPL) {
      if (layersArray.includes('NPL')) {
        setNPL(coords);
      }
    }
    if (props.gMap.getZoom() > LayerZoom.Contour) {
      if (layersArray.includes('Contour')) {
        setNC(coords);
      }
    }
    if (props.gMap.getZoom() > LayerZoom.Pipeline) {
      if (layersArray.includes('Pipeline')) {
        setPipelines();
      }
    }
  };
  /* eslint-enable */
  const removeLayers = (layerArray: any) => {
    if (mapState.pipelineLayerRef && ((props.gMap && props.gMap.getZoom() <= 13) || !layerArray.includes('Pipeline'))) {
      mapState.pipelineLayerRef.forEach((feature: any) => {
        mapState.pipelineLayerRef.remove(feature);
      });
    }
    if (parcelLayerRef && ((props.gMap && props.gMap.getZoom() <= 16) || !layerArray.includes('Parcel'))) {
      parcelLayerRef.forEach((feature: any) => {
        parcelLayerRef.remove(feature);
      });
    }
    if (oilGasWellsLayerRef && ((props.gMap && props.gMap.getZoom() <= 16) || !layerArray.includes('Oil/Gas'))) {
      oilGasWellsLayerRef.forEach((feature: any) => {
        oilGasWellsLayerRef.remove(feature);
      });
    }
    if (soilLayerRef && ((props.gMap && props.gMap.getZoom() <= 16) || !layerArray.includes('Soil'))) {
      soilLayerRef.forEach((feature: any) => {
        soilLayerRef.remove(feature);
      });
    }
    if (nplLayerRef && ((props.gMap && props.gMap.getZoom() <= 10) || !layerArray.includes('NPL'))) {
      nplLayerRef.forEach((feature: any) => {
        nplLayerRef.remove(feature);
      });
    }
    if (msdLayerRef && ((props.gMap && props.gMap.getZoom() <= 16) || !layerArray.includes('MSD'))) {
      msdLayerRef.forEach((feature: any) => {
        msdLayerRef.remove(feature);
      });
    }
    if (ncLayerRef && ((props.gMap && props.gMap.getZoom() <= 13) || !layerArray.includes('National Contour'))) {
      ncLayerRef.forEach((feature: any) => {
        ncLayerRef.remove(feature);
      });
    }
  };

  const handleLayersData = async (event: any) => {
    const layerArray = event.target.value;
    removeLayers(layerArray);
    mapState.layers = layerArray;
    dispatch(updateMap(mapState));
    setLayers(layerArray);
  };
  mapCenter = activeMarkerRecord
    ? activeMarkerRecord.location && activeMarkerRecord.location.lat
      ? getCenterWithOffset(activeMarkerRecord.location.lat, activeMarkerRecord.location.lon, -0.1, -0.15)
      : { lat: 31, lng: -100 }
    : !!uniqueEntityRecords.length
    ? mapCenter
    : { lat: 31, lng: -100 };
  if (uniqueEntityRecords.length === 1) {
    mapCenter = {
      lat: uniqueEntityRecords[0].location.lat,
      lng: uniqueEntityRecords[0].location.lon,
    };
  }
  if (uniqueEntityRecords.length === 0 && props.boundingBox && props.rectangle && props.rectangle.getBounds()) {
    mapZoom = props.gMap.getZoom();
    mapCenter = props.gMap.getCenter();
  }
  zoomLevel = mapZoom;
  if (props.zoomLevel) {
    zoomLevel = props.zoomLevel;
  }

  const points = uniqueEntityRecords.map((clusterRecord: any) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      recordId: clusterRecord.contentId,
      category: clusterRecord.state,
    },
    record: clusterRecord,
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(clusterRecord.location.lon), parseFloat(clusterRecord.location.lat)],
    },
  }));

  const onMapLoaded = ({ map, maps }: { map: any; maps: any }) => {
    // Set silver style for the map.
    // const styledMapSilver = new maps.StyledMapType(silverMapStyle, {
    //   name: 'Gray Map',
    // });
    // map.mapTypes.set('gray_map', styledMapSilver);
    map.setMapTypeId('roadmap');

    // Load ceres logo.
    const logoElement = document.createElement('img');
    logoElement.src = CeresBotLogo;
    logoElement.className = 'ceres-map-logo';
    map.controls[maps.ControlPosition.LEFT_BOTTOM] = [logoElement];
    const rectangleRef = new maps.Rectangle({
      draggable: true,
      editable: true,
      geodesic: false,
      strokeColor: '#005FBC',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#f99d38',
      fillOpacity: 0.35,
    });
    rectangleRef.addListener('bounds_changed', () => {
      const northEast = rectangleRef.getBounds().getNorthEast();
      const southwest = rectangleRef.getBounds().getSouthWest();
      const coords = {
        boundingBox: `${northEast.lng()},${southwest.lat()},${southwest.lng()},${northEast.lat()}`,
      };
      props.onRectDrag(coords);
    });
    setRectangle(rectangleRef);
  };
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: props.gMap ? props.gMap.getZoom() : 5,
    options: { radius: 40, maxZoom: 20 },
  });

  const setMapType = () => {
    const mapTypeIndex = mapIndex === 2 ? 0 : mapIndex + 1;

    setMapIndex(mapTypeIndex);
    setMapTypeLabel(mapTypeLabels[mapTypeIndex]);
    if (mapTypeIds[mapTypeIndex] === 'roadmap') {
      props.gMap.setMapTypeId('roadmap');
      props.gMap.tilt === 0 ? props.gMap.setTilt(67.5) : props.gMap.setTilt(0);
    } else if (mapTypeIds[mapTypeIndex] === 'satellite') {
      props.gMap.setMapTypeId('satellite');
      props.gMap.setTilt(0);
    }
  };

  const showStreetView = (getMaps: any, gLocation: any) => {
    const astorPlace = new gMaps.LatLng(gLocation.lat, gLocation.lon);
    const sv = new gMaps.StreetViewService();
    const panorama = getMaps.getStreetView();
    panorama.setPosition(astorPlace);
    panorama.setPov({
      heading: 270,
      pitch: 0,
    });
    sv.getPanorama({ location: astorPlace, radius: 50 }, (result: any, status: string) => {
      if (status === 'OK') {
        panorama.setPano(result.location.pano);
        panorama.setPosition(astorPlace);
        panorama.setPov({
          heading: 270,
          pitch: 0,
        });
        panorama.setVisible(true);
      } else {
        sv.getPanorama({ location: astorPlace, radius: 5000 }, (result2: any, status2: string) => {
          if (status2 === 'OK') {
            panorama.setPano(result2.location.pano);
            panorama.setPosition(result2.location.latLng);
            panorama.setPov({
              heading: 270,
              pitch: 0,
            });
            panorama.setVisible(true);
          } else {
            panorama.setVisible(false);
            panorama.setPosition(astorPlace);
          }
        });
      }
    });
    setStreetLocation(panorama);
    setStreetView(false);
  };

  if (getStreetView) {
    showStreetView(props.gMap, getStreetView);
  }

  useEffect(() => {
    if (jobState.jobCreate === 'cancel' && jobState.jobAction) {
      mapState.parcelSelectionLayer.forEach((feature: any) => {
        mapState.parcelSelectionLayer.remove(feature);
      });
      mapState.adjoiningPropertyLayer.forEach((feature: any) => {
        mapState.adjoiningPropertyLayer.remove(feature);
      });
      gMaps.event.clearInstanceListeners(parcelLayerRef);
      dispatch(
        updateJob({
          ...jobState,
          ...{ jobAction: false },
          ...{ jobCreate: 'neutral' },
        })
      );
      setSelectedParcelArray([]);
      setSelectedParcelAdjPropArray([]);
    } else if (jobState.jobCreate === 'save' && jobState.jobAction) {
      mapState.parcelSelectionLayer.forEach((feature: any) => {
        mapState.parcelSelectionLayer.remove(feature);
      });
      mapState.adjoiningPropertyLayer.forEach((feature: any) => {
        mapState.adjoiningPropertyLayer.remove(feature);
      });
      gMaps.event.clearInstanceListeners(parcelLayerRef);
      dispatch(
        updateJob({
          ...jobState,
          ...{ jobAction: false },
          ...{ jobCreate: 'neutral' },
        })
      );
      setSelectedParcelArray([]);
      setSelectedParcelAdjPropArray([]);
    } else if (parcelLayerRef && jobState.jobCreate === 'create' && jobState.jobAction) {
      gMaps.event.clearInstanceListeners(parcelLayerRef);
      gMaps.event.addListener(
        parcelLayerRef,
        'click',
        throttle((e: any) => {
          setSelectedParcel(e.feature);
        }, 600)
      );
      dispatch(
        updateJob({
          ...jobState,
          ...{ jobAction: false },
        })
      );
    } else if (parcelLayerRef && jobState.jobCreate === 'createAdjoiningProp' && jobState.jobAction) {
      gMaps.event.clearInstanceListeners(parcelLayerRef);
      gMaps.event.addListener(
        parcelLayerRef,
        'click',
        throttle((e: any) => {
          setSelectedParcelAdjProp(e.feature);
        }, 600)
      );
      dispatch(
        updateJob({
          ...jobState,
          ...{ jobAction: false },
        })
      );
    } else if (parcelLayerRef && jobState.jobCreate === 'neutral') {
      gMaps.event.clearInstanceListeners(parcelLayerRef);
      gMaps.event.addListener(parcelLayerRef, 'click', (e: any) => {
        if (areaInfoWindow != null) {
          areaInfoWindow.close();
          areaInfoWindow = null;
        }
        e.feature.removeProperty('layer');
        e.feature.removeProperty('centroid_x');
        e.feature.removeProperty('centroid_y');
        e.feature.removeProperty('ogc_fid');
        let parcelPropInfo = '';
        e.feature.forEachProperty((keyValue: string, property: string) => {
          parcelPropInfo += `<p class="mb-1"><b>${property}</b>: ${keyValue}</p>`;
        });
        areaInfoWindow = new gMaps.InfoWindow({
          content: `<div>${parcelPropInfo}</div>`,
          position: e.latLng,
        });
        areaInfoWindow.open(gMap);
      });
      gMaps.event.addListener(parcelLayerRef, 'mouseout', (e: any) => {
        if (areaInfoWindow != null) {
          areaInfoWindow.close();
          areaInfoWindow = null;
        }
      });
    }
    if (selectedParcel && jobState.jobCreate === 'create') {
      selectedParcel.toGeoJson((json: any) => {
        let selectedParcelArrayJSON = selectedParcelArray;
        let removeJson = false;
        selectedParcelArrayJSON.forEach((feature: any, index: any) => {
          if (feature.properties['Parcel ID'] === json.properties['Parcel ID']) {
            selectedParcelArray.splice(index, 1);
            removeJson = true;
          }
        });
        if (!removeJson) {
          selectedParcelArrayJSON = selectedParcelArray.concat(json);
        }
        setSelectedParcelArray(selectedParcelArrayJSON);
        if (selectedParcelArrayJSON.length > 0) {
          const multiPolygonFinal = selectedParcelArrayJSON.reduce((res: any, parcel: any) => {
            return union(res, parcel);
          });
          dispatch(
            updateJob({
              ...jobState,
              ...{ jobJson: multiPolygonFinal },
            })
          );
        }
      });
      let remove = false;
      mapState.parcelSelectionLayer.forEach((feature: any) => {
        if (feature.getProperty('Parcel ID') === selectedParcel.getProperty('Parcel ID')) {
          mapState.parcelSelectionLayer.remove(feature);
          remove = true;
        }
      });
      if (!remove) {
        mapState.parcelSelectionLayer.add(selectedParcel);
      }
      setSelectedParcel(null);
    } else if (selectedParcelAdjProp && jobState.jobCreate === 'createAdjoiningProp') {
      selectedParcelAdjProp.toGeoJson((json: any) => {
        let selectedParcelAdjPropArrayJSON = selectedParcelAdjPropArray;
        let removeJson = false;
        selectedParcelAdjPropArrayJSON.forEach((feature: any, index: any) => {
          if (feature.properties['Parcel ID'] === json.properties['Parcel ID']) {
            selectedParcelAdjPropArray.splice(index, 1);
            removeJson = true;
          }
        });
        if (!removeJson) {
          selectedParcelAdjPropArrayJSON = selectedParcelAdjPropArray.concat(json);
        }
        setSelectedParcelAdjPropArray(selectedParcelAdjPropArrayJSON);
        if (selectedParcelAdjPropArrayJSON.length > 0) {
          const multiPolygonFinal = selectedParcelAdjPropArrayJSON.reduce((res: any, parcel: any) => {
            return union(res, parcel);
          });
          dispatch(
            updateJob({
              ...jobState,
              ...{ adjoiningPropJson: multiPolygonFinal },
            })
          );
        }
      });
      let remove = false;
      mapState.adjoiningPropertyLayer.forEach((feature: any) => {
        if (feature.j['Parcel ID'] === selectedParcelAdjProp.j['Parcel ID']) {
          mapState.adjoiningPropertyLayer.remove(feature);
          remove = true;
        }
      });
      if (!remove) {
        mapState.adjoiningPropertyLayer.add(selectedParcelAdjProp);
      }
      setSelectedParcelAdjProp(null);
    }
    if (mapState.execute && mapState.layers.length > 0) {
      setLayers(mapState.layers);
      mapState.execute = false;
      dispatch(updateMap(mapState));
    }
  }, [
    parcelLayerRef,
    gMap,
    gMaps,
    jobState,
    mapState,
    dispatch,
    setLayers,
    selectedParcelAdjProp,
    selectedParcelAdjPropArray,
    selectedParcel,
    selectedParcelArray,
  ]);

  const selectClose = () => {
    setSelectOpen(false);
  };
  return (
    <MainMapContainer>
      <MapControlsContainer id="map-controls-container">
        {props.gMap && (
          <>
            <MapTypeContainer onClick={setMapType}>
              <img alt="map type" src={MapTypeIcon} />
              <p>{mapTypeLabel}</p>
            </MapTypeContainer>
            <MuiThemeProvider theme={theme1}>
              <CustomFormControl size="small" variant="outlined" id="layersBtn">
                <InputLabel shrink={false}>Layers</InputLabel>
                <Select
                  multiple
                  id="myCheck"
                  classes={{ select: 'layersBtn' }}
                  value={mapState.layers}
                  onChange={handleLayersData}
                  onClose={(e) => {
                    // TODO
                  }}
                  onMouseEnter={() => {
                    setSelectJobOpen(false);
                    setSelectOpen(true);
                  }}
                  onMouseMove={(e: any) => {
                    const popup: any = document.querySelectorAll('.layersPopup > .MuiPaper-elevation8')[0];
                    if (popup) {
                      popup.onmouseleave = selectClose;
                      const popupLabels: any = document.getElementById('layersBtn');
                      if (popup.contains(e.target) === true || popupLabels.contains(e.target) === true) {
                        setSelectJobOpen(false);
                        setSelectOpen(true);
                      } else {
                        setSelectOpen(false);
                      }
                    }
                  }}
                  onClick={(e: any) => {
                    if(!e.target.value) {
                      setSelectOpen(!selectOpen);
                    }
                  }}
                  open={selectOpen}
                  renderValue={(selected) => ''}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    className: 'layersPopup JobLayersPopup',
                  }}
                >
                  {['Pipeline', 'Parcel', 'Oil/Gas', 'Soil', 'MSD', 'NPL', 'Contour'].map((layer, index) => (
                    <MenuItem key={index} value={layer}>
                      <Checkbox
                        disabled={showLayers(layer)}
                        checked={showLayers(layer) ? false : mapState.layers.indexOf(layer) > -1}
                      />
                      <ListItemText primary={layer} />
                    </MenuItem>
                  ))}
                </Select>
              </CustomFormControl>
            </MuiThemeProvider>
            {aiEarthState.activeTab === 'jobs' &&
              (jobState.jobInit || jobState.mapFindingTab) &&
              Object.keys(jobState.esaData).length > 0 &&
              typeof jobState.esaData.error === 'undefined' &&
              jobState.isLoadEsaData && (
                <JobMaps
                  gMap={gMap}
                  gMaps={gMaps}
                  setSubjectPropertyEsaData={props.setSubjectPropertyEsaData}
                  setSelectOpen={setSelectOpen}
                  selectJobOpen={selectJobOpen}
                  setSelectJobOpen={setSelectJobOpen}
                />
              )}
              <div>
                <PlacesSearch value={mapState.places} gMap={props.gMap} gMaps={props.gMaps} />
              </div>
          </>
        )}
      </MapControlsContainer>
      <InnerMapContainer ref={containerRef}>
        <GoogleMapReact
          onChange={(eventValue: ChangeEventValue) => {
            removeLayers(mapState.layers);
            const bBounds = [
              eventValue.bounds.nw.lng,
              eventValue.bounds.se.lat,
              eventValue.bounds.se.lng,
              eventValue.bounds.nw.lat,
            ];
            setBounds(bBounds);
            let coords = null;
            if (props.gMap) {
              const northEast = props.gMap.getBounds().getNorthEast();
              const southwest = props.gMap.getBounds().getSouthWest();
              coords = {
                boundingBox: `${northEast.lng()},${southwest.lat()},${southwest.lng()},${northEast.lat()}`,
              };
              setMapBounds(coords);
            }
            removeLayers(mapState.layers);
            if (mapState.layers.length > 0) {
              setLayers(mapState.layers, true, coords);
            }
          }}
          bootstrapURLKeys={{
            key: GOOGLE_MAP_KEY,
            libraries: ['places', 'drawing'],
            v: 'beta',
          }}
          onClick={() => {
            // resetMapToInitial();
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }: { map: any; maps: any }) => {
            onMapLoaded({ map, maps });
            setGMap(map);
            setGMaps(maps);
            if (maps.places) {
              autocompleteService.current = new maps.places.AutocompleteService();
            }

            const rotateControlDiv = document.createElement('div');
            rotateControlDiv.style.margin = '0 10px 0';
            const rotateControl = document.createElement('div');
            rotateControl.style.border = '0';
            rotateControl.style.padding = '2px 0';
            rotateControl.style.borderRadius = '2px';
            rotateControl.style.backgroundColor = '#fff';
            rotateControl.style.width = '40px';
            rotateControl.style.height = '40px';
            rotateControl.style.display = 'flex';
            rotateControl.style.alignItems = 'center';
            rotateControl.style.justifyContent = 'center';
            rotateControl.style.boxShadow = 'rgb(0 0 0 / 30%) 0px 1px 4px -1px';
            rotateControl.style.cursor = 'pointer';
            rotateControl.classList.add('rotate-button');
            rotateControl.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#666"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-5.5-2.5l7.51-3.49L17.5 6.5 9.99 9.99 6.5 17.5zm5.5-6.6c.61 0 1.1.49 1.1 1.1s-.49 1.1-1.1 1.1-1.1-.49-1.1-1.1.49-1.1 1.1-1.1z"/></svg>`;
            rotateControl.addEventListener('click', () => {
              map.setHeading(map.getHeading()! + 20);
            });
            rotateControlDiv.appendChild(rotateControl);
            map.controls[maps.ControlPosition.RIGHT_TOP].push(rotateControlDiv);

            const tiltControlDiv = document.createElement('div');
            tiltControlDiv.style.margin = '10px';
            const tiltControl = document.createElement('span');
            tiltControl.style.border = '0';
            tiltControl.style.padding = '2px 0';
            tiltControl.style.borderRadius = '2px';
            tiltControl.style.backgroundColor = '#fff';
            tiltControl.style.fontSize = '20px';
            tiltControl.style.color = '#666';
            tiltControl.style.fontWeight = '500';
            tiltControl.style.width = '40px';
            tiltControl.style.height = '40px';
            tiltControl.style.display = 'flex';
            tiltControl.style.alignItems = 'center';
            tiltControl.style.justifyContent = 'center';
            tiltControl.style.boxShadow = 'rgb(0 0 0 / 30%) 0px 1px 4px -1px';
            tiltControl.style.cursor = 'pointer';
            tiltControl.classList.add('tilt-button');
            tiltControl.innerText = '3D';

            tiltControl.addEventListener('click', () => {
              map.setTilt(map.getTilt() === 0 && map.getMapTypeId() === 'roadmap' ? 67.5 : 0);
              const tiltbtn: any = document.getElementsByClassName('tilt-button');
              tiltbtn[0].innerHTML = tiltbtn[0].innerHTML === '2D' && map.getMapTypeId() === 'roadmap' ? '3D' : '2D';
            });
            tiltControlDiv.appendChild(tiltControl);
            map.controls[maps.ControlPosition.RIGHT_TOP].push(tiltControlDiv);

            const rotate3dDiv = document.createElement('div');
            rotate3dDiv.style.margin = '0 10px';
            const rotate3d = document.createElement('span');
            rotate3d.style.border = '0';
            rotate3d.style.padding = '2px 0';
            rotate3d.style.borderRadius = '2px';
            rotate3d.style.backgroundColor = '#fff';
            rotate3d.style.fontSize = '20px';
            rotate3d.style.color = '#666';
            rotate3d.style.fontWeight = '500';
            rotate3d.style.width = '40px';
            rotate3d.style.height = '40px';
            rotate3d.style.display = 'flex';
            rotate3d.style.alignItems = 'center';
            rotate3d.style.justifyContent = 'center';
            rotate3d.style.boxShadow = 'rgb(0 0 0 / 30%) 0px 1px 4px -1px';
            rotate3d.style.cursor = 'pointer';
            rotate3d.classList.add('rotate3d-button');
            rotate3d.innerHTML =
              '<svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#666"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.53 21.48C4.26 19.94 1.92 16.76 1.56 13H.06c.51 6.16 5.66 11 11.95 11l.66-.03-3.81-3.81-1.33 1.32zm.89-6.52c-.19 0-.37-.03-.52-.08-.16-.06-.29-.13-.4-.24-.11-.1-.2-.22-.26-.37-.06-.14-.09-.3-.09-.47h-1.3c0 .36.07.68.21.95.14.27.33.5.56.69.24.18.51.32.82.41.3.1.62.15.96.15.37 0 .72-.05 1.03-.15.32-.1.6-.25.83-.44s.42-.43.55-.72.2-.61.2-.97c0-.19-.02-.38-.07-.56-.05-.18-.12-.35-.23-.51-.1-.16-.24-.3-.4-.43-.17-.13-.37-.23-.61-.31.2-.09.37-.2.52-.33.15-.13.27-.27.37-.42.1-.15.17-.3.22-.46s.07-.32.07-.48c0-.36-.06-.68-.18-.96s-.29-.51-.51-.69c-.2-.19-.47-.33-.77-.43C9.11 8.05 8.77 8 8.4 8c-.36 0-.69.05-1 .16-.3.11-.57.26-.79.45-.21.19-.38.41-.51.67-.12.26-.18.54-.18.85h1.3c0-.17.03-.32.09-.45s.14-.25.25-.34.23-.17.38-.22.3-.08.48-.08c.4 0 .7.1.89.31.19.2.29.49.29.86 0 .18-.03.34-.08.49s-.14.27-.25.37c-.11.1-.25.18-.41.24-.16.06-.36.09-.58.09h-.77v1.03h.77c.22 0 .42.02.6.07s.33.13.45.23c.12.11.22.24.29.4s.1.35.1.57c0 .41-.12.72-.35.93-.23.23-.55.33-.95.33zm8.55-5.92c-.32-.33-.7-.59-1.14-.77-.44-.18-.93-.27-1.47-.27H12v8h2.3c.55 0 1.06-.09 1.51-.27s.84-.43 1.16-.76c.32-.33.57-.73.74-1.19.17-.47.26-.99.26-1.57v-.4c0-.58-.09-1.1-.26-1.57s-.42-.87-.74-1.2zm-.39 3.16c0 .42-.05.79-.14 1.13-.1.33-.24.62-.43.85-.19.23-.43.41-.71.53-.29.12-.62.18-.99.18h-.91V9.12h.97c.72 0 1.27.23 1.64.69.38.46.57 1.12.57 1.99v.4zM12.01 0l-.66.03 3.81 3.81 1.33-1.33c3.27 1.55 5.61 4.72 5.96 8.48h1.5C23.45 4.84 18.3 0 12.01 0z"/></svg>';
            rotate3d.addEventListener('click', () => {
              if (map.zoom > 12 && map.getMapTypeId() === 'roadmap') {
                ThreeDmap(map, maps);
              }
            });
            rotate3dDiv.appendChild(rotate3d);
            map.controls[maps.ControlPosition.RIGHT_TOP].push(rotate3dDiv);

            const parcelLayer = new maps.Data({ map });
            setParcelLayerRef(parcelLayer);
            const pipelineLayerRef = new maps.Data({ map });
            const oilgasWellLayer = new maps.Data({ map });
            setOilGasWellsLayerRef(oilgasWellLayer);
            const soilLayer = new maps.Data({ map });
            setSoilLayerRef(soilLayer);
            const nplLayer = new maps.Data({ map });
            setNplLayerRef(nplLayer);
            const msdLayer = new maps.Data({ map });
            setMsdLayerRef(msdLayer);
            const ncLayer = new maps.Data({ map });
            setNCLayerRef(ncLayer);
            const parcelSelectionLayer = new maps.Data({ map });
            const subjectPropertyShowLayer = new maps.Data({ map });
            const oilGasJobMaps = new maps.Data({ map });
            const pipeLinesJobMaps = new maps.Data({ map });
            const adjoiningPropertyLayer = new maps.Data({ map });
            const adjoiningPropertiesBoundaries = new maps.Data({ map });
            const waterWellsPointer = new maps.Data({ map });
            const monitorWellsPointer = new maps.Data({ map });
            const soilsPropertyLayer = new maps.Data({ map });
            adjoiningPropertyLayer.setStyle((feature: any) => {
              return {
                fillColor: '#fcba03',
                fillOpacity: 0.5,
              };
            });
            subjectPropertyShowLayer.setStyle((feature: any) => {
              if (typeof feature.getProperty('symbol') !== 'undefined' && feature.getProperty('symbol')) {
                const symbol = CustomWellMarkers[feature.getProperty('symbol')];
                const svgIcon = {
                  path: symbol[`path`],
                  fillColor: symbol[`fillColor`],
                  fillOpacity: 0.7,
                  scale: 0.04,
                  anchor: new maps.Point(520, 520),
                };
                return {
                  icon: svgIcon,
                  strokeWeight: 1,
                };
              } else {
                const fill = feature.getProperty('fill') ? feature.getProperty('fill') : '#005FBC';
                const fillOpc = feature.getProperty('fill-opacity') ? feature.getProperty('fill-opacity') : 0;
                const stroke = feature.getProperty('stroke') ? feature.getProperty('stroke') : '#005FBC';
                const strokeOpc = feature.getProperty('stroke-opacity')
                  ? feature.getProperty('stroke-opacity')
                  : '#005FBC';
                const strokeWidth = feature.getProperty('stroke-width') ? feature.getProperty('stroke-width') : 2;
                const labels = feature.getProperty('label') ? feature.getProperty('label') : '';
                const types = feature.getProperty('type') ? feature.getProperty('type') : 'SUBJECT_PROPERTY';
                return {
                  icon: {
                    path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                    fillColor: feature.getProperty('marker-color'),
                    fillOpacity: 0.8,
                    scale: 1,
                    anchor: new maps.Point(12, 24),
                  },
                  fillColor: fill,
                  fillOpacity: fillOpc,
                  strokeColor: stroke,
                  strokeOpacity: strokeOpc,
                  strokeWeight: strokeWidth,
                  label: labels,
                  type: types,
                  zIndex: -1,
                };
              }
            });
            subjectPropertyShowLayer.addListener('click', (feature: any) => {
              const property = feature.feature.i;
              if (property && typeof property.entity_number !== 'undefined' && property.entity_number) {
                setJobFeature(feature.feature.i);
                setIsFeature(true);
                props.setAutoScroll(true);
              }
            });
            waterWellsPointer.setStyle((feature: any) => {
              return {
                icon: {
                  path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                  fillColor: '#005FBC',
                  fillOpacity: 0.8,
                  scale: 1,
                  anchor: new maps.Point(10, 2),
                },
                fillColor: '#005FBC',
                zIndex: -1,
              };
            });

            waterWellsPointer.addListener('mouseover', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
              let propertyInfo = '';
              propertyInfo += `<p class="mb-1"><b>Symbol</b>: ${e.feature.getProperty('symbol')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Type</b>: ${e.feature.getProperty('type')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Water Well ID</b>: ${e.feature.getProperty('water_well_id')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Well Type</b>: ${e.feature.getProperty('welltype')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Proposedus</b>: ${e.feature.getProperty('proposedus')}</p>`;
              propertyInfo += `<p class="mb-1"><b>County</b>: ${e.feature.getProperty('county')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Well Owner</b>: ${e.feature.getProperty('wellowner')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Water Level</b>: ${e.feature.getProperty('waterlevel')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Aquifercod</b>: ${e.feature.getProperty('aquifercod')}</p>`;

              areaInfoWindow = new maps.InfoWindow({
                content: `<div>${propertyInfo}</div>`,
                position: e.latLng,
              });
              areaInfoWindow.open(map);
            });

            monitorWellsPointer.setStyle((feature: any) => {
              return {
                icon: {
                  path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                  fillColor: '#005FBC',
                  fillOpacity: 0.8,
                  scale: 1,
                  anchor: new maps.Point(10, 2),
                },
                fillColor: '#005FBC',
                zIndex: -1,
              };
            });

            monitorWellsPointer.addListener('mouseover', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
              let propertyInfo = '';
              propertyInfo += `<p class="mb-1"><b>Label</b>: ${e.feature.getProperty('label')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Proposedus</b>: ${e.feature.getProperty('proposedus')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Symbol</b>: ${e.feature.getProperty('symbol')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Type</b>: ${e.feature.getProperty('type')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Water Well ID</b>: ${e.feature.getProperty('water_well_id')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Well City</b>: ${e.feature.getProperty('wellcity')}</p>`;
              propertyInfo += `<p class="mb-1"><b>well Owner</b>: ${e.feature.getProperty('wellowner')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Well Report</b>: ${e.feature.getProperty('wellreport')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Well Street</b>: ${e.feature.getProperty('wellstreet')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Well Type</b>: ${e.feature.getProperty('welltype')}</p>`;

              areaInfoWindow = new maps.InfoWindow({
                content: `<div>${propertyInfo}</div>`,
                position: e.latLng,
              });
              areaInfoWindow.open(map);
            });

            adjoiningPropertiesBoundaries.setStyle((feature: any) => {
              const fill = feature.getProperty('fill') ? feature.getProperty('fill') : '#005FBC';
              const fillOpc = feature.getProperty('fill-opacity') ? feature.getProperty('fill-opacity') : 0;
              const stroke = feature.getProperty('stroke') ? feature.getProperty('stroke') : '#005FBC';
              const strokeOpc = feature.getProperty('stroke-opacity')
                ? feature.getProperty('stroke-opacity')
                : '#005FBC';
              const strokeWidth = feature.getProperty('stroke-width') ? feature.getProperty('stroke-width') : 2;
              const labels = feature.getProperty('label') ? feature.getProperty('label') : '';
              const types = feature.getProperty('type') ? feature.getProperty('type') : 'OIL_GAS_PIPE_LINES';
              return {
                fillColor: fill,
                fillOpacity: fillOpc,
                strokeColor: stroke,
                strokeOpacity: strokeOpc,
                strokeWeight: strokeWidth,
                label: labels,
                type: types,
                zIndex: -2,
              };
            });
            adjoiningPropertiesBoundaries.addListener('click', (feature: any) => {
              setJobFeature(feature.feature.i);
              setFindingsTypes('adjoining_properties_boundaries');
              setIsFeature(true);
            });
            adjoiningPropertiesBoundaries.addListener('mouseout', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
            });
            adjoiningPropertiesBoundaries.addListener('mouseover', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
              let propertyInfo = '';
              propertyInfo += `<p class="mb-1"><b>Parcel Id</b>: ${e.feature.getProperty('parcel_id')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Owner</b>: ${e.feature.getProperty('owner')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Address</b>: ${e.feature.getProperty('address')}</p>`;
              propertyInfo += `<p class="mb-1"><b>City</b>: ${e.feature.getProperty('city')}</p>`;
              propertyInfo += `<p class="mb-1"><b>County</b>: ${e.feature.getProperty('county')}</p>`;
              propertyInfo += `<p class="mb-1"><b>State</b>: ${e.feature.getProperty('state')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Type</b>: ${e.feature.getProperty('type')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Name</b>: ${e.feature.getProperty('name')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Mail Address</b>: ${e.feature.getProperty('mail_addr')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Legal</b>: ${e.feature.getProperty('legal')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Street Number</b>: ${e.feature.getProperty('street_number')}</p>`;

              areaInfoWindow = new maps.InfoWindow({
                content: `<div>${propertyInfo}</div>`,
                position: e.latLng,
              });
              areaInfoWindow.open(map);
            });
            pipeLinesJobMaps.setStyle((feature: any) => {
              const fill = feature.getProperty('fill') ? feature.getProperty('fill') : '#005FBC';
              const fillOpc = feature.getProperty('fill-opacity') ? feature.getProperty('fill-opacity') : 0;
              const stroke = feature.getProperty('stroke') ? feature.getProperty('stroke') : '#005FBC';
              const strokeOpc = feature.getProperty('stroke-opacity')
                ? feature.getProperty('stroke-opacity')
                : '#005FBC';
              const strokeWidth = feature.getProperty('stroke-width') ? feature.getProperty('stroke-width') : 2;
              const labels = feature.getProperty('label') ? feature.getProperty('label') : '';
              const types = feature.getProperty('type') ? feature.getProperty('type') : 'OIL_GAS_PIPE_LINES';
              return {
                fillColor: fill,
                fillOpacity: fillOpc,
                strokeColor: stroke,
                strokeOpacity: strokeOpc,
                strokeWeight: strokeWidth,
                label: labels,
                type: types,
                zIndex: -1,
              };
            });
            pipeLinesJobMaps.addListener('mouseout', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
            });
            pipeLinesJobMaps.addListener('mouseover', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
              let propertyInfo = '';
              propertyInfo += `<p class="mb-1"><b>Status</b>: ${e.feature.getProperty('status_cd')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Commodity</b>: ${e.feature.getProperty('cmdty_desc')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Diameter</b>: ${e.feature.getProperty('diameter')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Is Interstate</b>: ${e.feature.getProperty('interstate')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Subsystem Name</b>: ${e.feature.getProperty('subsys_nm')}</p>`;
              propertyInfo += `<p class="mb-1"><b>System Name</b>: ${e.feature.getProperty('sys_nm')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Type</b>: ${e.feature.getProperty('systype')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Operator</b>: ${e.feature.getProperty('oper_nm')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Accuracy</b>: ${e.feature.getProperty('quality_cd')}</p>`;

              areaInfoWindow = new maps.InfoWindow({
                content: `<div>${propertyInfo}</div>`,
                position: e.latLng,
              });
              areaInfoWindow.open(map);
            });
            oilGasJobMaps.setStyle((feature: any) => {
              if (typeof feature.getProperty('symbol') !== 'undefined' && feature.getProperty('symbol')) {
                const symbol = CustomWellMarkers[feature.getProperty('symbol')];
                const svgIcon = {
                  path: symbol[`path`],
                  fillColor: symbol[`fillColor`],
                  fillOpacity: 0.7,
                  scale: 0.04,
                  anchor: new maps.Point(520, 520),
                };
                return {
                  icon: svgIcon,
                  strokeWeight: 1,
                };
              }
            });
            soilsPropertyLayer.setStyle((feature: any) => {
              const fill = feature.getProperty('fill') ? feature.getProperty('fill') : '#9B9700';
              const fillOpc = feature.getProperty('fill-opacity') ? feature.getProperty('fill-opacity') : 0.2;
              const stroke = feature.getProperty('stroke') ? feature.getProperty('stroke') : '#9B9700';
              const strokeOpc = feature.getProperty('stroke-opacity')
                ? feature.getProperty('stroke-opacity')
                : '#9B9700';
              const strokeWidth = feature.getProperty('stroke-width') ? feature.getProperty('stroke-width') : 2;
              const labels = feature.getProperty('label') ? feature.getProperty('label') : '';
              const types = feature.getProperty('type') ? feature.getProperty('type') : 'URLX';
              return {
                fillColor: fill,
                fillOpacity: fillOpc,
                strokeColor: stroke,
                strokeOpacity: strokeOpc,
                strokeWeight: strokeWidth,
                label: labels,
                type: types,
                zIndex: -2,
              };
            });
            soilsPropertyLayer.addListener('mouseout', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
            });

            soilsPropertyLayer.addListener('mouseover', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
              let propertyInfo = '';
              propertyInfo += `<p class="mb-1"><b>Soil ID</b>: ${e.feature.getProperty('Soil ID')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Map Unit Title</b>: ${e.feature.getProperty('Map Unit Title')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Area Symbol</b>: ${e.feature.getProperty('Area Symbol')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Spatial Version</b>: ${e.feature.getProperty('Spatial Version')}</p>`;
              propertyInfo += `<p class="mb-1"><b>Map Unit Key</b>: ${e.feature.getProperty('Map Unit Key')}</p>`;

              areaInfoWindow = new maps.InfoWindow({
                content: `<div>${propertyInfo}</div>`,
                position: e.latLng,
              });
              areaInfoWindow.open(map);
            });

            soilsPropertyLayer.addListener('click', (e: any) => {
              const property = e.feature.h;
              if (property && typeof property['Soil ID'] !== 'undefined' && property['Soil ID']) {
                setSoilsPropertySelect(property);

                soilsPropertyLayer.setStyle((feature: any) => {
                  if (feature.getProperty('Map Unit Key') === e.feature.getProperty('Map Unit Key')) {
                    return {
                      fillColor: '#005FBC',
                    };
                  } else {
                    return {
                      fillColor: feature.getProperty('fill'),
                    };
                  }
                });
              }
            });
            oilGasJobMaps.addListener('mouseout', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
            });

            oilGasJobMaps.addListener('mouseover', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
              // e.feature.removeProperty('symbol');
              // e.feature.removeProperty('api');
              let propertyInfo = '';
              e.feature.forEachProperty((keyValue: string, property: string) => {
                if (property !== 'symbol' && property !== 'api') {
                  propertyInfo += `<p class="mb-1"><b>${property}</b>: ${keyValue}</p>`;
                }
              });
              areaInfoWindow = new maps.InfoWindow({
                content: `<div>${propertyInfo}</div>`,
                position: e.latLng,
              });
              areaInfoWindow.open(map);
            });

            const drawingManager = new maps.drawing.DrawingManager({
              drawingMode: null,
              drawingControl: false,
              drawingControlOptions: {
                position: maps.ControlPosition.TOP_CENTER,
                drawingModes: ['polyline', 'polygon'],
              },
              polylineOptions: {
                id: 'polyLine1',
                strokeColor: 'blue',
                strokeOpacity: 0.6,
                strokeWeight: 4,
                clickable: true,
                zIndex: 1,
                editable: false,
              },
              polygonOptions: {
                id: 'polyGon1',
                fillColor: 'blue',
                fillOpacity: 0.3,
                strokeColor: 'red',
                strokeOpacity: 0.6,
                strokeWeight: 4,
                clickable: true,
                zIndex: 1,
                editable: false,
              },
              circleOptions: {
                fillColor: '#ffff00',
                fillOpacity: 1,
                strokeWeight: 5,
                clickable: false,
                editable: true,
                zIndex: 1,
                text: '2',
              },
            });
            drawingManager.setMap(map);
            dispatch(
              updateMap({
                ...mapState,
                ...{
                  gMap: map,
                  gMaps: maps,
                  subjectPropertyShowLayer,
                  parcelSelectionLayer,
                  adjoiningPropertyLayer,
                  adjoiningPropertiesBoundaries,
                  waterWellsPointer,
                  monitorWellsPointer,
                  drawingManager,
                  oilGasJobMaps,
                  pipeLinesJobMaps,
                  pipelineLayerRef,
                  soilsPropertyLayer,
                },
              })
            );
            document.addEventListener('DOMNodeInserted', (event) => {
              const target = event.target;
              if (R.isNil(target)) {
                return;
              }
            });

            pipelineLayerRef.addListener('mouseover', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
              areaInfoWindow = new maps.InfoWindow({
                content: `<div>
            <p><b>Status</b>: ${e.feature.getProperty('Status')}</p>
            <p><b>Commodity</b>: ${e.feature.getProperty('Commodity Description')}</p>
              <p><b>Diameter</b>: ${e.feature.getProperty('Diameter')}</p>
                <p><b>Is Interstate</b>: ${e.feature.getProperty('Is Interstate')}</p>
                  <p><b>P5 Number</b>: ${e.feature.getProperty('P5 Number')}</p>
                    <p><b>Pipeline ID</b>: ${e.feature.getProperty('Pipeline ID')}</p>
                      <p><b>Subsystem Name</b>: ${e.feature.getProperty('Subsytem Name')}</p>
                        <p><b>System Name</b>: ${e.feature.getProperty('System Name')}</p>
                          <p><b>T4 Permit</b>: ${e.feature.getProperty('T4 Permit')}</p>
            <p><b>Type</b>: ${e.feature.getProperty('System Type')}</p>
            <p><b>Operator</b>: ${e.feature.getProperty('Operator')}</p>
            <p><b>Accuracy</b>: ${e.feature.getProperty('Estimated Positional Accuracy')}</p>
            </div>`,
                position: e.latLng,
              });
              areaInfoWindow.open(map);
            });
            // parcelLayer.addListener('click', (e: any) => {
            //   parcelLayer.revertStyle();
            //   parcelLayer.overrideStyle(e.feature, {
            //     strokeColor: '#005FBC',
            //     strokeWeight: '2',
            //     strokeOpacity: '1',
            //   });
            // });
            pipelineLayerRef.addListener('mouseout', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
            });
            oilgasWellLayer.addListener('mouseover', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
              e.feature.removeProperty('layer');
              // e.feature.removeProperty('Symbol');
              let propertyInfo = '';
              e.feature.forEachProperty((keyValue: string, property: string) => {
                if (property !== 'Symbol') {
                  propertyInfo += `<p class="mb-1"><b>${property}</b>: ${keyValue}</p>`;
                }
              });
              areaInfoWindow = new maps.InfoWindow({
                content: `<div>${propertyInfo}</div>`,
                position: e.latLng,
              });
              areaInfoWindow.open(map);
            });

            soilLayer.addListener('mouseover', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
              let propertyInfo = '';
              e.feature.forEachProperty((keyValue: string, property: string) => {
                propertyInfo += `<p class="mb-1"><b>${property}</b>: ${keyValue}</p>`;
              });
              areaInfoWindow = new maps.InfoWindow({
                content: `<div>${propertyInfo}</div>`,
                position: e.latLng,
              });
              areaInfoWindow.open(map);
            });

            ncLayer.addListener('mouseover', (e: any) => {
              if (areaInfoWindow != null) {
                areaInfoWindow.close();
                areaInfoWindow = null;
              }
              e.feature.removeProperty('layer');
              let propertyInfo = '';
              e.feature.forEachProperty((keyValue: string, property: string) => {
                propertyInfo += `<p class="mb-1"><b>${property}</b>: ${keyValue}</p>`;
              });
              areaInfoWindow = new maps.InfoWindow({
                content: `<div>${propertyInfo}</div>`,
                position: e.latLng,
              });
              areaInfoWindow.open(map);
            });

            oilgasWellLayer.setStyle((feature: any) => {
              const symbolNum = parseInt(feature.getProperty('Symbol'), 10);
              if (!isNaN(symbolNum)) {
                const symbol = CustomWellMarkers[symbolNum];
                const svgIcon = {
                  path: symbol[`path`],
                  fillColor: symbol[`fillColor`],
                  fillOpacity: 0.7,
                  scale: 0.04,
                  anchor: new maps.Point(520, 520),
                };
                return { icon: svgIcon, strokeWeight: 1 };
              } else {
                const symbol = CustomWellMarkers[2];
                const svgIcon = {
                  path: symbol[`path`],
                  fillColor: '#8B4513',
                  fillOpacity: 0.7,
                  scale: 0.04,
                  anchor: new maps.Point(520, 520),
                };
                return {
                  icon: svgIcon,
                  strokeWeight: 1,
                };
              }
            });

            pipelineLayerRef.setStyle((feature: any) => {
              const systemType = feature.getProperty('System Type');
              let color = '#c46b09';
              if (systemType === 'Gas Transmission') {
                color = '#FF0000';
              }
              if (systemType === 'HVL PRODUCTS') {
                color = '#FF7F00';
              }
              if (systemType === 'Crude Transmission') {
                color = '#FFFF00';
              }
              if (systemType === 'NON_HVL LIQUID PRODUCTS') {
                color = '#00FF00';
              }
              if (systemType === 'CRUDE GATHERING') {
                color = '#0000FF';
              }
              if (systemType === 'Carbon Dioxide') {
                color = '#2E2B5F';
              }
              if (systemType === 'Gas Gathering') {
                color = '#8B00FF';
              }
              return {
                strokeColor: color,
                strokeOpacity: '0.7',
                strokeWeight: '1',
                fillOpacity: 0.0,
              };
            });
            parcelLayer.setStyle((feature: any) => {
              return {
                strokeColor: '#2A5FB5',
                strokeOpacity: '0.7',
                strokeWeight: '1',
                fillOpacity: 0.0,
                zIndex: 9999,
              };
            });
            ncLayer.setStyle((feature: any) => {
              return {
                strokeColor: '#73401C',
              };
            });
            soilLayer.setStyle((feature: any) => {
              return {
                fillOpacity: 0.2,
              };
            });
          }}
          options={getMapOptions}
          center={mapCenter}
          zoom={zoomLevel}
        >
          {clusters.map((cluster: any) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } = cluster.properties;
            if (isCluster) {
              return (
                <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                  <ClusterMarker
                    style={{
                      width: `${10 + (pointCount / points.length) * 20}px`,
                      height: `${10 + (pointCount / points.length) * 20}px`,
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                      props.setZoomLevel(expansionZoom);
                      props.gMap.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </ClusterMarker>
                </Marker>
              );
            }
            return (
              <RecordMarker
                onMarkerClick={(record: ISearchRecord) => {
                  if (R.isNil(record)) {
                    // return resetMapToInitial();
                  }
                  props.gMap.panTo(getCenterWithOffset(record.location.lat, record.location.lon, -0.1, -0.15));
                  setActiveMarkerRecord(record);
                  setZoomLevel(18);
                  if (aiEarthState.activeTab === 'air') {
                    dispatch(updateAir({ ...airState, ...{ activeRecord: record } }));
                    dispatch(
                      updateAIEarth({
                        ...aiEarthState,
                        ...{ mainDrawer: 'air' },
                      })
                    );
                  } else if (!props.showProgramQueryPanel) {
                    setActivePanel(true);
                    props.setShowDocPanel(false);
                    props.setAutoScroll(true);
                  } else {
                    props.setShowProgramQueryDocPanel(true);
                    props.setAutoScroll(true);
                  }
                }}
                key={`marker-${cluster.properties.recordId}`}
                lat={latitude}
                lng={longitude}
                isActive={R.equals(activeMarkerRecord && activeMarkerRecord.entityNumber, cluster.record.entityNumber)}
                record={cluster.record}
                isMap={true}
                setStreetView={setStreetView}
                setActivePanel={setActivePanel}
              />
            );
          })}
          {props.programQueryEPNResult &&
            props.gMap &&
            props.gMap.getZoom() >= 16 &&
            props.programQueryEPNResult.map((epn: any, index: number) => {
              return (
                <EPNPin
                  key={index}
                  marker={epn.marker}
                  details={epn.details}
                  name={epn.name}
                  entityName={epn.entityNumber}
                  entity={activeMarkerRecord && activeMarkerRecord.entityName ? activeMarkerRecord.entityName : ''}
                  lat={epn.location.lat}
                  lng={epn.location.lon}
                  setStreetView={setStreetView}
                  setShowDocPanel={props.setShowDocPanel}
                  showProgramQueryPanel={props.showProgramQueryPanel}
                  setShowProgramQueryDocPanel={props.setShowProgramQueryDocPanel}
                  setAutoScroll={props.setAutoScroll}
                  openInfoModal={openInfoModal}
                  setInfoModal={setInfoModal}
                  openTabResultsModal={openTabResultsModal}
                  setTabResultsModal={setTabResultsModal}
                  openTabResultsType={openTabResultsType}
                  setTabResultsType={setTabResultsType}
                  setTrackbotTabModal={setTrackbotTabModal}
                  tabKeyword={tabKeyword}
                  setTabKeyword={setTabKeyword}
                  context={context}
                  containerRef={containerRef}
                />
              );
            })}
          {jobState.toolTipPoint &&
            jobState.toolTipPoint.length > 0 &&
            jobState.toolTipPoint.map((epn: any, index: any) => {
              return (
                <EPNPinJob
                  key={index}
                  marker={epn.marker}
                  details={epn.details}
                  name={epn.name}
                  entityName={epn.entityNumber}
                  lat={epn.location.lat}
                  lng={epn.location.lon}
                  setStreetView={setStreetView}
                  setJobFeature={setJobFeature}
                  setIsFeature={setIsFeature}
                  setAutoScroll={props.setAutoScroll}
                />
              );
            })}
        </GoogleMapReact>
      </InnerMapContainer>
      <TrackbotTabModal open={openTrackbotTabModal} setOpen={setTrackbotTabModal} context={context} />
    </MainMapContainer>
  );
};

export default AiEarthMap;
