import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Spinner } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
// import { Table } from 'react-bootstrap';
interface IEnforcement {
  result: any;
  entity: any;
  cnStatsResult: any;
}

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.7rem;
`;
// const TableContainer = styled.div`
//   margin-top: 1rem;
// `;
// const TD = styled.td`
//   font-size: 14px;
//   text-align: center;
// `;
// const TR = styled.tr`
//   background-color: #e97132;
//   color: #ffffff;
// `;
// const TB = styled(Button)`
//   background-color: #e97132;
//   border-radius: 5px;
//   border: 2px solid black;
//   padding: 5px 80px;

//   &:hover {
//     color: #fff;
//     background-color: #e97132;
//     border-color: #000000;
//   }
// `;
// const TH = styled.th`
//   font-size: 13px;
//   text-align: center;
// `;
// const TableHeading = styled.div`
//   display: flex;
//   gap: 20px;
//   margin-left:35%;

//   @media (max-width: 1440px) {
//     && {
//       margin-left:unset;
//       justify-content:center;
//     }
// `;
const BarHeading = styled.h6`
  font-family: Arial;
  text-align: center;
  font-size: 28px;
  color: #585757;
`;

const EnforcementEvent: React.FC<IEnforcement> = (props) => {
  // const [violations, setViolations] = useState<any[]>([]);
  const [enforcements, setEnforcements] = useState<any>(null);
  const [cnStatsEnforcements, setCnStatsEnforcements] = useState<any>(null);

  const [loadingApis, setLoadingApis] = useState(0);

  useEffect(() => {
    if (props.result && props.result.length > 0) {
      const enforcementList = props.result;
      const sortedEnforcements = enforcementList
        .sort((a: any, b: any) => parseInt(b.Year, 10) - parseInt(a.Year, 10))
        .slice(0, 3);

      const labels = ['NOVs', 'NOEs', 'Agreed Orders', 'Complaints'];
      const datasets = sortedEnforcements.map((entry: any, index: number) => ({
        label: entry.Year,
        backgroundColor: ['#156082', '#E97132', '#196B24', '#8B4513', '#4682B4'][index % 5],
        data: [
          parseInt(entry.NOVs || '0', 10),
          parseInt(entry.NOEs || '0', 10),
          parseInt(entry['Agreed Orders'] || '0', 10),
          parseInt(entry.Complaints || '0', 10),
        ],
      }));

      const facilityData = { labels, datasets };
      setEnforcements({ facilityData });
    }

    if (props.cnStatsResult && props.cnStatsResult.length > 0) {
      const enforcementList = props.cnStatsResult;
      const sortedEnforcements = enforcementList
        .sort((a: any, b: any) => parseInt(b.Year, 10) - parseInt(a.Year, 10))
        .slice(0, 3);

      const labels = ['NOVs', 'NOEs', 'Agreed Orders', 'Complaints'];
      const datasets = sortedEnforcements.map((entry: any, index: number) => ({
        label: entry.Year,
        backgroundColor: ['#156082', '#E97132', '#196B24', '#8B4513', '#4682B4'][index % 5],
        data: [
          parseInt(entry.NOVs || '0', 10),
          parseInt(entry.NOEs || '0', 10),
          parseInt(entry['Agreed Orders'] || '0', 10),
          parseInt(entry.Complaints || '0', 10),
        ],
      }));

      const facilityData = { labels, datasets };
      setCnStatsEnforcements({ facilityData });
    }
    setLoadingApis(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cnStatsResult]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        fontSize: 14,
        boxWidth: 20,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            stepSize: 2,
            beginAtZero: true,
            fontSize: 18,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 18,
          },
        },
      ],
    },
  };

  return loadingApis > 0 ? (
    <Spinner animation="border" variant="primary" className="d-flex mx-auto" />
  ) : (
    <ResultContainer>
        <div style={{ padding: '20px' }}>
          <Row>
      {enforcements && (
            <Col md={6}>
              <BarHeading>Facility-Level Enforcement</BarHeading>
              <div style={{ height: '400px' }}>
                <Bar data={enforcements.facilityData} options={chartOptions} />
              </div>
            </Col>
        )}
        {cnStatsEnforcements && (
          <Col md={6}>
          <BarHeading>Company-Wide Enforcement</BarHeading>
          <div style={{ height: '400px' }}>
            <Bar data={cnStatsEnforcements.facilityData} options={chartOptions} />
          </div>
        </Col>

        )}
          </Row>
        </div>
      {/* <TableContainer>
             <Row>
               <Col>
                <TableHeading>
                  <div>
                    <h5 style={{ fontSize: '30px', margin: '0px' }}>
                      Comparison with Competitors
                    </h5>
                   <p>{`(over past 3 years)`}</p>
                 </div>
                 <div>
                    <TB>Select Competitor to Add to List</TB>
                   </div>
            </TableHeading>

            <Table bordered hover>
              <thead>
                <TR>
                  <TH></TH>
                  <TH>Complaints</TH>
                  <TH>NOVs</TH>
                  <TH>NOEs</TH>
                  <TH>Cat. A Violations</TH>
                  <TH>Cat. B Violations</TH>
                  <TH>Cat. C Violations</TH>
                  <TH>Number of Violations</TH>
                  <TH>NOVs</TH>
                  <TH>Dollar Amount Assessed</TH>
                </TR>
              </thead>
              <tbody>
                {violations.map((data, index) => (
                  <tr key={index} style={{ backgroundColor: data.bgColor }}>
                    <TD>{data.company}</TD>
                    <TD>{data.complaints}</TD>
                    <TD>{data.novs}</TD>
                    <TD>{data.noes}</TD>
                    <TD>{data.catA}</TD>
                    <TD>{data.catB}</TD>
                    <TD>{data.catC}</TD>
                    <TD>{data.violations}</TD>
                    <TD>{data.novsDuplicate}</TD>
                    <TD>{data.assessedAmount}</TD>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </TableContainer> */}

    </ResultContainer>

  );
};

export default EnforcementEvent;
