import { IAuthContext } from '../context/auth/auth-context';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { TIERS } from '../types/tiers';
import styled from 'styled-components';
import withAuthContext from '../context/auth/AuthConsumer';
import { Nav } from 'react-bootstrap';
import CloseLogo from '../assets/sidebar/close-icon.svg';
import EnviroChatIcon from '../assets/sidebar/enviro-chat.svg';
import MyTundraIcon from '../assets/sidebar/my-tundra.svg';
import AiEarthIcon from '../assets/sidebar/ai-earth.svg';
import TrackbotIcon from '../assets/sidebar/track-bot.svg';
import PeopleFinderIcon from '../assets/sidebar/people-finder.svg';
import SentimentIcon from '../assets/sidebar/sentiment.svg';
import ValidatorIcon from '../assets/sidebar/validator.svg';
import CorrectiveActionIcon from '../assets/sidebar/corrective-action.svg';
import BenchmarkingIcon from '../assets/sidebar/benchmarking.svg';
import GeneratorIcon from '../assets/sidebar/generator.svg';
import AdminPanelIcon from '../assets/sidebar/admin-panel.svg';
import ReactTooltip from 'react-tooltip';
import {InfoOutlined as InfoOutlinedIcon} from '@material-ui/icons';
import { removeGlobalFlag } from '../store/aiearth/action';
import { useDispatch, useSelector } from 'react-redux';

interface ISidebarProps {
  isCollapsed: boolean;
  onItemClick: () => void;
  onLogoutClick: () => void;
  onLoginClick: () => void;
}
interface ISidebarContainerProps {
  isCollapsed: boolean;
}
interface ISidebarItemProps {
  isActive?: boolean;
}

const SidebarContainer = styled.div<ISidebarContainerProps>`
  display: flex;
  max-width: 375px;
  min-width: 300px;
  width: 100%;
  align-items: flex-start;
  position: fixed;
  height: 100vh;
  overflow: auto;
  background: #fff;
  top: 0;
  flex-direction: column;
  transition: all 0.3s;
  z-index: 800;
  ${(prop) => (!!prop.isCollapsed ? 'margin-left: -450px' : '')}
`;

const SidebarItem = styled(Link)<ISidebarItemProps>`
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-family: 'Sans Pro';
  color: ${(props: ISidebarItemProps) => (!!props.isActive ? 'var(--primary)' : `#000`)};
  font-size: 22px;
  padding-left: 15%;
  display: flex;
  align-items: center;
  line-height: 38px;
  width: 100%;
  border-bottom: 1px solid #CBDFF8;
  margin-bottom: 10px;
  padding-bottom: 10px;
  &:hover {
    text-decoration: none;
    svg,
    span {
      color: #005fbc;
      color: var(--primary);
    }
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
`;

const Close = styled.img`
  width: 100%;
  padding: 1em 14%;
  margin: 0 auto;
  cursor: pointer;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;
// const Title = styled.h2`
//   font-family: 'Lazy Dog';
//   font-size: 36px;
//   font-weight: 400;
//   letter-spacing: 1px;
//   margin: 26px 0 30px;
// `;
const SubTitle = styled.h3`
  font-family: 'Sans Pro';
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  color: inherit;
  margin-bottom: 15px;
`;
const SubTitleWithoutLink = styled.h3`
  font-family: 'Sans Pro';
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  color: inherit;
  margin-bottom: 15px;
  padding-left: 15%;
`;
// const TundraPro = styled.a`
//   font-family: 'Sans Pro';
//   font-size: 26px;
//   padding-left: 15%;
//   text-transform: uppercase;
//   font-weight: 400;
//   letter-spacing: 1px;
//   color: inherit;
//   margin-bottom: 35px;
//   &:hover {
//     text-decoration: none;
//   }
// `;
const ExternalLink = styled.a`
  color: #000;
  padding-left: 15%;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;
const PageLink = styled(Link)`
  color: #000;
  padding-left: 15%;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;
const IconActionButton = styled(Nav.Link)`
  color: #000;
  text-decoration: none;
  padding: 0;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  max-width: 25% !important
`;

const Sidebar: React.FC<ISidebarProps & { context: IAuthContext }> = (
  props: ISidebarProps & { context: IAuthContext }
) => {
  const authContext = props.context;
  const isAuthenticated = !!(props.context.user && props.context.user.uid);
  const location = useLocation();
  const store: any = useSelector<any>((state): any => state);
  const dispatch = useDispatch();
  const useQuery = () => {
    const { search: searchVal } = location;

    return React.useMemo(() => new URLSearchParams(searchVal), [searchVal]);
  };
  const queryParam = useQuery();

  // Remove lastQuestion flag from localStorage on refresh page and browser back button
  useEffect(() => {
    // window.addEventListener('load', () => localStorage.removeItem('lastQuestion'));
    // performance.navigation.type detects page is refresh or not
    if(performance.navigation.type === 1 && (!store.globalFlags.lastQuestion || !localStorage.getItem('lastQuestion'))) {
      localStorage.removeItem('lastQuestion');
    }
    // return () => {
    //   window.removeEventListener('beforeunload', () => localStorage.removeItem('lastQuestion'));
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Remove previousPage flag on route change
  useEffect(() => {
    // reset temp chat in local storage
    localStorage.removeItem('tempChatData');
    if((location.pathname !== '/search' && location.pathname !== '/my-tundra') || (location.pathname === '/search' && !!!location.search)) {
      dispatch(removeGlobalFlag('previousPage'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, queryParam.get('type')]);

  return (
    <SidebarContainer isCollapsed={props.isCollapsed}>
      <StyledTooltip place="right" effect="solid"/>
      <Close src={CloseLogo} onClick={() => props.onItemClick()}/>
      {/* <Title>BY ENVIRO.AI </Title> */}
      {/* <TundraPro href="https://www.enviro.ai/pricing" onClick={() => props.onItemClick()} >
        Tundra pro
      </TundraPro> */}
      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
      <SidebarItem to="/myenviroai" onClick={() => props.onItemClick()}>
          <img src={EnviroChatIcon} alt="Enviro Chat"/>
          <span>Enviro Assist/Chat</span>
        </SidebarItem>
      )}
      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
        <SidebarItem to="/search" onClick={() => props.onItemClick()}>
           <img src={EnviroChatIcon} alt="Enviro Chat"/>
           <span>Enviro AI Search</span>
         </SidebarItem>
      )}
      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
       <SidebarItem to="/my-tundra" onClick={() => props.onItemClick()}>
          <img src={MyTundraIcon} alt="My Tundra"/>
          <span> My Environment</span>
        </SidebarItem>
      )}
      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
        <SidebarItem
          to="/ai-earth"
          onClick={() => props.onItemClick()}
        >
          <img src={AiEarthIcon} alt="AI Earth"/>
          <span>AI Earth <InfoOutlinedIcon data-tip="Interactive mapping of environmental data and compliance information"/></span>
        </SidebarItem>
      )}
      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && process.env.REACT_APP_ENVIRONMENT !== 'production' && (
       <SidebarItem to="/ai-earth-air" onClick={() => props.onItemClick()} title={'Air'}>
          <img src={AiEarthIcon} alt="Ai Air"/>
          <span>Ai Air</span>
        </SidebarItem>
      )}
      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
        <SidebarItem
          to="/trackbot"
          onClick={() => props.onItemClick()}
        >
          <img src={TrackbotIcon} alt="TrackBot"/>
          <span>TrackBot <InfoOutlinedIcon data-tip="Track agency filings and other environmental changes"/></span>
        </SidebarItem>
      )}
      {(authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
        <SidebarItem
          to="/people-finder"
          onClick={() => props.onItemClick()}
        >
          <img src={PeopleFinderIcon} alt="People Finder"/>
          <span>People Finder</span>
        </SidebarItem>
      )}
      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
       <SidebarItem to="/sentiment" onClick={() => props.onItemClick()} title={'Environmental Sentiment Analysis'}>
          <img src={SentimentIcon} alt="Sentiment"/>
          <span>Sentiment</span>
        </SidebarItem>
      )}
      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
       <SidebarItem
          to="/validator"
          onClick={() => props.onItemClick()}
        >
          <img src={ValidatorIcon} alt="Validator"/>
          <span>Validator <InfoOutlinedIcon data-tip="Reviews Phase 1 environmental reports"/></span>
        </SidebarItem>
      )}

      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
       <SidebarItem
          to="/benchmarking"
          onClick={() => props.onItemClick()}
        >
          <img src={BenchmarkingIcon} alt="Benchmarking"/>
          <span>Benchmarking <InfoOutlinedIcon data-tip="Benchmarks company environmental performance"/></span>
        </SidebarItem>

      )}
      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
        <SidebarItem to="/corrective-tracker" onClick={() => props.onItemClick()}>
          <img src={CorrectiveActionIcon} alt="Corrective Action"/>
          <span>Corrective Action</span>
        </SidebarItem>
      )}
      {(authContext.isLubrizol() || authContext.isTier(TIERS.PLATINUM) || authContext.isTier(TIERS.DAYPASS) || authContext.isTier(TIERS.SUPER_ADMIN)) && (
        <SidebarItem
          to="/generator"
          onClick={() => props.onItemClick()}
        >
          <img src={GeneratorIcon} alt="Generator"/>
          <span>Generator <InfoOutlinedIcon data-tip="Identifies potential emission reduction opportunities"/></span>
        </SidebarItem>
      )}
      {authContext.isTier(TIERS.SUPER_ADMIN) && (
     <SidebarItem to="/admin/users" onClick={() => props.onItemClick()}>
          <img src={AdminPanelIcon} alt="Admin Panel"/>
          <span>Admin Panel</span>
        </SidebarItem>
      )}
      <br />
      <PageLink to="/account" onClick={() => props.onItemClick()}>
        <SubTitle>Account</SubTitle>
      </PageLink>

      {/* <ExternalLink
        href="https://www.info.searchtundra.com/carbon-sequestration"
        target="_blank"
        rel="noreferrer"
        onClick={() => props.onItemClick()}
      >
        <SubTitle>How it works</SubTitle>
      </ExternalLink> */}
      <ExternalLink
        href="https://www.info.searchtundra.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
        onClick={() => props.onItemClick()}
      >
        <SubTitle>Privacy policy</SubTitle>
      </ExternalLink>
      <ExternalLink
        href="https://www.info.searchtundra.com/terms-and-conditions"
        target="_blank"
        rel="noreferrer"
        onClick={() => props.onItemClick()}
      >
        <SubTitle>Terms & Conditions</SubTitle>
      </ExternalLink>
      <ExternalLink
        href="https://www.info.searchtundra.com/contact"
        target="_blank"
        rel="noreferrer"
        onClick={() => props.onItemClick()}
      >
        <SubTitle>Contact</SubTitle>
      </ExternalLink>
      <ExternalLink
        href="https://www.info.searchtundra.com"
        target="_blank"
        rel="noreferrer"
        onClick={() => props.onItemClick()}
      >
        <SubTitle>About</SubTitle>
      </ExternalLink>

      {isAuthenticated ? (
        <SubTitleWithoutLink>
          <IconActionButton
            title="Logout"
            href="#"
            onClick={() => props.context.signOut().then(() => props.onLogoutClick())}
            rel="noreferrer"
          >
            Log Out
          </IconActionButton>
        </SubTitleWithoutLink>
      ) : (
        <SubTitleWithoutLink>
          <IconActionButton title="Login" href="#" onClick={() => props.onLoginClick()} rel="noreferrer">
            Log In
          </IconActionButton>
        </SubTitleWithoutLink>
      )}
    </SidebarContainer>
  );
};

export default withAuthContext(Sidebar);
